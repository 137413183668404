import { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import ErrorBoundary from './containers/ErrorBoundary/ErrorBoundary';
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.css';
import validators from './common/validators';
import Routes from './Routes';

import { AuthContext } from './AuthContext';

import { checkToken, changeModal, fetchTournaments } from './helpers/util';
import ChatbotModal from './components/ChatBot/ChatbotModal';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  state = {
    viewportWidth: window.innerWidth,
    authState: {
      role: ''
    },
    changeAuthState: (role) => this.changeAuthState(role),
    loading: true
  };

  checkToken = checkToken.bind(this);
  changeModal = changeModal.bind(this);
  fetchTournaments = fetchTournaments.bind(this);

  changeAuthState = (role) => {
    this.setState((prevState) => ({
      authState: {
        ...prevState.authState,
        role: role
      }
    }));
  };

  componentDidMount() {
    this.checkToken((body, status) => {
      this.setState({ loading: false });

      if (status === 401) {
        this.changeAuthState('');
      }
    });

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => this.setState({ viewportWidth: window.innerWidth });

  render() {
    const { loading, authState } = this.state;
    let component = !loading ? (
      <AuthContext.Provider value={this.state}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <BrowserRouter history={browserHistory}>
              <Routes />
              {authState?.role && <ChatbotModal />}
            </BrowserRouter>
          </ErrorBoundary>
        </ThemeProvider>
      </AuthContext.Provider>
    ) : null;
    return component;
  }
}
