import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { selectedValue } from '../../../helpers/selectors';
import Filter from '../../Filter/Filter';
import { NAME, ID } from '../../../helpers/constants';
import { useStyles } from './style';

const FilterBracketData = (props) => {
  const {
    categoryTypes,
    selectCategoryType,
    selectedTatamis,
    selectTatami,
    bracketErrors,
    bracketForm
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const filterData = [
    {
      options: categoryTypes,
      onChange: selectCategoryType,
      label: t('discipline'),
      item: NAME,
      value: selectedValue(
        categoryTypes,
        ID,
        +bracketForm?.category_type,
        true
      ),
      error: Boolean(bracketErrors.category_type),
      helperText: bracketErrors.category_type,
      className: classes.marginRight
    },
    {
      options: selectedTatamis,
      onChange: selectTatami,
      label: t('tatami'),
      item: 'tatami_name',
      value:
        Array.isArray(selectedTatamis) &&
        selectedTatamis.find((item) => item === bracketForm.selectedTatami),
      error: Boolean(bracketErrors.selectedTatami),
      helperText: bracketErrors.selectedTatami
    }
  ];

  return (
    <span className={classes.root}>
      {filterData.map(
        (
          {
            options,
            onChange,
            label,
            item,
            helperText,
            error,
            className,
            value
          },
          index
        ) =>
          item && (
            <Fragment key={index}>
              <Filter
                className={clsx(className, classes.maxWidth)}
                {...{ options }}
                {...{ onChange }}
                {...{ label }}
                {...{ item }}
                value={value || ''}
                variant="outlined"
                {...{ helperText }}
                {...{ error }}
              />
            </Fragment>
          )
      )}
    </span>
  );
};
export default FilterBracketData;
