import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';

import {
  fetchTournamentWinners,
  changeModal,
  fetchTournaments,
  exportReportGeneral,
  downloadFile,
  exportAwardSequenceReport,
  fetchAllCategoryTypesV2,
  getCertificates,
  fetchUsers,
  selectOption
} from '../../helpers/util';
import {
  formatDateMMDDYYYY,
  selectedValue,
  categoryTypesPresentOnTournament
} from 'helpers/selectors';

import { changeTitle } from '../../helpers/actions';

import { ReactComponent as ThirdMedal } from '../../assets/img/third_place.svg';
import { ReactComponent as SecondMedal } from '../../assets/img/second_place.svg';
import { ReactComponent as FirstMedal } from '../../assets/img/first_place.svg';
import { ReactComponent as Diploma } from '../../assets/img/diploma.svg';

import {
  ENABLED,
  ID,
  INITIAL_ALL_FIELDS,
  serverDefaultPath
} from 'helpers/constants';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { AuthContext } from 'AuthContext';
import Button from '../../components/Buttons/ActionButtons';
import SearchInput from '../../components/SearchInput/SearchInput';
import FilterCheckboxes from '../../components/FilterOptions/FilterCheckboxes';

import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';
import LoadingState from '../../components/LoadingState/LoadingState';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import SideModal from '../../components/Snackbar/SideModal';
import Accordion from 'components/Accordion/Accordion';
import Table from 'components/Table/CardsList';
import Filter from 'components/Filter/Filter';
import Modal from 'components/Modal/ModalNewDesign';
import PreviewDiploma from 'components/CertificateBuilder/PreviewDiploma';

import styles from './styles';

const PREVIEW_DIPLOMA = 'PREVIEW_DIPLOMA';

class Winners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      success: false,
      loading: true,
      collapsed: {},
      open: {},
      role: localStorage.getItem('role'),
      loggedUserId: localStorage.getItem('user_id'),
      winners: [],
      tournamentData: {},
      fileData: {},
      viewportWidth: window.innerWidth,
      isFilterOpen: false,
      searchBar: '',
      categoryTypes: [],
      selectedCheckboxes: [],
      selectedCheckboxesWinners: [],
      expandAll: false,
      filterWinnersValue: {
        categoryId: '',
        categoriesWithWinners: []
      },
      searchByOption: { id: '0' }
    };
    this.fetchTournamentWinners = fetchTournamentWinners.bind(this);
    this.changeModal = changeModal.bind(this);
    this.fetchTournaments = fetchTournaments.bind(this);
    this.exportReportGeneral = exportReportGeneral.bind(this);
    this.downloadFile = downloadFile.bind(this);
    this.exportAwardSequenceReport = exportAwardSequenceReport.bind(this);
    this.fetchAllCategoryTypesV2 = fetchAllCategoryTypesV2.bind(this);
    this.getCertificates = getCertificates.bind(this);
    this.fetchUsers = fetchUsers.bind(this);
    this.selectOption = selectOption.bind(this);
    this.selectedValue = selectedValue.bind(this);

    this.myParam = props.match.params?.id;
  }

  static contextType = AuthContext;

  componentDidMount() {
    const { role } = this.state;

    changeTitle(this.props.t('winners'));

    this.setState({ loading: true });

    this.fetchTournaments(this.myParam, null, null, () => {
      const { tournamentData } = this.state;

      const data1 = new Promise((resolve) => {
        this.fetchTournamentWinners(this.myParam, () => {
          const { winners } = this.state;

          let statistics = {
            totalCategoriesCount: tournamentData?.total_categories_count || 0,
            categoriesCount: tournamentData?.categories_count || 0,
            total1Place: 0,
            total2Place: 0,
            total3Place: 0,
            totalPrintedDiploma: 0,
            totalUnprintedDimploma: 0
          };

          const processWinner = (winner) => {
            const place = +winner.participant_place;
            const isDiplomaPrinted = !!+winner.participant_is_diplom_printed;

            if (place === 1) {
              statistics.total1Place += 1;
            } else if (place === 2) {
              statistics.total2Place += 1;
            } else if (place === 3) {
              statistics.total3Place += 1;
            }

            if (isDiplomaPrinted) {
              statistics.totalPrintedDiploma += 1;
            } else {
              statistics.totalUnprintedDimploma += 1;
            }
          };

          winners?.forEach((it) => {
            it.winners?.forEach((winner) => {
              if (+it.category_type !== 3) {
                // individual winners
                processWinner(winner);
              } else {
                // team winners
                winner?.team?.forEach(processWinner);
              }
            });
          });

          let categoriesWithWinners = winners.map(
            ({ category_name, category_id }) => ({
              category_name,
              category_id
            })
          );

          resolve({ categoriesWithWinners, statistics });
        });
      });

      const data2 = new Promise((resolve) => {
        this.fetchAllCategoryTypesV2(
          role === 'administrator' && {
            user_id: tournamentData?.organizer_id
          },
          (types) => {
            const currenyTypes = categoryTypesPresentOnTournament(
              types || [],
              tournamentData?.category_types
            );

            resolve(currenyTypes);
          }
        );
      });

      Promise.all([data1, data2]).then(
        ([{ categoriesWithWinners, statistics }, res2]) => {
          const { filterWinnersValue } = this.state;
          let cpy = { ...filterWinnersValue };

          cpy.categoriesWithWinners = categoriesWithWinners;

          this.setState({
            statistics,
            filterWinnersValue: cpy,
            ...(res2 ? { categoryTypes: res2 } : {}),
            loading: false
          });
        }
      );
    });
  }

  onResize = () => this.setState({ viewportWidth: window.innerWidth });

  onSelectCheckbox = (key) => {
    const { selectedCheckboxes } = this.state;
    const checkedValues = selectedCheckboxes.some((item) => item.id === key.id)
      ? selectedCheckboxes.filter((it) => it.id !== key.id)
      : [...selectedCheckboxes, key];

    this.setState({ selectedCheckboxes: checkedValues }, () => {
      this.fetchTournamentWinners(this.myParam, () => {
        this.setState({ loading: false });
      });
    });
  };

  cancelFilter = () => {
    this.setState({ selectedCheckboxes: [] }, () =>
      this.fetchTournamentWinners(this.myParam, () => {
        this.setState({ loading: false });
      })
    );
  };

  itemToggle = (key) =>
    this.setState((prevState) => ({
      collapsed: { ...prevState.collapsed, [key]: !this.state.collapsed[key] }
    }));

  hideSnackbar = () => this.setState({ showModal: false });

  onSearch = (evt) => {
    const { value } = evt.target;

    this.setState({ searchBar: value });
  };

  filterVision = () => {
    this.setState({ isFilterOpen: !this.state.isFilterOpen });
  };

  onClearSearch = () => {
    this.setState({ searchBar: '' }, () =>
      this.fetchTournamentWinners(this.myParam, () => {
        this.setState({ loading: false });
      })
    );
  };

  pressEnterHandler = (event) => {
    if (event.key === 'Enter') {
      this.searchHandler();
    }
  };

  searchHandler = () => {
    this.fetchTournamentWinners(this.myParam, () =>
      this.setState({ loading: false })
    );
  };

  onSelectCheckboxExpandAll = () => {
    const { expandAll } = this.state;

    this.setState({ expandAll: !expandAll }, () => {
      this.expandOrCloseList(!expandAll);
    });
  };

  expandOrCloseList = (expandOrClose) => {
    const { winners } = this.state;
    const len = winners.length;
    let collapseAll = {};

    for (let i = 0; i < len; i++) {
      collapseAll[i] = expandOrClose;
    }

    this.setState({ collapsed: collapseAll });
  };

  handleFilterChange = (evt, val) => {
    this.selectOption(
      evt,
      val,
      'filterWinnersValue',
      'categoryId',
      'category_id',
      null,
      () => {
        this.fetchTournamentWinners(this.myParam, () => {
          this.setState({ loading: false });
        });
      }
    );
  };

  showModal = (item) => {
    const { tournamentData } = this.state;
    const { t } = this.props;
    const targetID = item?.targetId;
    const id = item?.id;

    if (targetID === PREVIEW_DIPLOMA) {
      this.getCertificates(
        { tournament_id: tournamentData?.id },
        (certificates) => {
          const template = certificates[0];
          const textFields = this.generateTextFields(template, item, t);
          const modalData = {
            ...template,
            path: template?.file_name,
            textFields
          };

          this.setState({
            fileData: modalData,
            pressedIcon: targetID,
            isModalOpened: true,
            selectedRow: id
          });
        }
      );
    } else {
      this.setState({
        pressedIcon: targetID,
        isModalOpened: true,
        selectedRow: id
      });
    }
  };

  // generate text fields
  generateTextFields = (template, item, t) => {
    const allFields = INITIAL_ALL_FIELDS(t);
    return allFields.map((it) => {
      const fieldParams = this.getFieldParams(it, template, item);
      return {
        ...it,
        params: fieldParams
      };
    });
  };

  //  get field parameters
  getFieldParams = (it, template, item) => {
    const { tournamentData } = this.state;

    let finalParams = [];
    const fieldParam = (val) => ({
      default_fontSize: it.default_fontSize,
      default_width: it.default_width,
      label: val?.[4],
      x: val?.[0] ? parseInt(val[0], 10) : 0,
      y: val?.[1] ? parseInt(val[1], 10) : 0,
      fontSize: parseInt(val[2]),
      width: parseInt(val[3]),
      fontFamily: val?.[5]?.length > 0 ? val[5] : 'Helvetica',
      fontWeight: val?.[6] ?? 'normal',
      fontAlign: val?.[7] ?? 'center'
    });

    const setPreviewLabel = (recievedVal) => {
      const currentVal = it.name;
      const findParticipantName = !item.team
        ? item['participant_name']
        : item.team?.find((it) => !!+it.participant_is_diplom_printed)?.[
            'participant_name'
          ];

      switch (currentVal) {
        case 'name_field':
          return findParticipantName;
        case 'date_field':
          return formatDateMMDDYYYY(new Date(), '/');
        case 'category_field':
          return item[
            !!+tournamentData?.use_alt_category_name &&
            item['category_alt_name']
              ? 'category_alt_name'
              : 'category_name'
          ];
        case 'age_field':
          return item['category_age'];
        case 'weight_field':
          return item['category_weight'];
        case 'place_field':
          return item['participant_place'];
        case 'region_field':
          return item['region'] ?? it.label;
        case 'additional_field':
          return recievedVal ?? elem.label;
        default:
          return '';
      }
    };

    if (typeof template?.[it.name] === 'string') {
      const splitVal = template[it.name].split('|');
      const previewLabel = setPreviewLabel();
      finalParams.push({
        ...fieldParam(splitVal),
        previewLabel,
        isActive: template[it.name]?.length > 0
      });
    } else if (Array.isArray(template?.[it.name])) {
      finalParams = template[it.name].map((field) => {
        const splitVal = field.split('|');
        const previewLabel = setPreviewLabel(splitVal[4]);
        return { ...fieldParam(splitVal), previewLabel, isActive: true };
      });
    }

    return finalParams.length ? finalParams : [fieldParam({})];
  };

  hideModal = () => {
    this.setState({
      isModalOpened: false,
      fileData: {}
    });
  };

  drawImageOnCanvas = () => {
    const { fileData } = this.state;
    let cpy = { ...fileData };
    const canvas = document.getElementById('canvas');
    const canvasContainer = document.getElementById('canvasContainer');

    if (canvas) {
      const context = canvas.getContext('2d');
      const image = new Image();
      image.src = serverDefaultPath + fileData?.path;

      image.onload = () => {
        const imgWidth = image.width;
        const imgHeight = image.height;
        const imgRotation = imgHeight > imgWidth;

        const realDiplomHeight = imgRotation ? 1122.24 : 793.92;
        const realDiplomWidth = imgRotation ? 793.92 : 1122.24;

        const realDiplomaAspectRatio = realDiplomWidth / realDiplomHeight;

        let canvasDiplomaWidth, canvasDiplomaHeight;

        if (imgRotation) {
          canvasDiplomaHeight = realDiplomWidth;
          canvasDiplomaWidth = realDiplomWidth * realDiplomaAspectRatio;
        } else {
          canvasDiplomaWidth = realDiplomHeight;
          canvasDiplomaHeight = realDiplomHeight / realDiplomaAspectRatio;
        }

        canvasContainer.style.width = `${canvasDiplomaWidth}px`;
        canvasContainer.style.height = `${canvasDiplomaHeight}px`;
        canvas.style.width = `${canvasDiplomaWidth}px`;
        canvas.style.height = `${canvasDiplomaHeight}px`;

        canvas.width = canvasDiplomaWidth;
        canvas.height = canvasDiplomaHeight;

        // Calculate scaling factor to match real diploma size
        const scaleX = canvasDiplomaWidth / realDiplomWidth;
        const scaleY = canvasDiplomaHeight / realDiplomHeight;

        const scale = Math.min(scaleX, scaleY);

        context.clearRect(0, 0, canvas.width, canvas.height);

        context.drawImage(
          image,
          0,
          0,
          imgWidth,
          imgHeight,
          0,
          0,
          canvasDiplomaWidth,
          canvasDiplomaHeight
        );

        cpy.textFields = cpy.textFields.map((it) => {
          return {
            ...it,
            params: it?.params.map((v) => {
              return {
                ...v,
                x: v?.x * scaleX,
                y: v.y * scaleY,
                width: v?.width,
                fontSize: v?.fontSize * scale
              };
            })
          };
        });

        cpy = {
          ...cpy,
          scaleX,
          scaleY
        };

        this.setState({
          fileData: cpy
        });
      };
    }
  };

  render() {
    const {
      success,
      showModal,
      modalInfo,
      fileData,
      winners,
      collapsed,
      isModalOpened,
      pressedIcon,
      loading,
      tournamentData,
      isFilterOpen,
      searchBar,
      categoryTypes,
      selectedCheckboxes,
      expandAll,
      filterWinnersValue,
      searchByOption,
      statistics
    } = this.state;
    const { t } = this.props;
    const { classes } = this.props;
    const { viewportWidth } = this.context;

    const SEARCH_BY_OPTION = [
      {
        id: '0',
        label: t('athletes')
      },
      { id: '1', label: t('coaches') }
    ];

    const recordDataParams = (item, idx, parent) => {
      const hasPrinetedDimploma = !item.team
        ? !!+item.participant_is_diplom_printed
        : item.team?.some((it) => !!+it.participant_is_diplom_printed);

      return {
        main: {
          label: t('nameRegistrar'),
          style: {
            cardMainPart: classes.cardWrapper,
            recordName: classes.txtWrapper
          },
          name: (
            <span
              style={{
                color:
                  !!+tournamentData?.use_reg_online &&
                  !+item?.participant_registered
                    ? '#005FEE'
                    : ''
              }}>
              {item.participant_name}
            </span>
          ),
          hasPhoto: true,
          photo: item?.participant_photo
        },
        info: [
          ...(tournamentData.no_participant_number !== ENABLED
            ? [
                {
                  name: t('number'),
                  value: item?.participant_number || '-'
                }
              ]
            : []),
          {
            name: t('age'),
            value: item.participant_age
          },
          {
            name: t('gender'),
            value: item.participant_gender
          },
          {
            name: t('weight'),
            value: item.participant_weight
          },
          {
            name: t('coach'),
            value: item.participant_coach
          },
          {
            name: t('diploma'),
            value: hasPrinetedDimploma ? (
              <Diploma
                className={classes.onHover}
                onClick={() =>
                  this.showModal({
                    ...item,
                    targetId: PREVIEW_DIPLOMA,
                    ...parent
                  })
                }
              />
            ) : (
              '-'
            )
          },
          {
            name: t('rank'),
            value:
              +item.participant_place === 1 ? (
                <FirstMedal />
              ) : +item.participant_place === 2 ? (
                <SecondMedal />
              ) : +item.participant_place === 3 ? (
                <ThirdMedal />
              ) : (
                '-'
              )
          }
        ]
      };
    };

    const pageHeaderStatistics = {
      title: t('eventStatistics'),
      info: [
        {
          label: t('totalCategories'),
          name: statistics?.totalCategoriesCount
        },
        {
          label: t('prizeCategories'),
          name: statistics?.categoriesCount
        },
        {
          label: t('firstPlaces'),
          name: statistics?.total1Place
        },
        {
          label: t('secondPlaces'),
          name: statistics?.total2Place
        },
        {
          label: t('thirdPlaces'),
          name: statistics?.total3Place
        },
        {
          label: t('printedDiplomas'),
          name: statistics?.totalPrintedDiploma
        },
        {
          label: t('unprintedDiplomas'),
          name: statistics?.totalUnprintedDimploma
        }
      ]
    };

    let onClick, dialogTitle, dialogContent, buttonPurpose;

    if (pressedIcon === PREVIEW_DIPLOMA) {
      dialogContent = fileData.file_name ? (
        <PreviewDiploma
          drawImageOnCanvas={this.drawImageOnCanvas}
          data={fileData}
          {...{ viewportWidth }}
        />
      ) : (
        t('noTemplateForDiploma')
      );
    }

    return (
      <>
        <SideModal
          closeModal={this.hideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <>
            {tournamentData && (
              <HeaderTournInfo
                {...{ tournamentData, pageHeaderStatistics, viewportWidth }}
                shouldShowBtn
              />
            )}
            <Modal
              open={isModalOpened}
              close={this.hideModal}
              {...{ onClick, dialogTitle, dialogContent, buttonPurpose }}
              specificDetails={{
                className: {
                  ...(pressedIcon === PREVIEW_DIPLOMA
                    ? { paper: classes.maxWidthModal }
                    : {})
                }
              }}
            />
            <CheckboxBtn
              className={classes.marginLeft5}
              checked={expandAll}
              onChange={this.onSelectCheckboxExpandAll}
              label={t('expandList')}
            />
            <span
              className={clsx(
                classes.gridButtons,
                classes.verticallyCentered,
                !isFilterOpen && classes.marginBottom1
              )}>
              <Button
                onClick={() => this.filterVision()}
                label={
                  <span
                    className={clsx(
                      classes.flex,
                      classes.verticallyCentered,
                      classes.center,
                      classes.flexGrow1
                    )}>
                    <span className={classes.flexGrow1}>{t('filter')}</span>
                    <ArrowDropDownIcon
                      className={isFilterOpen && classes.arrowDropUp}
                    />
                  </span>
                }
                isSaveBtn={!isFilterOpen}
                className={isFilterOpen && classes.selected}
                style={{ gridArea: 'filterButton' }}
              />
              <Filter
                classes={{
                  root: classes.outlined
                }}
                value={
                  this.selectedValue(
                    filterWinnersValue.categoriesWithWinners,
                    'category_id',
                    filterWinnersValue.categoryId
                  ) || ''
                }
                options={filterWinnersValue?.categoriesWithWinners}
                onChange={(evt, val) => this.handleFilterChange(evt, val)}
                label={t('rfQual')}
                item={'category_name'}
                variant="outlined"
                style={{ gridArea: 'filter' }}
                disableClearable
              />
              <SearchInput
                className={classes.search}
                onChange={this.onSearch}
                clearSearch={this.onClearSearch}
                value={searchBar}
                isSearchInactive={!searchBar}
                onKeyPress={this.pressEnterHandler}
                style={{ gridArea: 'searchInput' }}
                specificDetails={{
                  childrenElemEnd: (
                    <Filter
                      value={
                        selectedValue(
                          SEARCH_BY_OPTION,
                          ID,
                          searchByOption.id
                        ) || ''
                      }
                      className={classes.styleFilter}
                      options={SEARCH_BY_OPTION}
                      item="label"
                      onChange={(e, val) =>
                        this.selectOption(e, val, 'searchByOption', ID, ID)
                      }
                      specificDetails={{
                        InputProps: { disableUnderline: true }
                      }}
                    />
                  )
                }}
              />
              <Button
                onClick={() =>
                  this.exportReportGeneral({
                    api: `/get_winners_report/${this.myParam}`,
                    isApiGet: true
                  })
                }
                label={t('saveList')}
                isSaveBtn={true}
                className={classes.center}
                style={{ gridArea: 'exportButton' }}
              />
            </span>
            <FilterCheckboxes
              {...{
                categoryTypes,
                selectedCheckboxes,
                isFilterOpen
              }}
              filterDetails={{
                ownerCategories: false,
                tounamentPresence: false,
                noUnisex: false
              }}
              onSelectCheckbox={this.onSelectCheckbox}
              cancelFilter={this.cancelFilter}
            />
            <Accordion
              itemToggle={this.itemToggle}
              data={winners}
              {...{ collapsed }}
              accordionContent={(finalists, elem) => {
                return (
                  <Table
                    data={finalists}
                    {...{ recordDataParams }}
                    specificDetails={{ parent: elem }}
                  />
                );
              }}
            />
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Winners)));
