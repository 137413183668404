const NON_LATIN1_REGEXP = /[^\x20-\x7e\xa0-\xff]/g;
const HEX_ESCAPE_REPLACE_REGEXP = /%([0-9A-Fa-f]{2})/g;
const EXT_VALUE_REGEXP =
  /^([A-Za-z0-9!#$%&+\-^_`{}~]+)'(?:[A-Za-z]{2,3}(?:-[A-Za-z]{3}){0,3}|[A-Za-z]{4,8}|)'((?:%[0-9A-Fa-f]{2}|[A-Za-z0-9!#$&+.^_`|~-])+)$/;
const DISPOSITION_TYPE_REGEXP =
  /^([!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*(?:$|;)/; // eslint-disable-line no-control-regex
const PARAM_REGEXP =
  /;[\x09\x20]*([!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*=[\x09\x20]*("(?:[\x20!\x23-\x5b\x5d-\x7e\x80-\xff]|\\[\x20-\x7e])*"|[!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*/g; // eslint-disable-line no-control-regex
const QESC_REGEXP = /\\([\u0000-\u007f])/g; // eslint-disable-line no-control-regex

function pdecode(str, hex) {
  return String.fromCharCode(parseInt(hex, 16));
}

function getlatin1(val) {
  // simple Unicode -> ISO-8859-1 transformation
  return String(val).replace(NON_LATIN1_REGEXP, '?');
}

function decodefield(str) {
  const match = EXT_VALUE_REGEXP.exec(str);

  if (!match) {
    return null;
  }

  const charset = match[1].toLowerCase();
  const encoded = match[2];
  let value;

  // to binary string
  const binary = encoded.replace(HEX_ESCAPE_REPLACE_REGEXP, pdecode);
  const Buffer = require('safe-buffer').Buffer;

  switch (charset) {
    case 'iso-8859-1':
      value = getlatin1(binary);
      break;
    case 'utf-8':
      value = Buffer.from(binary, 'binary').toString('utf8');
      break;
    default:
      throw new TypeError('unsupported charset in extended field');
  }

  return value;
}

export const parse = (string) => {
  if (!string || typeof string !== 'string') {
    throw new TypeError('argument string is required');
  }

  let match = DISPOSITION_TYPE_REGEXP.exec(string);

  if (!match) {
    throw new TypeError('invalid type format');
  }

  // normalize type
  let index = match[0].length;
  // let type = match[1].toLowerCase();

  let key;
  let names = [];
  let params = {};
  let value;

  // calculate index to start at
  index = PARAM_REGEXP.lastIndex =
    match[0].substr(-1) === ';' ? index - 1 : index;

  // match parameters
  while ((match = PARAM_REGEXP.exec(string))) {
    if (match.index !== index) {
      console.warn(
        `Skipping parameter at index ${match.index} (expected ${index})`
      );
    }

    index += match[0].length;
    key = match[1].toLowerCase();
    value = match[2];

    if (names.indexOf(key) !== -1) {
      console.warn(`Duplicate parameter detected for ${key}, skipping...`);
    }

    names.push(key);

    if (key.indexOf('*') + 1 === key.length) {
      // decode extended value
      key = key.slice(0, -1);
      value = decodefield(value);

      // overwrite existing value
      params[key] = value;
      continue;
    }

    if (typeof params[key] === 'string') {
      continue;
    }

    if (value[0] === '"') {
      // remove quotes and escapes
      value = value.substr(1, value.length - 2).replace(QESC_REGEXP, '$1');
    }

    params[key] = value;
  }

  if (index !== -1 && index !== string.length) {
    console.warn(`Invalid parameter at index (${index})`);
  }

  return params;
};
