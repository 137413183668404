import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },

  paper: {
    backgroundColor: '#fff',
    border: '1px solid #A2ABBA',
    borderRadius: '0.3rem'
  },

  margin0: {
    margin: 0
  },

  padding0: {
    padding: 0
  },

  header: {
    fontSize: '1.2rem',
    textAlign: 'center',
    fontWeight: 500,
    marginTop: '2.5rem',
    marginBottom: '1.5rem'
  },

  divResize: {
    height: '100%',
    width: 5,
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1
  },

  clearOptinalFromTable: {
    position: 'absolute',
    top: '0.1rem',
    right: '0.1rem'
  },

  toolbar: {
    display: 'grid',
    gap: '1rem',
    marginBottom: '1.5rem',
    alignItems: 'center',
    '@media (min-width:1024px)': {
      gridTemplateAreas: "'filter . . .'",
      gridTemplateColumns: '1fr 16.25em 10em 21.45em 10em'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateAreas: "'filter filter filter' '. . .'"
    }
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  popperContentWrapper: {
    background: '#fff',
    display: 'grid',
    margin: '0.5rem 1rem',
    border: '1px solid #A2ABBA',
    borderRadius: '0.3rem',
    gap: '1rem',
    paddingBottom: '1rem'
  },

  filterOutlined: {
    '@media (min-width:768px)': {
      gridArea: 'filter'
    },
    '& .MuiInputBase-fullWidth': {
      height: '3.429em'
    }
  },

  uploadBtnTable: {
    height: '4.286em',
    width: '20.36em'
  },

  fullWidth: {
    width: '100%'
  },

  childrenElemWrapperTable: {
    display: 'grid',
    justifyContent: 'center',
    textAlign: 'center'
  },

  uploadBtn: {
    background: '#8996AC',
    color: '#fff'
  },

  plusBtn: {
    background: '#8996AC',
    color: '#fff',
    border: 'none',
    borderRadius: 0
  },

  tableOptionalPlusIc: {
    width: '.8em',
    height: '.8em',
    fontSize: '2rem',
    alignItems: 'center',
    justifyContent: 'center'
  },

  uploadBtnPlus: {
    color: '#8996AC',
    background: '#fff',
    borderRadius: '.3rem',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 'auto'
  },

  uploadBtnPlusTable: {
    width: '2.573em',
    height: '2.573em'
  },

  uploadBtnPlusToolbar: {
    width: '2.429em',
    height: '2.429em'
  },

  uploadBtnLabel: {
    display: 'grid',
    gridTemplateColumns: '2.5em 1fr',
    alignItems: 'center',
    width: '100%'
  },

  uploadBtnLabelTabel: {
    fontSize: '1rem'
  },

  dropFileInsideTable: {
    border: 'none',
    justifyContent: 'center'
  },

  dropFileInsideToolbar: {
    minHeight: 'fit-content',
    padding: 0,
    border: 'none'
  }
});
