import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  headerMain: {
    fontSize: '1.2rem',
    textAlign: 'center',
    fontWeight: 500,
    marginTop: '2.5rem',
    marginBottom: '1.5rem'
  },

  toolbar: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateAreas: "'search . .'",
      gridTemplateColumns: '1fr 13.75em 13.75em'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateAreas: "'search search' '. .'"
    },
    gap: '1rem',
    marginBottom: '1.5rem'
  },

  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  search: {
    '@media (min-width:768px)': {
      gridArea: 'search'
    }
  },

  wrapperInvitations: {
    height: '15em',
    overflowY: 'auto',
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr'
    },
    gap: '1.5em'
  }
}));
