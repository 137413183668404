import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Tooltip } from '@material-ui/core';

import { DEFAULT_TOURNAMENT, serverDefaultPath } from '../../helpers/constants';
import {
  DDMMYYYY,
  isSmallScreen,
  isDesktopView,
  organizerName
} from '../../helpers/selectors';

import statisticsIcon from './../../assets/img/statisticsIcon.svg';
import Button from '../Buttons/ActionButtons';
import RegistrationStatus from 'components/RegistrationStatus/RegistrationStatus';

import { useStyles } from './styles';

const HeaderTournInfo = (props) => {
  const {
    summaryHeader,
    tournamentData,
    shouldShowBtn,
    pageHeaderStatistics,
    viewportWidth
  } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const desktopView = isDesktopView(viewportWidth);
  const [visibility, setVisibility] = useState({
    headerPart1: false,
    headerPart2: false
  });

  const toggleVisibility = (key) => {
    setVisibility((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const tournType = () => {
    switch (tournamentData?.tournament_type) {
      case 'local':
        return t('regionalEvent');
      case 'international':
        return t('internationalEvent');
      case 'training_school':
        return t('trainingSchool');
    }
  };

  const headerTournMaster = {
    title: t('eventInfo'),
    regStatus: (
      <RegistrationStatus
        specificDetails={{
          txt: {
            hideJudgeStatus: true,
            openRegistration: t('registrationOpened'),
            closedRegistration: t('registrationClosed')
          },
          className: { root: classes.displayContent }
        }}
        {...{ tournamentData }}
      />
    ),
    img: {
      src: tournamentData?.poster && serverDefaultPath + tournamentData?.poster
    },
    info: [
      {
        label: t('sportEvent'),
        name: tournamentData?.tournament_name,
        nameOfSportEvent: true
      },
      {
        label: t('eventStart'),
        name: DDMMYYYY(tournamentData?.start_date, true)
      },
      {
        label: t('organizer'),
        name: [
          tournamentData.organizer_club,
          ', ',
          organizerName(tournamentData)?.firstN,
          ' ',
          organizerName(tournamentData)?.lastN,
          ' '
        ]
      },
      {
        label: t('eventType'),
        name: tournType()
      }
    ],
    showRegistrationStatus: true
  };

  const currentDataObj = (summaryHeader && summaryHeader(tournType())) ?? [
    headerTournMaster,
    ...(pageHeaderStatistics ? [pageHeaderStatistics] : [])
  ];

  const btnComponent = (key, className) => (
    <Button
      id={key.button.id}
      className={clsx(classes.flex, className, classes.left)}
      onClick={key.button.onClick}
      label={key.button.label}
    />
  );

  const cardHeaderContent = (key, idx, defaultImg) => {
    const headerPart = idx === 0 ? 'headerPart1' : 'headerPart2';

    return (
      tournamentData &&
      Object.keys(tournamentData).length > 0 && (
        <span
          key={idx}
          className={clsx(classes.flex, classes.column, classes.fullWidth)}>
          <span
            className={clsx(
              classes.paper,
              classes.marginBottom6,
              classes.nameOfSportEvent,
              classes.padding7,
              classes.onHover
            )}
            onClick={() => toggleVisibility(headerPart)}>
            <span
              className={clsx(
                classes.flex,
                classes.centerVertically,
                classes.justifyContent,
                classes.fullWidth
              )}>
              <span className={classes.title}>
                {key.title}
                {idx === 0 && ': '}
                <span
                  className={
                    !!+tournamentData?.registration_active
                      ? classes.green
                      : classes.red
                  }>
                  {key.regStatus}
                </span>
              </span>
              <ArrowDropDownIcon
                className={clsx(
                  visibility[headerPart] ? classes.arrowDropUp : undefined,
                  classes.grey
                )}
              />
            </span>
          </span>
          {visibility[headerPart] && (
            <span
              style={{ height: '100%' }}
              className={clsx(classes.flex, classes.paper, classes.padding10)}>
              {!key.noImg && (key?.img || idx === 1) && (
                <img
                  className={classes.img}
                  alt="pic"
                  src={key?.img ? key?.img?.src ?? defaultImg : statisticsIcon}
                />
              )}
              <span
                style={{ ...(key?.styles?.list ?? {}) }}
                className={clsx(
                  classes.marginLeftRight,
                  key?.button?.className
                    ? classes.panelWithoutImgBtn
                    : classes.fullWidth,
                  classes.flex,
                  classes.column
                )}>
                {key.info.map((it, infoIdx) => (
                  <span
                    key={`${it.label}_${idx}_${infoIdx}`}
                    className={clsx(
                      classes.flex,
                      classes.fontSize14,
                      classes.fullWidth,
                      isSmallScreen(viewportWidth) ||
                        (it.nameOfSportEvent && classes.column),
                      infoIdx !== key.info.length - 1 && classes.paddingBottom
                    )}>
                    <span className={classes.fullWidth}>
                      {it.label && (
                        <span className={clsx(classes.secondaryTxt)}>
                          {[
                            it.label,
                            !isSmallScreen(viewportWidth) ? ':' : ''
                          ].join('')}
                        </span>
                      )}
                      <span
                        className={clsx(
                          it.nameOfSportEvent && classes.nameOfSportEvent,
                          classes.fullWidth
                        )}>
                        {it.name}
                      </span>
                    </span>
                  </span>
                ))}
              </span>
              {shouldShowBtn && key.button && !isSmallScreen(viewportWidth) ? (
                key?.button?.className ? (
                  btnComponent(key, key.button.className)
                ) : (
                  <Tooltip arrow title={t('goBack')}>
                    <span className={classes.heightFitContent}>
                      {btnComponent(key, classes.goBackArrow)}
                    </span>
                  </Tooltip>
                )
              ) : (
                Array.isArray(key.button) && (
                  <span
                    className={clsx(
                      classes.flex,
                      classes.heightFitContent,
                      classes.column
                    )}>
                    {key.button.map((icBtn, idxIcBtn) => (
                      <Fragment key={idxIcBtn}>{icBtn.ic}</Fragment>
                    ))}
                  </span>
                )
              )}
            </span>
          )}
        </span>
      )
    );
  };

  return (
    <div
      style={{
        display: 'grid',
        ...(desktopView
          ? {
              gridTemplateColumns:
                '' + currentDataObj?.map(() => '1fr').join(' ') + '',
              ...(currentDataObj?.length > 1 ? { gridColumnGap: '1rem' } : {})
            }
          : { gridRowGap: '1rem' })
      }}
      className={clsx(classes.marginBottom20)}>
      {currentDataObj.map((item, idx) =>
        cardHeaderContent(item, idx, DEFAULT_TOURNAMENT)
      )}
    </div>
  );
};

export default HeaderTournInfo;
