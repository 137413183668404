import { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { CircularProgress, Box } from '@material-ui/core';

import {
  fetchTemplates,
  changeModal,
  fetchTournaments,
  updateTemplate,
  fetchCoaches,
  sendInvitation,
  getInvitationToken,
  changeHead,
  fetchReference,
  fetchExcelFields,
  fetchExcelImport,
  fetchAllCategoryTypesV2,
  importExcel,
  importGet,
  importDelete,
  selectOption,
  saveTournInvitations,
  fetchTournInvitations,
  textChangeHandler,
  fetchCountries,
  changeImage,
  changeDate,
  validateForm,
  fetchReferenceRegion,
  usersRegisterNewUser,
  deleteTournInvitations,
  onBlur
} from '../../helpers/util';
import { isDesktopView, DDMMYYYY } from '../../helpers/selectors';
import {
  ENV_URL,
  KEEP_EDITING,
  CLOSE_DISCARD,
  CHOOSE_FROM_LIST,
  IMPORTS_BY_TOURNAMENT
} from '../../helpers/constants';

import { AuthContext } from 'AuthContext';

import LoadingState from '../../components/LoadingState/LoadingState';
import EmptyState from '../../components/EmptyState/EmptyState';
import Excel from '../../components/ImportExcel/ImportExcel';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import CopyTxt from '../../components/CopyTxt/CopyTxt';
import CoachForm from '../../components/QuickTournRegistration/CoachForm/CoachForm';
import Modal from '../../components/Modal/ModalNewDesign';
import SearchInput from '../../components/SearchInput/SearchInput';
import TableList from '../../components/Table/CardsList';
import Invitation from '../../components/Invitation/Invitation';
import SideModal from '../../components/Snackbar/SideModal';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';

import styles from './Styles';

const initialState = {
  checkedValues: [],
  indeterminate: false,
  checkAll: false,
  recipients: []
};

const defaultForm = {
  first_name: '',
  last_name: '',
  email: '',
  country_id: ''
};
class InviteCoaches extends Component {
  state = {
    tournamentId: +this.props.match.params.id,
    tournInvitations: [],
    templateLang: '1',
    type: 1,
    ...initialState,
    showModal: false,
    coachesList: [],
    selectedCheckboxes: [],
    selectedTemplate: {},
    tournamentData: {},
    errors: {},
    modalData: {
      selectedCheckboxes: [],
      selectedCheckboxesDefault: [],
      open: false
    },
    excelData: {
      currentFields: {},
      optionalFields: {},
      teamRepresentatives: []
    },
    showError: {},
    countries: [],
    allRegions: [],
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng')
  };

  fetchTemplates = fetchTemplates.bind(this);
  changeModal = changeModal.bind(this);
  fetchTournaments = fetchTournaments.bind(this);
  updateTemplate = updateTemplate.bind(this);
  fetchCoaches = fetchCoaches.bind(this);
  sendInvitation = sendInvitation.bind(this);
  getInvitationToken = getInvitationToken.bind(this);
  changeHead = changeHead.bind(this);
  fetchReference = fetchReference.bind(this);
  fetchExcelFields = fetchExcelFields.bind(this);
  fetchExcelImport = fetchExcelImport.bind(this);
  fetchAllCategoryTypesV2 = fetchAllCategoryTypesV2.bind(this);
  fetchCountries = fetchCountries.bind(this);
  importExcel = importExcel.bind(this);
  importGet = importGet.bind(this);
  importDelete = importDelete.bind(this);
  selectOption = selectOption.bind(this);
  validateForm = validateForm.bind(this);
  usersRegisterNewUser = usersRegisterNewUser.bind(this);
  fetchTournInvitations = fetchTournInvitations.bind(this);
  saveTournInvitations = saveTournInvitations.bind(this);
  deleteTournInvitations = deleteTournInvitations.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);
  changeImage = changeImage.bind(this);
  changeDate = changeDate.bind(this);
  onBlur = onBlur.bind(this);

  static contextType = AuthContext;

  componentDidMount() {
    const { tournamentId, type, langOnLoad } = this.state;
    const { t } = this.props;

    this.getInvitationToken(tournamentId);
    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;

      if (tournamentData) {
        if (!!+tournamentData?.section_import_on) {
          const importExcelFields = localStorage.getItem('importExcelFields');
          const parsedImportExcelFields =
            importExcelFields && JSON.parse(importExcelFields);

          const fetchBeforeImportExcel = async () => {
            try {
              const [
                allTournamentCategories,
                allImportFields,
                allRepresentatives
              ] = await Promise.all([
                new Promise((resolve) =>
                  this.fetchAllCategoryTypesV2(
                    { tournament_id: tournamentId },
                    (types) => {
                      resolve(types);
                    }
                  )
                ),
                new Promise((resolve) =>
                  this.fetchExcelFields(
                    { tournamentId: tournamentId },
                    (data) => {
                      resolve(data);
                    }
                  )
                ),
                new Promise((resolve) =>
                  this.fetchCoaches(tournamentId, (data) => {
                    resolve(data);
                  })
                )
              ]);

              const fieldConfig = {
                first_name: {
                  name: 'firstName',
                  label: t('firstname'),
                  isRequired: true
                },
                last_name: {
                  name: 'lastName',
                  label: t('lastname'),
                  isRequired: true
                },
                patronymic: { name: 'patronymic', label: t('patronymic') },
                birthday: {
                  name: 'birthdayInput',
                  label: t('birthday'),
                  isRequired: true
                },
                age: { name: 'age', label: t('age') },
                gender: {
                  name: 'gender',
                  label: t('gender'),
                  isRequired: true
                },
                weight: {
                  name: 'weight',
                  label: t('weight'),
                  isRequired: true
                },
                height: { name: 'height', label: t('height') },
                coach_first_name: {
                  name: 'coachFirstName',
                  label: [t('coach'), t('firstname')].join(' ')
                },
                coach_last_name: {
                  name: 'coachLastName',
                  label: [t('coach'), t('lastname')].join(' ')
                },
                coach_first_name_2: {
                  name: 'coachFirstName2',
                  label: [t('coach'), t('firstname'), '/2'].join(' ')
                },
                coach_last_name_2: {
                  name: 'coachLastName2',
                  label: [t('coach'), t('lastname'), '/2'].join(' ')
                },
                region: { name: 'region', label: t('region') },
                city: { name: 'city', label: t('city') },
                club: { name: 'club', label: t('club') },
                qdan: { name: 'qDan', label: t('kyuDan') },
                fio: { name: 'fio', label: t('athleteName') },
                fio_coach: { name: 'fio_coach', label: t('trainerName') },
                empty: { name: 'empty', label: t('empty') }
              };

              const fields = allImportFields.fields?.map((it) => {
                const config = fieldConfig[it] || { name: it, label: it };

                return {
                  ...config,
                  name_db: it
                };
              });

              let currentFields = [];

              if (parsedImportExcelFields?.fields) {
                //  use localStorage only when fields were set in a specific order
                currentFields = parsedImportExcelFields.fields;
              } else {
                currentFields = fields.filter((it) => it.isRequired);
              }

              const optionalFields = fields.filter((it) => !it.isRequired);
              const tournamentCategoryTypes = allTournamentCategories.map(
                (type) => {
                  if (
                    allImportFields?.categoryTypes?.some(
                      (it) => +it === +type?.id
                    )
                  ) {
                    return type;
                  }
                }
              );

              let currentCategoryTypes = [];

              if (parsedImportExcelFields?.category_types) {
                currentCategoryTypes = tournamentCategoryTypes.filter((type) =>
                  parsedImportExcelFields.category_types.some(
                    (it) => +it.id === +type.id
                  )
                );
              } else {
                currentCategoryTypes = tournamentCategoryTypes;
              }

              this.setState({
                excelData: {
                  currentFields: {
                    fields: currentFields,
                    category_types: currentCategoryTypes
                  },
                  optionalFields: {
                    fields: optionalFields,
                    category_types: tournamentCategoryTypes
                  },
                  teamRepresentatives: allRepresentatives
                }
              });
            } catch (error) {
              console.error('Error!', error);
            }
          };

          fetchBeforeImportExcel();
        }

        this.fetchTournInvitations({ tournamentId }, (data) =>
          this.setState({
            tournInvitations: data,
            tournInvitationsFiltered: data,
            loading: false
          })
        );
      }

      changeHead(tournamentData, t('registrationN'));
    });

    // this.fetchReference(
    //   4113,
    //   (data) => {
    //     const filteredData = data.filter((item) => item.name === 'invite');
    //     this.setState({ iframeURL: filteredData[0] });
    //   },
    //   null,
    //   langOnLoad
    // );
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCheckboxes, tournamentData, isEditing, langOnLoad } =
      this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      isEditing &&
      selectedCheckboxes &&
      selectedCheckboxes !== prevState.selectedCheckboxes
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }

    if (langOnLoad !== currentLang) {
      changeHead(tournamentData, t('registrationN'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  showModal = (elem) => {
    const { tournamentId } = this.state;
    const modalData = { open: true, targetID: elem?.targetID, isLoading: true };

    this.setState({ modalData });

    if (elem?.targetID === CHOOSE_FROM_LIST) {
      new Promise((resolve) => {
        this.fetchCoaches(null, () => {
          const { tournInvitations, coachesList } = this.state;
          let selectedInvitations = [];

          tournInvitations?.map((it) => {
            const coach = coachesList.find(
              (coach) => +coach?.id === +it?.user_id
            );

            if (coach) {
              selectedInvitations = [...selectedInvitations, coach];
            }
          });

          resolve(selectedInvitations);
        });
      }).then((res) => {
        this.setState((prev) => ({
          modalData: {
            ...prev.modalData,
            selectedCheckboxes: [],
            selectedCheckboxesDefault: res,
            isLoading: false
          }
        }));
      });
    } else if (modalData?.targetID === IMPORTS_BY_TOURNAMENT) {
      new Promise((resolve) => {
        this.importGet({ tournamentId }, (result) => resolve(result));
      }).then((res) => {
        this.setState((prev) => ({
          importsGetList: res,
          modalData: {
            ...prev.modalData,
            selectedCheckboxes: [],
            selectedCheckboxesDefault: res,
            isLoading: false
          }
        }));
      });
    } else {
      const data1 = new Promise((resolve) => {
        this.fetchCountries(() => resolve(true));
      });
      const data2 = new Promise((resolve) => {
        if (elem?.country_id) {
          this.fetchReferenceRegion(elem?.country_id, null, () =>
            resolve(true)
          );
        } else {
          resolve();
        }
      });

      Promise.all([data1, data2]).then(() => {
        this.setState((prevState) => ({
          modalData: { ...prevState.modalData, isLoading: false }
        }));
      });
    }
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        modalData: {
          selectedCheckboxes: [],
          selectedCheckboxesDefault: [],
          open: false
        },
        shouldShowDiscardChanges: false,
        isEditing: false,
        allRegions: []
      });
    }
  };

  discardOrKeepEditing = (evt, targetID) => {
    if (targetID === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (targetID === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  hideSnackBar = () => this.setState({ showModal: false });

  onDropExcel = (acceptedFiles) => {
    const { excelData, tournamentData } = this.state;
    let cpy = { ...excelData };

    if (acceptedFiles[0]) {
      this.fetchExcelImport(
        {
          file: acceptedFiles[0],
          currentFields: excelData?.currentFields,
          tournamentId: tournamentData?.id
        },
        (data) => {
          let notPassedCount = 0;
          let notPassedItems = [];

          cpy.data = data.map((it) => {
            // If not passed, increment the count and store the item in notPassedItems
            if (!it.passed) {
              notPassedCount++;
              notPassedItems.push(it);
            }

            return {
              ...it,
              isChecked: it.passed,
              birthday: it.birthday,
              birthdayInput: DDMMYYYY(it.birthday),
              categoryTypes: it.categoryTypes ?? []
            };
          });

          this.setState({
            excelData: {
              ...cpy,
              notPassedCount,
              notPassedItems,
              fileName: acceptedFiles[0]?.name
            }
          });
        }
      );
    }
  };

  onResizeColumn = (width, index) => {
    const { excelData } = this.state;
    const newColumnsOrder = [...excelData?.currentFields];

    newColumnsOrder[index].width = width;

    this.setState((prev) => ({
      excelData: { ...prev.excelData, currentFields: newColumnsOrder }
    }));
  };

  onToggleOptionalFields = (el, specificDetails) => {
    const { excelData } = this.state;
    const param = specificDetails?.param;
    const arg = specificDetails?.arg;
    const cpy = {
      ...excelData,
      currentFields: { ...excelData.currentFields },
      optionalFields: { ...excelData.optionalFields }
    };
    const updatedCurrentFields = { ...cpy.currentFields };

    updatedCurrentFields[param] = updatedCurrentFields[param].some(
      (it) => it[arg] === el[arg]
    )
      ? updatedCurrentFields[param].filter((it) => it[arg] !== el[arg])
      : [...updatedCurrentFields[param], el];

    cpy.currentFields = updatedCurrentFields;

    this.setState({ excelData: cpy });
  };

  onTogglePassedStatus = (evt, el) => {
    const { excelData } = this.state;
    let cpy = { ...excelData };

    cpy.data[el?.idx].isChecked = evt.target.checked;

    this.setState({ excelData: cpy });
  };

  onToggleCategoryTypes = (el) => {
    const { excelData } = this.state;
    let cpy = { ...excelData };

    cpy.data[el.idx].categoryTypes = cpy.data[el.idx].categoryTypes.some(
      (it) => +it === +el.type
    )
      ? cpy.data[el.idx].categoryTypes.filter((it) => +it !== +el.type)
      : [...cpy.data[el.idx].categoryTypes, el.type];

    this.setState({ excelData: cpy });
  };

  onDragEnd = (result, param) => {
    const { excelData } = this.state;

    if (!result.destination) return;

    if (result.source.index === result.destination.index) return;

    const newColumnsOrder = [...excelData?.currentFields[param]];

    const [reorderedColumn] = newColumnsOrder.splice(result.source.index, 1);

    newColumnsOrder.splice(result.destination.index, 0, reorderedColumn);

    this.setState((prev) => ({
      excelData: {
        ...prev.excelData,
        currentFields: {
          ...prev.excelData.currentFields,
          [param]: newColumnsOrder
        }
      }
    }));
  };

  onImportExcel = () => {
    const { t } = this.props;
    const { tournamentData, excelData } = this.state;

    if (excelData?.selectedRepresentative) {
      this.importExcel(
        {
          userId: excelData?.selectedRepresentative,
          tournamentId: tournamentData?.id,
          data: excelData.data.map((it) => ({
            ...it,
            passed: it?.isChecked
          })),
          fileName: excelData?.fileName
        },
        () => {
          const defaultData = {
            currentFields: excelData?.currentFields,
            optionalFields: excelData?.optionalFields,
            teamRepresentatives: excelData?.teamRepresentatives
          };

          this.setState({ excelData: defaultData });
        }
      );
    } else {
      this.changeModal(t('teamRepresentativeNoSelection'));
      this.setState((prev) => ({
        excelData: {
          ...prev.excelData,
          selectedRepresentativeHelperTxtError: t('required')
        }
      }));
    }
  };

  onSwitchRepresentative = (e, val) => {
    this.selectOption(e, val, 'excelData', 'selectedRepresentative', 'id');
    this.setState((prev) => ({
      excelData: {
        ...prev.excelData,
        selectedRepresentativeHelperTxtError: null
      }
    }));
  };

  onSearch = (param) => {
    const { modalData } = this.state;
    const tournInvitationsFiltered =
      modalData?.targetID !== CHOOSE_FROM_LIST && this.onFilterInvitations(); //filter invitations

    this.setState(
      (prevState) => ({
        [param]: {
          ...prevState[param],
          searchedValue: this.state[param]?.searchBar
        },
        ...(tournInvitationsFiltered ? { tournInvitationsFiltered } : {})
      }),
      () => {
        if (modalData?.targetID === CHOOSE_FROM_LIST) {
          this.onFilterRecordsModal();
        }
      }
    );
  };

  fetchRegionBySelectedCountry = (key) => {
    const { modalData } = this.state;
    let cpy = { ...modalData };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ modalData: cpy });
  };

  onFilterRecordsModal = () => {
    const { coachesList, modalData } = this.state;
    const len = coachesList?.length;
    let newData = [];

    for (let i = 0; i < len; i++) {
      const el = coachesList[i];
      const bySearch = modalData?.searchBar
        ? [el.c_first_name, el.c_last_name, el.email, el.club].some((val) =>
            val
              ?.toLowerCase()
              .includes(modalData.searchBar.toLowerCase().trim())
          )
        : true;

      if (bySearch) {
        newData = [...newData, el];
      }
    }
    this.setState({ filteredCoaches: newData });
  };

  onFilterInvitations = (data) => {
    const { tournInvitations, filters } = this.state;
    const currentData = data ?? tournInvitations;
    const len = currentData?.length;
    let newData = [];

    for (let i = 0; i < len; i++) {
      const el = currentData[i];
      const bySearch = filters?.searchBar
        ? [el.first_name, el.last_name, el.email, el.club].some((val) =>
            val?.toLowerCase().includes(filters?.searchBar.toLowerCase().trim())
          )
        : true;

      if (bySearch) {
        newData = [...newData, el];
      }
    }

    return newData;
  };

  onChangeSearchText = (evt, param) => {
    this.setState((prevState) => ({
      [param]: { ...prevState[param], searchBar: evt.target.value }
    }));
  };

  onClearSearch = (param) => {
    this.setState(
      (prevState) => ({ [param]: { ...prevState[param], searchBar: '' } }),
      () => this.onSearch(param)
    );
  };

  onEnterPress = (evt, param) => {
    if (evt.key === 'Enter') {
      this.onSearch(param);
    }
  };

  select2Invite = (elem) => {
    const { modalData } = this.state;
    let cpy = { ...modalData };

    cpy.selectedCheckboxes = cpy?.selectedCheckboxes?.some(
      (it) => +it?.id === +elem?.id
    )
      ? cpy?.selectedCheckboxes?.filter((it) => +it?.id !== +elem?.id)
      : [...(cpy.selectedCheckboxes ?? []), elem];

    this.setState({ modalData: cpy });
  };

  onChangeCheckbox = (elem, param) => {
    const { modalData } = this.state;
    let cpy = { ...modalData };

    const elemId = `${elem?.[param]}`;

    cpy.selectedCheckboxes = cpy.selectedCheckboxes?.some(
      (it) => `${it}` === elemId
    )
      ? cpy.selectedCheckboxes.filter((it) => `${it}` !== elemId)
      : [...(cpy.selectedCheckboxes ?? []), elemId];

    this.setState({ modalData: cpy });
  };

  onSave = (evt) => {
    const { tournamentData } = this.state;
    const regionVal = document.getElementById('region')?.value;

    this.setState(
      (prevState) => ({
        modalData: {
          ...prevState.modalData,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.modalData;

        this.validateForm(
          evt,
          { ...defaultForm },
          form,
          'errors',
          () => {
            this.usersRegisterNewUser(evt, tournamentData?.id, form, (body) => {
              this.setState({
                modalData: { selectedCheckboxes: [] },
                errors: {},
                imagePreview: null
              });
              this.saveTournInvitations(
                { tournamentId: tournamentData?.id, id: body?.id },
                () => {
                  this.triggerFetchTournInvitations(this.hideModal);
                }
              );
            });
          },
          () => {
            this.setState({
              showError: { email: true }
            });
          }
        );
      }
    );
  };

  triggerFetchTournInvitations = (cb) => {
    const { tournamentId } = this.state;

    this.fetchTournInvitations({ tournamentId }, (data) => {
      const tournInvitationsFiltered = this.onFilterInvitations(data);

      this.setState({ tournInvitations: data, tournInvitationsFiltered });
      cb && cb();
    });
  };

  render() {
    const {
      modalData,
      showModal,
      success,
      modalInfo,
      filteredCoaches,
      invitationToken,
      tournamentData,
      loading,
      shouldShowDiscardChanges,
      excelData,
      tournInvitationsFiltered,
      showError,
      errors,
      countries,
      allRegions,
      importsGetList,
      filters
    } = this.state;

    const { viewportWidth, authState } = this.context;
    const { role } = authState;
    const { t, classes } = this.props;

    const hasDesktopSize = isDesktopView(viewportWidth);
    const FIRST_NAME = !+tournamentData?.last_name_first
      ? 'first_name'
      : 'last_name';
    const LAST_NAME = !+tournamentData?.last_name_first
      ? 'last_name'
      : 'first_name';
    const FIRST_NAME_COACH = !+tournamentData?.last_name_first
      ? 'c_first_name'
      : 'c_last_name';
    const LAST_NAME_COACH = !+tournamentData?.last_name_first
      ? 'c_last_name'
      : 'c_first_name';
    const COACH_FIRST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'coach_first_name'
      : 'coach_last_name';

    const COACH_LAST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'coach_last_name'
      : 'coach_first_name';

    const COACH_FIRST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('coachFirstName')
      : t('coachLastName');

    const COACH_LAST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('coachLastName')
      : t('coachFirstName');

    const dojoInfo = [
      {
        name: FIRST_NAME,
        label: !+tournamentData?.last_name_first
          ? t('firstname')
          : t('lastname'),
        required: true
      },
      {
        name: LAST_NAME,
        label: !+tournamentData?.last_name_first
          ? t('lastname')
          : t('firstname'),
        required: true
      },
      {
        name: 'patronymic',
        label: t('patronymic')
      },
      {
        name: 'email',
        label: t('email'),
        shouldEditCoach: false,
        required: true
      },
      { birthday: true, isDate: true },
      { gender: true, isFilter: true },
      {
        name: 'branch_chief',
        label: t('nameBranchChief')
      },
      {
        name: COACH_FIRST_NAME_VAL,
        label: COACH_FIRST_NAME_LABEL
      },
      {
        name: COACH_LAST_NAME_VAL,
        label: COACH_LAST_NAME_LABEL
      },
      {
        country: true,
        isFilter: true
      },
      allRegions?.length > 0
        ? {
            region: true,
            isFilter: true
          }
        : {
            name: 'region',
            label: t('region')
          },
      {
        name: 'city',
        label: t('city')
      },
      {
        name: 'club',
        label: t('cluborFederationName')
      },
      {
        label: t('phone'),
        name: 'phone'
      },
      { label: t('website'), name: 'website' },
      { save: true }
    ];

    const recordDataParams = (item, idx) => {
      return {
        class: {
          gridRecordsValue: classes.gridRecordsValue,
          gridRecords: classes.gridRecords,
          thumbnail: classes.thumbnail
        },
        main: {
          rowOrder: {
            value: (
              <CheckboxBtn
                classControlLabel={classes.margin0}
                onChange={() => this.select2Invite(item)}
                checked={Array.from([
                  ...modalData?.selectedCheckboxes,
                  ...modalData?.selectedCheckboxesDefault
                ])?.some((it) => +it?.id === +item?.id)}
                disabled={modalData?.selectedCheckboxesDefault.some(
                  (it) => +it.id === +item.id
                )}
              />
            ),
            class: classes.rowOrder
          },
          style: {
            cardMainPart: classes.cardValuePart
          },
          name: [
            item?.[FIRST_NAME_COACH],
            item?.[LAST_NAME_COACH],
            ...(!!+tournamentData?.show_reg_patronymic
              ? [item?.patronymic]
              : [])
          ].join(' '),
          hasPhoto: true,
          photo: item?.photo
        },
        info: [
          {
            value: item.email,
            name: t('email'),
            className: classes.emailTable
          },
          { value: item.iso3, name: t('country') },
          { value: item.region, name: t('region') },
          { value: item.city, name: t('city') },
          { value: item.club, name: t('club') }
        ]
      };
    };

    const recordDataParamsImportsGet = (item, idx) => {
      return {
        class: {},
        main: {
          label: t('name'),
          style: {
            cardMainPart: classes.cardValuePart
          },
          name: (
            <Box className={clsx(classes.flex, classes.alignItems)}>
              <CheckboxBtn
                classControlLabel={classes.margin0}
                onChange={() => this.onChangeCheckbox(item, 'import_key')}
                checked={modalData?.selectedCheckboxes?.includes(
                  item?.import_key
                )}
              />
              {item.file_name}
            </Box>
          )
        },
        info: [
          { value: item?.participants, name: t('participants') },
          {
            value: item?.user_name,
            name: t('owner')
          },
          {
            value: item.created_at,
            name: t('createdOn')
          }
        ]
      };
    };

    const pageHeaderStatistics = {
      title: t('invitationLink'),
      noImg: true,
      info: [
        {
          name: (
            <span
              className={clsx(
                classes.flex,
                classes.spaceBetween,
                classes.alignItems,
                classes.fullWidth,
                classes.break
              )}>
              <Link
                className={classes.link}
                to={`${ENV_URL}/register_to_tournament/${invitationToken}`}>
                <span className={clsx(classes.name)}>
                  {`${ENV_URL}/register_to_tournament/${invitationToken}`}
                </span>
              </Link>

              <CopyTxt
                copiedTxt={`${ENV_URL}/register_to_tournament/${invitationToken}`}
              />
            </span>
          )
        },
        {
          label: t('pincode'),
          name: tournamentData?.id
        },
        {
          name: (
            <span
              className={clsx(
                classes.flex,
                classes.spaceBetween,
                classes.alignItems,
                classes.fullWidth
              )}>
              <label>{`${ENV_URL}/sign-in`}</label>
              <CopyTxt copiedTxt={`${ENV_URL}/sign-in`} />
            </span>
          )
        }
      ]
    };

    let dialogTitle, onClick, buttonPurpose, content;

    if (modalData?.targetID === CHOOSE_FROM_LIST) {
      dialogTitle = t('RegisterOrganisationInTheAdmissionList');
      onClick = () => {
        this.saveTournInvitations(
          {
            tournamentId: tournamentData?.id,
            selectedCheckboxes: modalData?.selectedCheckboxes,
            isMultiInvitations: true
          },
          () => {
            this.triggerFetchTournInvitations(this.hideModal);
          }
        );
      };
      buttonPurpose = t('addRecord', { name: '' });
      content = (
        <>
          <SearchInput
            onChange={(e) => this.onChangeSearchText(e, 'modalData')}
            id="search-input_table_registration"
            onKeyPress={(e) => this.onEnterPress(e, 'modalData')}
            value={modalData?.searchBar || ''}
            isSearchInactive={!modalData?.searchBar}
            clearSearch={() => this.onClearSearch('modalData')}
            className={classes.marginBottom}
          />
          <TableList data={filteredCoaches} {...{ recordDataParams }} />
        </>
      );
    } else if (modalData?.targetID === IMPORTS_BY_TOURNAMENT) {
      dialogTitle = t('importByTounTitle');
      content =
        importsGetList?.length > 0 ? (
          <TableList
            data={importsGetList ?? []}
            recordDataParams={recordDataParamsImportsGet}
          />
        ) : (
          t('noRecords')
        );
      buttonPurpose = t('deleteRecord', { name: '' });
      onClick = () => {
        this.importDelete(
          {
            tournamentId: tournamentData?.id,
            importKeys: modalData?.selectedCheckboxes
          },
          () => {
            this.hideModal();
          }
        );
      };
    } else {
      dialogTitle = t('addOrganisation');
      content = (
        <CoachForm
          inputs={dojoInfo}
          onBlur={this.onBlur}
          {...{ showError, countries }}
          changeTxt={this.textChangeHandler}
          saveCoachData={this.onSave}
          changeImage={this.changeImage}
          changeDate={(date) =>
            this.changeDate(date, 'modalData', errors, true)
          }
          values={modalData}
          param={'modalData'}
          selectOption={this.selectOption}
          fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
          {...{ allRegions, errors }}
        />
      );
    }

    return (
      <>
        <SideModal
          closeModal={this.hideSnackBar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            {/* Modal Window */}
            <Modal
              open={modalData?.open}
              close={this.hideModal}
              discardOrKeepEditing={this.discardOrKeepEditing}
              {...{
                onClick,
                dialogTitle,
                buttonPurpose,
                shouldShowDiscardChanges
              }}
              specificDetails={{
                className: {
                  ...(hasDesktopSize ? { paper: classes.modalWidth } : {})
                }
              }}>
              {!modalData?.isLoading ? (
                modalData?.open && content
              ) : (
                <CircularProgress style={{ margin: 'auto', display: 'flex' }} />
              )}
            </Modal>

            <HeaderTournInfo
              {...{ tournamentData, viewportWidth, pageHeaderStatistics }}
              shouldShowBtn={true}
            />
            {!!+tournamentData?.limited_reg_on && (
              <Invitation
                {...{ filters }}
                showModal={this.showModal}
                {...{ filteredCoaches }}
                tournInvitations={tournInvitationsFiltered}
                onSaveInvitations={(elem) => {
                  this.saveTournInvitations(elem, () => {
                    this.triggerFetchTournInvitations(this.hideModal);
                  });
                }}
                onDelete={(elem) => {
                  this.deleteTournInvitations(elem, () => {
                    this.triggerFetchTournInvitations();
                  });
                }}
                specificDetails={{ FIRST_NAME, LAST_NAME }}
              />
            )}
            {!!+tournamentData?.section_import_on && (
              <Excel
                onDropExcel={this.onDropExcel}
                onResizeColumn={this.onResizeColumn}
                onToggleOptionalFields={this.onToggleOptionalFields}
                onTogglePassedStatus={this.onTogglePassedStatus}
                onToggleCategoryTypes={this.onToggleCategoryTypes}
                onDragEnd={this.onDragEnd}
                onSwitchRepresentative={this.onSwitchRepresentative}
                onImportExcel={this.onImportExcel}
                showModal={this.showModal}
                {...{ excelData, tournamentData, role }}
              />
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(InviteCoaches)));
