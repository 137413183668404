import { Fragment, Component } from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';

import { Tooltip } from '@material-ui/core';

import { ReactComponent as AddIcon } from 'assets/img/new_assign_grey.svg';
import { ReactComponent as EditIcon } from 'assets/img/new_edit_grey.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/new_trash_grey.svg';
import { ReactComponent as DropdownIcon } from 'assets/img/dropdown_black&white_bg.svg';
import { ReactComponent as EditGreyBgBorder } from 'assets/img/edit_grey_bg&border.svg';

import { AuthContext } from '../../AuthContext';

import {
  pageChangeHandler,
  rowsPerPageHandler,
  clearSearch,
  handleTextChange,
  deleteCategory,
  deleteCategoryGroup,
  fetchCategories,
  fetchAllCategoryTypes,
  changeModal
} from '../../helpers/util';
import {
  DELETE_ICON,
  ADD_NEW_GROUP_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_GROUP,
  ADD_CATEGORY,
  UPDATE_GROUP_CATEGORY,
  TEST_ID_FORM,
  FILTERS_CHECKBOXES
} from '../../helpers/constants';
import { changeTitle } from '../../helpers/actions';
import { isMobileView, convertMs } from 'helpers/selectors';
import { SearchInput } from 'components';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '../../components/Buttons/ActionButtons';
import LoadingState from '../../components/LoadingState/LoadingState';
import Pagination from '../../components/TablePagination/TablePagination';
import Modal from '../../components/Modal/ModalNewDesign';
import AddCategory from '../AddCategory/AddCategory';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import SideModal from '../../components/Snackbar/SideModal';
import Table from '../../components/Table/CardsList';
import FilterCheckboxes from 'components/FilterOptions/FilterCheckboxes';

import styles from './styles';
import EmptyState from 'components/EmptyState/EmptyState';

class Categories extends Component {
  state = {
    categories: [],
    categoryTypes: [],
    open: false,
    showModal: false,
    shouldUpdateTable: false,
    loading: true,
    success: false,
    isSearching: false,
    searchedValue: '',
    searchBar: '',
    filteredCategories: [],
    langOnLoad: localStorage.getItem('i18nextLng'),
    rowsPerPage:
      (localStorage.getItem('rowsPerPage') &&
        +localStorage.getItem('rowsPerPage')) ||
      25,
    page: 1,
    categoriesCount: 0,
    modalData: {},
    selectedCheckboxes: [],
    collapsed: [],
    isFilterOpen: false
  };

  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  clearSearch = clearSearch.bind(this);
  handleTextChange = handleTextChange.bind(this);
  deleteCategory = deleteCategory.bind(this);
  deleteCategoryGroup = deleteCategoryGroup.bind(this);
  changeModal = changeModal.bind(this);
  fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
  fetchCategories = fetchCategories.bind(this);

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const {
      shouldUpdateTable,
      page,
      searchBar,
      rowsPerPage,
      typeList,
      genderList,
      ageList,
      ownerList,
      collapsed,
      langOnLoad
    } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      shouldUpdateTable &&
      shouldUpdateTable !== prevState.shouldUpdateTable
    ) {
      this.fetchData(
        page,
        rowsPerPage,
        () => {
          const { categories } = this.state;
          const copyCollapsed = [...collapsed];
          const len = copyCollapsed.length;

          for (let i = 0; i < len; i++) {
            const findCategory = categories.find(
              (it) => +it?.group_id === +copyCollapsed[i]?.id
            );

            if (findCategory) {
              copyCollapsed[i].categories = findCategory.categories;

              this.setState({ collapsed: copyCollapsed });
            }
          }
        },
        typeList,
        genderList,
        ageList,
        ownerList,
        searchBar
      );
    } else if (searchBar !== prevState.searchBar) {
      this.setState({ isSearching: true });
    }

    if (langOnLoad !== currentLang) {
      this.fetchAllCategoryTypes((types) => {
        this.setState({
          ...(types ? { categoryTypes: types } : {})
        });
      });

      this.setState({ langOnLoad: currentLang });

      changeTitle(t('categories'));
    }
  }

  componentDidMount() {
    const { page, rowsPerPage } = this.state;

    this.fetchData(page, rowsPerPage, () =>
      this.fetchAllCategoryTypes((types) => {
        this.setState({
          ...(types ? { categoryTypes: types } : {}),
          loading: false
        });
      })
    );

    changeTitle(this.props.t('categories'));
  }

  fetchData = (
    page,
    rowsPerPage,
    action,
    typeList,
    genderList,
    ageList,
    ownerList,
    search
  ) => {
    this.fetchCategories(
      () => {
        action && action();
        this.setState({ isSearching: false });
      },
      null,
      null,
      page,
      rowsPerPage,
      null,
      null,
      typeList,
      genderList,
      ageList,
      ownerList,
      search,
      true // grouped_mode (group categories by group_id)
    );
  };

  showModal = (item) => {
    this.setState({
      categoryId: item.category_id,
      modalData: item,
      open: true
    });
  };

  showModalForm = (evt, item, showFormName, specificDetails) => {
    evt.preventDefault();

    const { categoryTypes } = this.state;

    const id =
      showFormName === ADD_NEW_GROUP_CATEGORY || showFormName === ADD_CATEGORY
        ? null
        : showFormName === UPDATE_GROUP
        ? item?.group_id
        : item?.category_id;
    const selectedRow =
      showFormName === ADD_NEW_GROUP_CATEGORY
        ? null
        : showFormName === UPDATE_GROUP || showFormName === ADD_CATEGORY
        ? item?.group_id
        : item?.category_id;
    const shouldUpdateGroup = showFormName === 'UPDATE_GROUP';

    this.setState({
      shouldShowForm: showFormName,
      pressedIdBtn: TEST_ID_FORM,
      open: true,
      selectedRow
    });
    const currentCategoryType = categoryTypes?.find(
      (it) => +it.id === +item?.category_type_id
    );

    if (id) {
      this.setState({
        modalData: {
          group_category_name: shouldUpdateGroup
            ? item?.group_name
            : item?.category_name,
          group_category_alt_name: shouldUpdateGroup
            ? item?.group_alt_name
            : item?.category_alt_name,
          group_category_age_from: shouldUpdateGroup
            ? item?.group_age_from
            : item?.age_from,
          group_category_age_to: shouldUpdateGroup
            ? item?.group_age_to
            : item?.age_to,
          group_category_gender: shouldUpdateGroup
            ? item?.group_gender
            : item?.gender,
          group_category_type: +item?.category_type_id,
          category_id: item?.category_id,
          group_id:
            item?.group_id ?? specificDetails?.toggleForms?.parent?.group_id,
          category_name_plus: item?.category_name_plus,
          category_alt_name_plus: item?.category_alt_name_plus,
          category_weight_from: item?.weight_from || item?.weight_to,
          weighted: currentCategoryType?.weighted,
          category_type_name: currentCategoryType?.name,
          ...(showFormName === UPDATE_CATEGORY ||
          showFormName === UPDATE_GROUP_CATEGORY
            ? { form_title_name: item?.category_name }
            : {}),
          isCategoryAbsolute:
            +item?.category_type_id === 2 &&
            item?.age_from &&
            !item?.age_to &&
            !item?.weight_from &&
            !item?.weight_to,
          is_owner: item?.is_owner,
          ...(specificDetails?.toggleForms
            ? { ...specificDetails.toggleForms }
            : {})
        }
      });
    } else {
      this.setState({
        //autofill in the category group form with group data
        modalData: {
          ...(showFormName === ADD_CATEGORY
            ? { form_title_name: item?.group_name }
            : {}),
          group_id: item?.group_id,
          group_category_name: item?.group_name,
          group_category_alt_name: item?.group_alt_name ?? item?.group_name,
          category_name_plus: item?.group_name,
          category_alt_name_plus: item?.group_alt_name ?? item?.group_name,
          group_category_age_from: item?.group_age_from,
          group_category_age_to: item?.group_age_to,
          group_category_gender: item?.group_gender,
          group_category_type: +item?.category_type_id,
          weighted: currentCategoryType?.weighted,
          category_type_name: currentCategoryType?.name,
          isAbsoluteChecked: false,
          ...(specificDetails?.toggleForms
            ? { ...specificDetails.toggleForms }
            : {})
        }
      });
    }
  };

  triggerTableUpdate = () => {
    const { open, shouldShowForm } = this.state;

    this.setState({ shouldUpdateTable: true });

    if (open && !this.isFormTypeToggleForm(shouldShowForm)) {
      this.hideModal();
    }
  };

  // check if to show 'Toggle Form Arrows'
  isFormTypeToggleForm = (formType) => {
    return [UPDATE_CATEGORY, UPDATE_GROUP, UPDATE_GROUP_CATEGORY].includes(
      formType
    );
  };

  hideModal = () => {
    this.setState({ open: false, selectedRow: null, pressedIdBtn: null });
  };

  closeModalHandler = () => {
    this.setState({ showModal: false });
  };

  onSelectCheckbox = (item) => {
    const { selectedCheckboxes, rowsPerPage, searchBar } = this.state;
    let genderList = [];
    let typeList = [];
    let ageList = [];
    let ownerList = [];

    const checkedValues = selectedCheckboxes.some((it) => it.id === item.id)
      ? selectedCheckboxes.filter((it) => it.id !== item.id)
      : [...selectedCheckboxes, item];

    this.setState({ page: 1, selectedCheckboxes: checkedValues }, () => {
      const len = this.state.selectedCheckboxes.length;

      for (let i = 0; i < len; i++) {
        const item = this.state.selectedCheckboxes[i];

        if (item.type) {
          typeList = [...typeList, item.id];
        } else if (item.gender) {
          genderList = [...genderList, item.gender];
        } else if (item.owner) {
          ownerList = [...ownerList, item.owner];
        } else {
          ageList = [...ageList, item.ageInterval];
        }
      }

      this.setState({ typeList, genderList, ageList, ownerList });

      this.fetchData(
        this.state.page,
        rowsPerPage,
        null,
        typeList,
        genderList,
        ageList,
        ownerList,
        searchBar
      );
    });
  };

  searchHandler = () => {
    const { searchBar, rowsPerPage, typeList, genderList, ageList, ownerList } =
      this.state;

    this.setState({ searchedValue: searchBar, page: 1 }, () => {
      this.fetchData(
        this.state.page,
        rowsPerPage,
        null,
        typeList,
        genderList,
        ageList,
        ownerList,
        searchBar
      );
    });
  };

  pressEnterHandler = (event) => {
    if (event.key === 'Enter') {
      this.searchHandler();
    }
  };

  onPageChange = (e, next) => {
    const {
      isSearching,
      rowsPerPage,
      searchBar,
      typeList,
      genderList,
      ageList,
      ownerList
    } = this.state;

    this.pageChangeHandler(e, next, () => {
      if (isSearching) {
        this.searchHandler();
      } else {
        this.fetchData(
          this.state.page,
          rowsPerPage,
          null,
          typeList,
          genderList,
          ageList,
          ownerList,
          searchBar
        );
      }
    });
  };

  expandTableRow = (evt, item) => {
    evt.preventDefault();

    const { collapsed } = this.state;
    const allCollapsedTables = [...collapsed];

    if (allCollapsedTables) {
      const findIndex = allCollapsedTables.findIndex(
        (it) => it.id === +item?.group_id
      );

      if (findIndex === -1) {
        this.setState((prevState) => ({
          collapsed: [
            ...prevState.collapsed,
            {
              id: +item.group_id,
              categories: item?.categories
            }
          ]
        }));
      } else {
        allCollapsedTables.splice(findIndex, 1);

        this.setState({ collapsed: allCollapsedTables });
      }
    }
  };

  updateNewFormNameOnCategorySelection = (data) => {
    //used to Add New, when choosing a type of category
    this.setState(data);
  };

  filterVision = () => {
    this.setState({ isFilterOpen: !this.state.isFilterOpen });
  };

  cancelFilter = () => {
    const { page, rowsPerPage } = this.state;

    this.setState({
      selectedCheckboxes: [],
      page: 1
    });

    this.fetchData(page, rowsPerPage, () =>
      this.fetchAllCategoryTypes((types) => {
        this.setState({
          ...(types ? { categoryTypes: types } : {}),
          loading: false
        });
      })
    );
  };

  render() {
    const {
      showModal,
      modalInfo,
      success,
      loading,
      filteredCategories,
      categoryTypes,
      rowsPerPage,
      page,
      open,
      shouldShowForm,
      selectedRow,
      categoriesCount,
      selectedCheckboxes,
      searchedValue,
      searchBar,
      modalData,
      pressedIdBtn,
      collapsed,
      isFilterOpen
    } = this.state;
    const { t } = this.props;
    const { authState, viewportWidth } = this.context;
    const { role } = authState;
    const { classes } = this.props;
    const hasMobileView = isMobileView(viewportWidth);

    const isUpdateForm =
      shouldShowForm === UPDATE_CATEGORY ||
      shouldShowForm === UPDATE_GROUP_CATEGORY ||
      shouldShowForm === UPDATE_GROUP;

    const currentName = (item) => (
      <>
        {item.category_name_plus ? (
          <span className={classes.flex}>
            <span className={clsx(classes.flex, classes.column)}>
              <span>
                1. {item?.[!+item.group_id ? 'category_name' : 'group_name']}
                {role === 'administrator' && ` (${item.category_id})`}
              </span>
              <span className={classes.ellipsis}>
                2. {item.category_name_plus}
                {role === 'administrator' && ` (${item.category_id_plus})`}
              </span>
            </span>
          </span>
        ) : (
          item?.[!+item.group_id ? 'category_name' : 'group_name']
        )}
      </>
    );
    const mainIcons = (item, idx, className) => {
      return (
        (item.is_owner === '1' || role === 'administrator') && (
          <>
            <Tooltip
              title={t('updateRecord', {
                name: t(!+item?.group_id ? 'category' : 'groupAddUpdate')
              })}>
              <EditIcon
                className={clsx(className)}
                onClick={(evt) =>
                  this.showModalForm(
                    evt,
                    item,
                    item?.category_id ? UPDATE_CATEGORY : UPDATE_GROUP,
                    {
                      toggleForms: { currentRecordIdx: idx }
                    }
                  )
                }
              />
            </Tooltip>
            {!!+item?.group_id && (
              <Tooltip arrow title={t('addWeightCategory')}>
                <AddIcon
                  className={clsx(className, classes.marginLeft1)}
                  onClick={(evt) => this.showModalForm(evt, item, ADD_CATEGORY)}
                />
              </Tooltip>
            )}
            <Tooltip
              title={t('deleteRecord', {
                name: t(!+item?.group_id ? 'category' : 'groupAddUpdate')
              })}>
              <DeleteIcon
                id={DELETE_ICON}
                className={clsx(className, classes.marginLeft1)}
                onClick={() => this.showModal(item)}
              />
            </Tooltip>
          </>
        )
      );
    };

    const recordDataParams = (item, idx) => {
      const categoryTypeName = categoryTypes?.find(
        (it) => +it.id === +item?.category_type_id
      )?.type;
      const id = item?.category_id || item?.group_id;

      return {
        selected: selectedRow === id,
        class: { gridRecords: classes.gridRecordsAttachments },
        main: {
          style: {
            wrapper: classes.marginTop1,
            cardMainPart: classes.cardMainPart,
            recordName: classes.cardMainName,
            cardValuePart: classes.cardValuePart
          },
          rowOrder: {
            value: (page - 1) * rowsPerPage + idx + 1,
            class: classes.rowOrder
          },
          name: currentName(item)
        },
        info: [
          {
            value: item?.[!+item.group_id ? 'age_from' : 'group_age_from'],
            name: `${t('age')} ${t('from').toLowerCase()}`
          },
          {
            value: item?.[!+item.group_id ? 'age_to' : 'group_age_to'],
            name: `${t('age')} ${t('to').toLowerCase()}`
          },
          {
            value: categoryTypes?.find(
              (it) => +it.id === +item?.category_type_id
            )?.name,
            name: t('discipline')
          },
          {
            value: item?.[!+item.group_id ? 'gender' : 'group_gender'],
            name: t('gender')
          },
          ...(role === 'administrator'
            ? [{ value: item.master_id, name: t('owner') }]
            : []),
          categoryTypeName !== 'kata' &&
          categoryTypeName !== 'teamkata' &&
          categoryTypeName !== 'break' &&
          categoryTypeName !== 'katabyflags'
            ? {
                value: (
                  <span
                    onClick={(evt) => {
                      this.expandTableRow(evt, item);
                    }}
                    className={clsx(
                      classes.flex,
                      classes.onHover,
                      classes.centerVertically
                    )}>
                    {item?.categories_in_group_count}
                    {+item?.categories_in_group_count > 0 && (
                      <DropdownIcon
                        className={clsx(
                          classes.flex,
                          classes.onHover,
                          classes.marginLeft0dot4,
                          !!collapsed?.find(
                            (it) => +it?.id === +item?.group_id
                          ) && classes.rotate180
                        )}
                      />
                    )}
                  </span>
                ),
                name: t('weightCategories')
              }
            : { value: '' },
          ...(!hasMobileView
            ? [
                {
                  value: (item.is_owner === '1' ||
                    role === 'administrator') && (
                    <span className={classes.flex}>
                      {mainIcons(item, idx, [
                        classes.flex,
                        classes.onHover,
                        classes.buttonsSize
                      ])}
                    </span>
                  ),
                  className: [classes.selfAlignCenter]
                }
              ]
            : [])
        ]
      };
    };

    const collapsedRecordDataParams = (item, idx, parent, className) => {
      const convertedTime = convertMs(item.main_time_ms);
      const resultTime = [convertedTime.minutes, convertedTime.seconds].join(
        ':'
      );

      return {
        class: { gridRecords: classes.gridRecordsAttachments },
        main: {
          name: currentName(item),
          style: {
            cardMainPart: classes.bgWhite
          }
        },
        info: [
          {
            value: item?.age_from,
            name: `${t('age')} ${t('from').toLowerCase()}`
          },
          {
            value: item?.age_to,
            name: `${t('age')} ${t('to').toLowerCase()}`
          },
          {
            value: categoryTypes?.find(
              (it) => +it.id === +item?.category_type_id
            )?.name,
            name: t('discipline')
          },
          {
            value: item?.gender,
            name: t('gender')
          },
          {
            value: item?.weight_to,
            name: +item?.category_type_id !== 8 ? t('weight') : t('units')
          },
          ...(role === 'administrator'
            ? [{ value: item.master_id, name: t('owner') }]
            : []),
          {
            value: resultTime,
            name: [t('time'), '(' + t('min') + ':' + t('sec') + ')'].join(' ')
          },
          ...(role === 'administrator'
            ? [{ value: item.master_id, name: t('owner') }]
            : []),
          {
            value: +item?.is_owner === 1 && (
              <span
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1.5em 1.5em',
                  gap: '1rem'
                }}>
                <Tooltip title={t('updateWeightCategory')}>
                  <EditGreyBgBorder
                    className={clsx(className)}
                    onClick={(evt) =>
                      this.showModalForm(evt, item, UPDATE_GROUP_CATEGORY, {
                        toggleForms: {
                          parent: parent,
                          currentRecordIdx: idx
                        }
                      })
                    }
                  />
                </Tooltip>
                <Tooltip title={t('deleteWeightCategory')}>
                  <DeleteIcon
                    id={DELETE_ICON}
                    className={clsx(className, classes.borderBtn)}
                    onClick={() => this.showModal(item)}
                  />
                </Tooltip>
              </span>
            )
          }
        ]
      };
    };

    let dialogTitle,
      onSwitchBetween,
      dialogContent,
      buttonPurpose,
      subHeader,
      onClick;

    if (pressedIdBtn === TEST_ID_FORM) {
      dialogTitle = isUpdateForm
        ? shouldShowForm === UPDATE_GROUP
          ? t('updateRecord', {
              name: [
                t('categoryGroup'),
                modalData?.category_type_name?.toUpperCase()
              ].join(' ')
            })
          : [
              !modalData.isCategoryAbsolute &&
              shouldShowForm !== UPDATE_CATEGORY
                ? t('updateWeightCategory')
                : t('updateCategory'),
              modalData?.category_type_name?.toUpperCase(),
              `(${modalData?.form_title_name})`
            ].join(' ')
        : shouldShowForm === ADD_CATEGORY
        ? [
            t('addWeightCategory'),
            `${modalData?.category_type_name?.toUpperCase()} ${
              modalData?.form_title_name ? `(${modalData.form_title_name})` : ''
            }`
          ].join(' ')
        : [
            modalData.weighted &&
            (+modalData.group_category_type === 2
              ? !modalData.isAbsoluteChecked
              : true)
              ? t('addRecord', { name: t('categoryGroup') })
              : t('addAgeCategory'),
            modalData?.category_type_name?.toUpperCase()
          ].join(' ');
      dialogContent = (
        <AddCategory
          state={this.state}
          {...{ isUpdateForm }}
          updateNewFormNameOnCategorySelection={
            this.updateNewFormNameOnCategorySelection
          }
          triggerTableUpdate={this.triggerTableUpdate}
        />
      );

      const getArray = (index, parent, categories) => {
        return parent ? parent?.categories?.[index] : categories[index];
      };

      const onOpenForm = (evt, item, specificDetails) => {
        const formType = !specificDetails?.toggleForms?.parent
          ? item?.category_id
            ? UPDATE_CATEGORY
            : UPDATE_GROUP
          : UPDATE_GROUP_CATEGORY;

        this.showModalForm(evt, item, formType, specificDetails);
      };

      onSwitchBetween = this.isFormTypeToggleForm(shouldShowForm) && {
        prev: (evt) => onToggleFrom(evt, -1),
        next: (evt) => onToggleFrom(evt, 1)
      };

      // toggle form (prev/next)
      const onToggleFrom = (evt, direction) => {
        const currentIndex = modalData.currentRecordIdx;
        const nextIndex = currentIndex + direction;

        const parentElem = getParentElement(modalData.parent);
        const len = parentElem
          ? parentElem.categories?.length
          : filteredCategories?.length;

        if (nextIndex >= 0 && nextIndex <= len - 1) {
          const item = getItemFromIndex(nextIndex, parentElem);

          onOpenForm(evt, item, {
            toggleForms: {
              parent: modalData?.parent,
              currentRecordIdx: nextIndex
            }
          });
        }
      };

      // get parent element
      const getParentElement = (parent) => {
        return (
          parent &&
          filteredCategories.find((it) => +it.group_id === +parent?.group_id)
        );
      };

      // get item from index
      const getItemFromIndex = (index, parentElem) => {
        return getArray(
          index,
          parentElem,
          parentElem ? parentElem.categories : filteredCategories
        );
      };
    } else {
      dialogTitle = t(
        !!+modalData?.category_id ? 'deleteCategory' : 'deleteCategoryGroup'
      );
      dialogContent = t(
        !!+modalData?.category_id
          ? 'deleteCategoryMsg'
          : 'deleteCategoryGroupMsg'
      );
      buttonPurpose = t('deleteRecord', { name: '' });
      subHeader = modalData && (
        <span className={clsx(classes.flex, classes.marginTop1)}>
          {!!+modalData?.category_id
            ? modalData.category_name
            : modalData.group_name}
        </span>
      );
      onClick = () =>
        !!+modalData?.category_id
          ? this.deleteCategory()
          : this.deleteCategoryGroup(modalData?.group_id);
    }

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{ open }}
          close={this.hideModal}
          {...{
            onClick,
            subHeader,
            dialogTitle,
            onSwitchBetween,
            dialogContent,
            buttonPurpose
          }}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <span className={clsx(classes.gridButtons)}>
              <SearchInput
                id="search-input"
                className={classes.search}
                onChange={this.handleTextChange}
                clearSearch={() =>
                  this.clearSearch(searchedValue, () => this.searchHandler())
                }
                onKeyPress={this.pressEnterHandler}
                value={searchBar}
                isSearchInactive={!searchBar}
                style={{ gridArea: 'searchInput' }}
              />
              <Button
                onClick={() => this.filterVision()}
                label={
                  <span
                    className={clsx(
                      classes.flex,
                      classes.verticallyCentered,
                      classes.center,
                      classes.flexGrow1
                    )}>
                    <span className={classes.flexGrow1}>{t('filter')}</span>
                    <ArrowDropDownIcon
                      className={isFilterOpen ? classes.arrowDropUp : undefined}
                    />
                  </span>
                }
                isSaveBtn={!isFilterOpen}
                className={isFilterOpen && classes.selected}
                style={{ gridArea: 'filterButton' }}
              />
              <Button
                onClick={(evt) =>
                  this.showModalForm(evt, null, ADD_NEW_GROUP_CATEGORY)
                }
                isSaveBtn={true}
                label={
                  <>
                    <span className={classes.plus}>&#x002B;</span>
                    {t('addNew')}
                  </>
                }
                style={{ gridArea: 'add' }}
              />
            </span>
            <FilterCheckboxes
              {...{
                categoryTypes,
                selectedCheckboxes,
                isFilterOpen
              }}
              filterDetails={{
                ownerCategories: true,
                tounamentPresence: false,
                noUnisex: false
              }}
              onSelectCheckbox={this.onSelectCheckbox}
              cancelFilter={() => this.cancelFilter()}
            />
            {filteredCategories.length != 0 ? (
              <>
                <Table
                  {...{
                    recordDataParams,
                    collapsedRecordDataParams,
                    collapsed
                  }}
                  data={filteredCategories}
                  onClick={(evt, item, idx) =>
                    this.showModalForm(
                      evt,
                      item,
                      item?.category_id ? UPDATE_CATEGORY : UPDATE_GROUP,
                      {
                        toggleForms: { currentRecordIdx: idx }
                      }
                    )
                  }
                  {...{ role }}
                  subTableDataKey="categories"
                  id="group_id"
                  // shouldAllowEditing={(item) =>
                  //   +item?.is_owner === 1 || role === 'administrator'
                  // }
                  actionIcons={(item, idx, className) => {
                    return hasMobileView && mainIcons(item, idx, className);
                  }}
                  expandTableRow={(evt, item) => this.expandTableRow(evt, item)}
                  // selectedSubTable={(item) => {
                  //   const id = item.category_id;

                  //   return selectedRow === id;
                  // }}
                />

                <Pagination
                  count={categoriesCount}
                  onPageChange={this.onPageChange}
                  onRowsPerPageChange={(evt) =>
                    this.rowsPerPageHandler(evt, this.searchHandler)
                  }
                  {...{ page }}
                  {...{ rowsPerPage }}
                />
              </>
            ) : (
              <EmptyState
                className={classes.emptyState}
                emptyStateText={t('noResults')}
              />
            )}
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(Categories));
