import { Component } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../components/withRouter';
import { withStyles } from '@material-ui/styles';

import DeleteIcon from '@material-ui/icons/Delete';

import { CircularProgress, TextField } from '@material-ui/core';

import {
  OK,
  BRACKET,
  CATEGORY_TYPE,
  ID,
  MAP,
  DELETE_ICON
} from '../../helpers/constants';

import {
  fetchTournaments,
  fetchTournamentTatamis,
  fetchTatamis,
  fetchAllTBKS,
  fetchTBK,
  downloadFile,
  changeModal,
  validateForm,
  selectOption,
  changeHead,
  fetchAllCategoryTypes,
  textChangeHandler,
  removeTBK,
  deleteTournament,
  fetchReference
} from '../../helpers/util';
import {
  categoryTypesPresentOnTournament,
  findSortDirection,
  compareValueForSorting,
  generate6DigitsCode,
  isMobileView,
  getBoundingBox
} from '../../helpers/selectors';

import { AuthContext } from '../../AuthContext';

import SideModal from '../../components/Snackbar/SideModal';
import LoadingState from '../../components/LoadingState/LoadingState';
import Table from '../../components/Table/Table';
import Body from '../../components/Tournament/Body/Body';
import Modal from '../../components/Modal/ModalNewDesign';
import FilterBracketData from '../../components/Tournament/FilterBracket/FilterBracketData';
import PageNotFound from '../../components/NotFound/NotFound';
import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';

import styles from './Styles';

class Tournament extends Component {
  state = {
    tournamentId: this.props.match.params.id,
    tournamentData: {},
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    showModal: false,
    success: false,
    open: false,
    categoryTypes: [],
    tourTatamis: [],
    sortDirection: [],
    bracketErrors: {},
    bracketForm: {},
    modalData: {}
  };
  fetchTournaments = fetchTournaments.bind(this);
  fetchTournamentTatamis = fetchTournamentTatamis.bind(this);
  fetchTatamis = fetchTatamis.bind(this);
  fetchAllTBKS = fetchAllTBKS.bind(this);
  fetchTBK = fetchTBK.bind(this);
  downloadFile = downloadFile.bind(this);
  changeModal = changeModal.bind(this);
  removeTBK = removeTBK.bind(this);
  validateForm = validateForm.bind(this);
  selectOption = selectOption.bind(this);
  changeHead = changeHead.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  deleteTournament = deleteTournament.bind(this);
  fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
  fetchReference = fetchReference.bind(this);

  static contextType = AuthContext;

  componentDidMount() {
    const { tournamentId, langOnLoad } = this.state;
    const { t, navigate } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;

      this.setState({ loading: false });

      // below section is used to set the tournament name in the page header
      navigate('.', {
        replace: true,
        state: {
          tournament_name: tournamentData?.tournament_name
        }
      });

      changeHead(tournamentData, t('tournament'));
    });

    // this.fetchReference(
    //   4113,
    //   (data) => {
    //     const filteredData = data.filter((item) => item.name === 'event');
    //     this.setState({ iframeURL: filteredData[0] });
    //   },
    //   null,
    //   langOnLoad
    // );
  }

  componentDidUpdate(prevProps, prevState) {
    const { tournamentData, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      changeHead(tournamentData, t('tournament'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  selectTatami = (_, value) => {
    const { bracketErrors } = this.state;
    if (value !== null) {
      this.setState((prevState) => ({
        bracketForm: {
          ...prevState.bracketForm,
          selectedTatami: value
        }
      }));
      delete bracketErrors.selectedTatami;
    }
  };

  onSelectCategoryType = (_, value) => {
    const { tournamentData, bracketErrors, sortDirection } = this.state;
    const { role } = this.context.authState;

    this.selectOption(
      _,
      value,
      'bracketForm',
      CATEGORY_TYPE,
      ID,
      bracketErrors,
      () => {
        this.fetchTournamentTatamis(
          {
            tournamentId: tournamentData.id,
            typeId: value.id
          },
          (data) => {
            this.setState({ tourTatamis: data });
          }
        );

        if (role === 'administrator') {
          this.fetchAllTBKS(tournamentData.id, value.id, () => {
            if (sortDirection?.length > 0) {
              this.onSort(sortDirection[2], null, true);
            }
          });
        }
      },
      true
    );
  };

  showModal = (evt, elem) => {
    const { t } = this.props;
    const { tournamentData } = this.state;
    const { category_types } = tournamentData;
    const pressedIcon = elem?.targetID ?? evt.target.id;

    if (pressedIcon === MAP) {
      this.setState({ isIntermediateLoading: true });

      getBoundingBox(elem?.searchedAddress)
        .then((result) => {
          if (result) {
            const { mapUrl } = result;

            this.setState({
              searched: {
                value: elem?.searchedAddress,
                mapUrl
              },
              isIntermediateLoading: false
            });

            return mapUrl; // Return the map URL
          } else {
            throw new Error('Bounding box not found.');
          }
        })
        .catch((error) => {
          this.setState({
            isIntermediateLoading: false
          });
          this.changeModal(`${t('notFound')}. ${error}`);
        });
    }

    new Promise((resolve) => {
      if (pressedIcon === BRACKET) {
        this.fetchAllCategoryTypes((allSystemTypes) => {
          const categoryTypes = categoryTypesPresentOnTournament(
            allSystemTypes || [],
            category_types
          );

          resolve({ tourTatamis: [], categoryTypes });
        });
      } else {
        resolve();
      }
    }).then((res) => {
      this.setState({ open: true, ...res, pressedIcon });
    });
  };

  hideModal = () => {
    this.setState({
      open: false,
      allTBKS: [],
      modalData: {},
      bracketErrors: {},
      shouldShowDiscardChanges: false,
      isEditing: false
    });
  };

  closeSideModalHandler = () => this.setState({ showModal: false });

  onSort = (sortField, idxCell, noFirstTimeSort) => {
    const { allTBKS, sortDirection } = this.state;
    const clone = [...allTBKS];

    let field;
    switch (sortField) {
      case 'user_name':
        field = 'user_name';
        break;
    }

    const direction = findSortDirection(
      sortDirection,
      sortField,
      noFirstTimeSort
    );
    const sortedData = compareValueForSorting(clone, field, direction);

    this.setState({
      ...(!noFirstTimeSort
        ? { sortDirection: [direction, idxCell, sortField] }
        : {}),
      allTBKS: sortedData
    });
  };

  onDelete = (data, action) => {
    const { tournamentData } = this.state;

    this.deleteTournament(
      {
        tournament_id: tournamentData?.id,
        ...(data ?? {})
      },
      (msg) => {
        action && action(msg);
      }
    );
  };

  onConfirmDelete = () => {
    const { modalData } = this.state;
    const { navigate, location } = this.props;

    const goBack = () => {
      navigate(`/events`, {
        state: { prevUrl: location.pathname }
      });
    };

    if (modalData?.force) {
      this.onDelete({ force: true }, (msg) => {
        this.onDelete({ ...(msg ?? {}) }, () => {
          goBack();
        });
      });
    } else {
      this.onDelete({}, () => {
        goBack();
      });
    }
  };

  onToggleForce = (evt) => {
    const { modalData } = this.state;
    const { checked } = evt.target;
    let cpy = { ...modalData };

    cpy = {
      ...cpy,
      force: checked,
      random: checked ? generate6DigitsCode() : null,
      hash: checked ? cpy.hash : null
    };

    this.setState({ modalData: cpy });
  };

  move2Grid = () => {
    const { navigate, location } = this.props;
    const { tournamentData, bracketForm } = this.state;

    navigate(
      `/event/${tournamentData?.id}/grid?category_type=${bracketForm.category_type}&tatami=${bracketForm?.selectedTatami?.tatami_id}`,
      {
        state: { prevUrl: location.pathname }
      }
    );
  };
  render() {
    const {
      tournamentData,
      loading,
      showModal,
      success,
      modalInfo,
      open,
      categoryTypes,
      pressedIcon,
      tourTatamis,
      sortDirection,
      allTBKS,
      bracketErrors,
      bracketForm,
      modalData,
      isIntermediateLoading
    } = this.state;
    const { t, classes } = this.props;
    const { authState, viewportWidth } = this.context;
    const { role } = authState;

    const mobileScreen = isMobileView(viewportWidth);
    const header = [
      { label: 'ID' },
      { label: t('attachment') },
      { label: [t('owner'), ' - ID'].join('') },
      { label: t('info') },
      { num: 'user_name', label: [t('owner'), ` - ${t('name')}`].join('') },
      { label: t('tatamis') },
      { label: t('date') }
    ];
    const tableBody = [
      'id',
      'file_name',
      'user_id',
      'user_info',
      'user_name',
      'tatamis',
      'updated_at'
    ];
    const condensedTableData = {
      mainName: 'file_name',
      info: [
        { key: 'id', name: 'ID' },
        { key: 'user_id', name: [t('owner'), ' - ID'].join('') },
        { key: 'user_info', name: t('info') },
        { key: 'user_name', name: t('owner') },
        { key: 'tatamis', name: t('tatamis') },
        { key: 'updated_at', name: t('date') }
      ]
    };

    let specificDetails,
      onClick,
      dialogTitle,
      buttonPurpose,
      shouldShowTopBottomDivider,
      subHeader,
      dialogContent;

    if (pressedIcon === BRACKET) {
      specificDetails = {
        className: {
          ...(role === 'administrator' ? { paper: classes.maxWidth } : {})
        }
      };
      onClick = (evt) =>
        this.validateForm(
          evt,
          { selectedTatami: '', category_type: '' },
          bracketForm,
          'bracketErrors',
          () => this.move2Grid()
        );
      dialogTitle = t('generateBracket');
      buttonPurpose = OK;
      shouldShowTopBottomDivider =
        role === 'administrator' && allTBKS?.length > 0;
      dialogContent = (
        <>
          {pressedIcon === BRACKET && (
            <FilterBracketData
              {...{ categoryTypes }}
              {...{ bracketErrors }}
              {...{ bracketForm }}
              selectCategoryType={this.onSelectCategoryType}
              selectedTatamis={tourTatamis}
              selectTatami={this.selectTatami}
              {...{ pressedIcon }}
            />
          )}
          {role === 'administrator' &&
            allTBKS?.length > 0 &&
            pressedIcon === BRACKET && (
              <Table
                className={classes.marginTop1}
                {...{ header }}
                {...{ condensedTableData }}
                shouldHideTableToolbar={true}
                body={allTBKS}
                data={tableBody}
                onClick={(evt, item) => this.fetchTBK(evt, item)}
                onSort={this.onSort}
                {...{ sortDirection }}
                shouldAllowEditing={(item) => item?.file_name}
                actionIcons={(item, _, className, iconWrapper) =>
                  item.file_name && (
                    <DeleteIcon
                      className={clsx(className, iconWrapper)}
                      onClick={(evt) =>
                        this.removeTBK(
                          evt,
                          tournamentData?.id,
                          bracketForm.category_type,
                          item.id,
                          () => {
                            this.fetchAllTBKS(
                              tournamentData.id,
                              bracketForm.category_type,
                              () => {
                                if (sortDirection?.length > 0) {
                                  this.onSort(sortDirection[2], null, true);
                                }
                              }
                            );
                          }
                        )
                      }
                    />
                  )
                }
              />
            )}
        </>
      );
    } else if (pressedIcon === 'MAP') {
      dialogTitle = t('eventAddress');
      dialogContent = (
        <>
          <div
            className={clsx(
              classes.flex,
              !mobileScreen ? classes.spaceBetween : classes.column,
              classes.marginBottom2
            )}>
            <div
              className={clsx(
                classes.flex,
                classes.column,
                mobileScreen && classes.marginBottom1
              )}>
              <span
                className={clsx(classes.addressText, classes.marginBottom05)}>
                {t('address')}
              </span>
              {tournamentData.address}
            </div>
            {tournamentData.org_phone && (
              <div className={clsx(classes.flex, classes.column)}>
                <span
                  className={clsx(classes.addressText, classes.marginBottom05)}>
                  {t('phone')}
                </span>
                {tournamentData.org_phone}
              </div>
            )}
          </div>
          {isIntermediateLoading ? (
            <div
              className={classes.iframeSize}
              style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <iframe
              className={classes.iframeSize}
              src={this.state.searched?.mapUrl}
            />
          )}
        </>
      );
    } else if (role === 'administrator' && pressedIcon === DELETE_ICON) {
      subHeader = tournamentData?.id && (
        <span className={clsx(classes.flex, classes.marginTop1)}>
          {tournamentData?.tournament_name}
        </span>
      );
      dialogTitle = t('deleteRecord', { name: t('event') });
      dialogContent = (
        <div style={{ display: 'grid', gap: '1rem' }}>
          {t('deleteEventMsg')}
          <CheckboxBtn
            checked={modalData?.force ?? false}
            onChange={this.onToggleForce}
            label={t('deletePermanently')}
          />
          {modalData?.force && modalData?.random && (
            <>
              {t('confirm2deletePermanently', {
                name: modalData?.random
              })}
              <TextField
                className={classes.txtField}
                name="confirmDigits"
                value={modalData?.confirmDigits || ''}
                onChange={(e) => this.textChangeHandler(e, 'modalData')}
                variant="outlined"
              />
            </>
          )}
        </div>
      );
      buttonPurpose = t('deleteRecord', { name: '' });
      onClick = this.onConfirmDelete;
      specificDetails = {
        disabled:
          modalData?.force &&
          (!modalData?.confirmDigits ||
            modalData?.confirmDigits !== modalData.random),
        className: { paper: classes.maxWidthConfirmDelete }
      };
    }

    return (
      <>
        <SideModal
          closeModal={this.closeSideModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{
            open,
            onClick,
            dialogContent,
            dialogTitle,
            buttonPurpose,
            shouldShowTopBottomDivider,
            subHeader
          }}
          close={this.hideModal}
          specificDetails={{
            ...(specificDetails ?? {})
          }}
        />
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <Body
            {...{ tournamentData }}
            {...{ viewportWidth }}
            showModal={this.showModal}
          />
        ) : (
          <PageNotFound />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Tournament)));
