import { Fragment, Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';

import LoadingState from '../../components/LoadingState/LoadingState';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import SideModal from '../../components/Snackbar/SideModal';
import DistributeParticipantsByCategoriesComponent from '../../components/DistributeParticipantsByCategoriesComponent/DistributeParticipantsByCategories';
import Modal from '../../components/Modal/Modal';
import Filter from '../../components/Filter/Filter';
import FilterCheckboxes from '../../components/FilterOptions/FilterCheckboxes';
import Button from '../../components/Buttons/ActionButtons';
import SearchInput from 'components/SearchInput/SearchInput';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import {
  fetchParticipantsByTournamentCategories,
  changeModal,
  fetchParticipantsByTournamentV2,
  distributeParticipantByCategory
} from '../../helpers/util';
import {
  selectedValue,
  finishedTournament,
  categoryTypesPresentOnTournament,
  findSortDirection,
  compareValueForSorting,
  isSmallScreen
} from '../../helpers/selectors';
import {
  TEST_ID_COPY,
  ALL_TEAMS,
  CLOSE_DISCARD,
  KEEP_EDITING
} from '../../helpers/constants';

import styles from './Styles';

class DistributeParticipantsByCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tournamentData: props.tournamentData || {},
      categoryTypes: categoryTypesPresentOnTournament(
        props?.categoryTypes ?? [],
        props?.tournamentData?.category_types ?? []
      ),
      loading: true,
      showModal: false,
      open: false,
      selectedCheckboxes: [],
      allRecords: [],
      filteredRecordsByPanel: {},
      collapsed: { panel_0: [], panel_1: [] },
      searchBar: '',
      role: localStorage.getItem('role'),
      selectedParticiantsCategory: {},
      activeFiltersPerPanel: {},
      isFilterOpen: false
    };

    this.fetchParticipantsByTournamentCategories =
      fetchParticipantsByTournamentCategories.bind(this);
    this.changeModal = changeModal.bind(this);
    this.fetchParticipantsByTournamentV2 =
      fetchParticipantsByTournamentV2.bind(this);
    this.distributeParticipantByCategory =
      distributeParticipantByCategory.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { categoryTypes, tournamentData } = this.props;
    const { isEditing, selectedParticiantsCategory } = this.state;

    if (prevProps?.categoryTypes !== categoryTypes) {
      const tournamentCat = categoryTypesPresentOnTournament(
        categoryTypes,
        tournamentData?.category_types
      );
      this.setState({ categoryTypes: tournamentCat });
    }

    if (
      isEditing &&
      selectedParticiantsCategory &&
      selectedParticiantsCategory !== prevState.selectedParticiantsCategory
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }
  }

  componentDidMount() {
    const { onGetPageHeaderStatistics } = this.props;

    onGetPageHeaderStatistics();
    this.fetchData();
  }

  fetchData = (action) => {
    const { tournamentData } = this.state;

    this.fetchParticipantsByTournamentCategories(
      tournamentData.id,
      { empty_categories: 1 },
      (categories) => {
        const shouldDisableEditing =
          finishedTournament(tournamentData) || !!+tournamentData?.finished;
        const allBlocksLeft =
          this.onFilterOutCategoriesWithZeroPart(categories);

        this.setState(
          {
            shouldDisableEditing,
            allRecords: categories,
            filteredRecordsByPanel: {
              panel_0: allBlocksLeft,
              panel_1: categories
            },
            loading: false
          },
          () => action && action()
        );
      }
    );
  };

  onFilterOutCategoriesWithZeroPart = (data) => {
    let filterOutCategoriesWithoutPart = [];
    const len = data?.length;

    for (let i = 0; i < len; i++) {
      const item = data[i];

      if (!!+item?.participants_count) {
        filterOutCategoriesWithoutPart = [
          ...filterOutCategoriesWithoutPart,
          item
        ];
      }
    }

    return filterOutCategoriesWithoutPart;
  };

  onSearch = (evt) => {
    const { value } = evt.target;

    this.setState({ searchBar: value });
  };

  onTxtSearch = (value, data) => {
    const len = data.length;
    let categories = [];

    for (let i = 0; i < len; i++) {
      const item = data[i];

      if (item?.name?.toLowerCase().includes(value.toLowerCase().trim())) {
        categories = [...categories, item];
      }
    }

    return categories;
  };

  onClearSearch = () => {
    this.setState({ searchBar: '' });
  };

  onSelectCheckbox = (key) => {
    const { selectedCheckboxes } = this.state;
    const checkedValues = selectedCheckboxes.some((item) => item.id === key.id)
      ? selectedCheckboxes.filter((it) => it.id !== key.id)
      : [...selectedCheckboxes, key];

    this.setState({ selectedCheckboxes: checkedValues });
  };

  onFilterBySelection = (checkedValues, action) => {
    const { allRecords, tournamentData } = this.state;
    let newData = [];

    if (checkedValues?.length > 0) {
      let genderArray = [];
      let ageArray = [];
      let typeArray = [];
      let ownerList = [];

      checkedValues.map((it) => {
        if (it.gender) genderArray = [...genderArray, it.gender];
        if (it.age) ageArray = [...ageArray, it.age];
        if (it.type) typeArray = [...typeArray, it.id];
        if (it.owner) ownerList = [...ownerList, it.owner];

        return true;
      });

      const len = allRecords?.length;

      for (let i = 0; i < len; i++) {
        const category = allRecords[i];
        const filterByGenderList =
          genderArray.length > 0
            ? genderArray.some((el) => category.gender === el)
            : category;

        const filterByAgeList =
          ageArray.length > 0
            ? ageArray.some((el) =>
                category.age_to
                  ? +category.age_from >= el[0] && +category.age_to <= el[1]
                  : +category.age_from >= el[0] && +category.age_from <= el[1]
              )
            : category;

        const filterByTypeList =
          typeArray.length > 0
            ? typeArray.some((el) => +category.type === +el)
            : category;

        const filterByOwnerList =
          ownerList.length > 0
            ? ownerList.some(
                (el) =>
                  // master_id === 1 (is Admin)
                  (+el === 0 &&
                    (+category.master_id === 1 || !category.master_id)) ||
                  (+el === 1 &&
                    +category.master_id === +tournamentData?.organizer_id &&
                    +category.master_id !== 1)
              )
            : category;

        if (
          filterByGenderList &&
          filterByAgeList &&
          filterByTypeList &&
          filterByOwnerList
        ) {
          newData = [...newData, category];
        }
      }
    } else {
      newData = [...allRecords];
    }

    const findSearchedVal = checkedValues?.find((it) => it.isSearchValue)?.name;
    newData = this.onTxtSearch(findSearchedVal ?? '', newData);

    action && action(newData);
  };

  hideSnackBar = () => this.setState({ showModal: false });

  onExpandRow = (evt, panelIdx, category) => {
    evt.preventDefault();

    const { filteredRecordsByPanel, collapsed } = this.state;
    let copyCollapsed = { ...collapsed };
    const panel = `panel_${panelIdx}`;
    const findCategory = filteredRecordsByPanel[panel].find(
      (it) => +it.id === +category.id
    );

    // Check if the panel already exists in collapsed state, if not, initialize it
    if (!copyCollapsed[panel]) {
      copyCollapsed[panel] = [];
    }

    // Find if the category is already expanded/collapsed
    const existingCategoryIndex = copyCollapsed[panel].findIndex(
      (item) => item.id === findCategory.id
    );

    // If not found, add the category to the array
    if (existingCategoryIndex === -1) {
      copyCollapsed[panel].push({
        id: findCategory.id,
        participants: findCategory.participants,
        sortDirection: []
      });
    } else {
      // If already present, remove the category from the array
      copyCollapsed[panel].splice(existingCategoryIndex, 1);
    }

    this.setState({
      collapsed: copyCollapsed
    });
  };

  onSortCollapsibleTable = (sortField, idxCell, parent, panel) => {
    const { collapsed } = this.state;
    const panelData = collapsed[panel];

    if (!panelData) return;

    // Find the index of the parent in the participants array
    const findIdxInArray = panelData.findIndex((it) => it.id === parent?.id);

    if (findIdxInArray === -1) return;

    const direction = findSortDirection(
      panelData[findIdxInArray].sortDirection,
      sortField
    );

    // Sort the participants based on the sort field and direction
    const sortedData = compareValueForSorting(
      panelData[findIdxInArray].participants,
      sortField,
      direction
    );

    panelData[findIdxInArray].sortDirection = [direction, idxCell, sortField];
    panelData[findIdxInArray].filteredParticipants = sortedData;

    this.setState({
      collapsed: {
        ...collapsed,
        [panel]: panelData
      }
    });
  };

  onRadioBtnSelection = (evt, category) => {
    const newCategory = evt.target.value;
    const copySelectedParticiantsCategory = {
      ...this.state.selectedParticiantsCategory
    };
    copySelectedParticiantsCategory.newCategory = newCategory;
    copySelectedParticiantsCategory.newCategoryType = category.type;

    this.setState({
      selectedParticiantsCategory: copySelectedParticiantsCategory,
      modalData: { ...category }
    });
  };

  onSelectPartCheckbox = (part, category) => {
    const copySelectedParticiantsCategory = {
      ...this.state.selectedParticiantsCategory
    };

    if (!copySelectedParticiantsCategory.oldData) {
      copySelectedParticiantsCategory.oldData = [
        {
          category_id: category.id,
          participants: [
            {
              participant_id: part.id,
              participant_name: part.participant_name,
              ...(part.team_id ? { team_id: `${part.team_id}` } : {})
            }
          ]
        }
      ];
    } else {
      const findCategoryIdx = copySelectedParticiantsCategory.oldData.findIndex(
        (it) => +it.category_id === +category.id
      );

      if (findCategoryIdx !== -1) {
        const findPartIdx = copySelectedParticiantsCategory.oldData[
          findCategoryIdx
        ].participants.findIndex((it) => +it.participant_id === +part.id);

        if (findPartIdx !== -1) {
          copySelectedParticiantsCategory.oldData[
            findCategoryIdx
          ].participants.splice(findPartIdx, 1);
        } else {
          copySelectedParticiantsCategory.oldData[
            findCategoryIdx
          ].participants = [
            ...copySelectedParticiantsCategory.oldData[findCategoryIdx]
              .participants,
            {
              participant_id: part.id,
              participant_name: part.participant_name,
              ...(part.team_id ? { team_id: `${part.team_id}` } : {})
            }
          ];
        }
      } else {
        copySelectedParticiantsCategory.oldData = [
          ...copySelectedParticiantsCategory.oldData,
          {
            category_id: category.id,
            participants: [
              {
                participant_id: part.id,
                participant_name: part.participant_name,
                ...(part.team_id ? { team_id: `${part.team_id}` } : {})
              }
            ]
          }
        ];
      }
    }

    this.setState({
      selectedParticiantsCategory: copySelectedParticiantsCategory
    });
  };

  onSelectAllPartCheckboxes = (category) => {
    const copySelectedParticiantsCategory = {
      ...this.state.selectedParticiantsCategory
    };
    const findCategoryIdx = copySelectedParticiantsCategory?.oldData?.findIndex(
      (it) => +it.category_id === +category.id
    );

    if (!copySelectedParticiantsCategory.oldData || findCategoryIdx === -1) {
      copySelectedParticiantsCategory.oldData = [
        ...(copySelectedParticiantsCategory.oldData ?? []),
        {
          category_id: category.id,
          participants: category?.participants?.map((part) => ({
            participant_id: part.id,
            participant_name: part.participant_name,
            ...(part.team_id ? { team_id: `${part.team_id}` } : {})
          }))
        }
      ];
    } else {
      copySelectedParticiantsCategory.oldData.splice(findCategoryIdx, 1);
    }

    this.setState({
      selectedParticiantsCategory: copySelectedParticiantsCategory
    });
  };

  onSaveDistribution = () => {
    const filterAgainAfterSavingChange = () => {
      this.fetchData(() => {
        Object.entries(this.state.activeFiltersPerPanel).map(([key, value]) => {
          this.onFilterBySelection(value, (newData) => {
            const allBlocksLeft =
              this.onFilterOutCategoriesWithZeroPart(newData);

            this.setState((prevState) => ({
              filteredRecordsByPanel: {
                ...prevState.filteredRecordsByPanel,
                [key]: +key?.slice(-1) === 0 ? allBlocksLeft : newData
              }
            }));
          });
        });

        this.setState({ collapsed: {} });
      });
    };

    const { selectedParticiantsCategory, tournamentData, actionBtnClicked } =
      this.state;
    const { t } = this.props;

    if (
      selectedParticiantsCategory.newCategory &&
      selectedParticiantsCategory.oldData
    ) {
      Promise.all(
        selectedParticiantsCategory.oldData.map((category) => {
          if (+selectedParticiantsCategory.newCategoryType !== 3) {
            const participantsIds = category.participants.map(
              (it) => it.participant_id
            );

            return new Promise((resolve) =>
              this.distributeParticipantByCategory(
                tournamentData.id,
                participantsIds,
                selectedParticiantsCategory.newCategory,
                selectedParticiantsCategory.newCategoryType,
                actionBtnClicked !== TEST_ID_COPY && category?.category_id,
                (result) =>
                  resolve({
                    result,
                    currentVal: category.participants
                      .map((currentName) => currentName.participant_name)
                      .join(', ')
                  }),
                true
              )
            );
          } else {
            const partLen = category.participants.length;

            const onDistributeIndividualParticipant = () => {
              let distributedIndividualParticipant = [];

              for (let j = 0; j < partLen; j++) {
                distributedIndividualParticipant = [
                  ...distributedIndividualParticipant,
                  new Promise((resolve) =>
                    this.distributeParticipantByCategory(
                      tournamentData.id,
                      category.participants[j],
                      selectedParticiantsCategory.newCategory,
                      selectedParticiantsCategory.newCategoryType,
                      actionBtnClicked !== TEST_ID_COPY &&
                        category?.category_id,
                      (result) =>
                        resolve({
                          result,
                          currentVal: category.participants[j]?.participant_name
                        }),
                      true
                    )
                  )
                ];
              }

              return distributedIndividualParticipant;
            };

            return new Promise((resolve) =>
              Promise.all([...onDistributeIndividualParticipant()]).then(
                (res) => {
                  resolve(res);
                }
              )
            );
          }
        })
      )
        .then((responses) => {
          const flattenResponses = [].concat(...responses);
          const failed2Distribute = flattenResponses?.filter(
            (it) => +it?.result?.code === 500
          );

          if (failed2Distribute?.length > 0) {
            this.changeModal(
              [
                t('successfullyUpdated'),
                t('failed2Distribute', {
                  name: failed2Distribute
                    ?.map((it) => it?.currentVal)
                    .join(', ')
                })
              ].join(' '),
              400
            );
          } else {
            this.changeModal(t('successfullyUpdated'), 200);
          }

          this.setState({ isAttemptingToEditModalFields: false }, () => {
            this.hideModal();
            this.setState({
              selectedParticiantsCategory: {}
            });
          });
          filterAgainAfterSavingChange();
        })
        .catch((err) => console.error(err, 'err'));
    } else {
      this.changeModal(t('distributeParticipantsByCategoriesError'), 400);
    }
  };

  onPanelSelection = (panelIdx) => {
    const { activeFiltersPerPanel, searchBar, selectedCheckboxes } = this.state;
    const item = `panel_${panelIdx}`;
    const copyActiveFiltersPerPanel = { ...activeFiltersPerPanel };

    if (copyActiveFiltersPerPanel[item]) {
      delete copyActiveFiltersPerPanel[item];
    } else {
      copyActiveFiltersPerPanel[item] = [
        ...(searchBar ? [{ name: searchBar, isSearchValue: true }] : []),
        ...selectedCheckboxes
      ];
    }

    this.setState(
      {
        activeFiltersPerPanel: copyActiveFiltersPerPanel
      },
      () =>
        this.onFilterBySelection(
          this.state.activeFiltersPerPanel[item],
          (newData) => {
            const allBlocksLeft =
              this.onFilterOutCategoriesWithZeroPart(newData);

            this.setState((prevState) => ({
              filteredRecordsByPanel: {
                ...prevState.filteredRecordsByPanel,
                [item]: panelIdx === 0 ? allBlocksLeft : newData
              }
            }));
          }
        )
    );
  };

  showModal = () => {
    this.setState({ open: true });
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      const { selectedParticiantsCategory } = this.state;
      let copySelectedParticiantsCategory = { ...selectedParticiantsCategory };

      if (+copySelectedParticiantsCategory?.newCategoryType === 3) {
        //If the team_id field is filled in the modal, and the changes are discarded, the modal should reopen with the team_id field empty
        copySelectedParticiantsCategory.oldData =
          copySelectedParticiantsCategory?.oldData?.map((it) => ({
            ...it,
            participants: it?.participants?.map((val) => ({
              participant_id: val.participant_id,
              participant_name: val.participant_name
            }))
          }));
      }

      this.setState({
        open: false,
        shouldShowDiscardChanges: false,
        selectedParticiantsCategory: copySelectedParticiantsCategory,
        isEditing: false
      });
    }
  };

  discardOrKeepEditing = (evt, targetID) => {
    if (targetID === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (targetID === CLOSE_DISCARD) {
      // clear the fields to be able to close the modal window
      this.setState({ isAttemptingToEditModalFields: false }, () => {
        this.hideModal();
      });
    }
  };

  onSelectTeamNum = (_, value, itemIdx, subitemIdx, error) => {
    const { selectedParticiantsCategory } = this.state;
    const copySelectedParticiantsCategory = { ...selectedParticiantsCategory };

    if (value !== null) {
      copySelectedParticiantsCategory.oldData[itemIdx].participants[
        subitemIdx
      ] = {
        ...copySelectedParticiantsCategory.oldData[itemIdx].participants[
          subitemIdx
        ],
        team_id: value.team_id
      };

      this.setState({
        selectedParticiantsCategory: copySelectedParticiantsCategory,
        isEditing: true
      });
    }
  };

  onActionBtnClick = (evt) => {
    const { selectedParticiantsCategory } = this.state;

    if (
      selectedParticiantsCategory.newCategory &&
      selectedParticiantsCategory.oldData
    ) {
      const actionBtnClicked = evt.currentTarget.id;

      this.setState({ actionBtnClicked }, () => {
        if (`${selectedParticiantsCategory.newCategoryType}` === `3`) {
          this.showModal();
        } else {
          this.onSaveDistribution();
        }
      });
    }
  };

  onToggleFilter = () => {
    this.setState((prevState) => ({ isFilterOpen: !prevState.isFilterOpen }));
  };

  render() {
    const {
      loading,
      success,
      showModal,
      modalInfo,
      selectedCheckboxes,
      filteredRecordsByPanel,
      categoryTypes,
      searchBar,
      selectedParticiantsCategory,
      activeFiltersPerPanel,
      collapsed,
      modalData,
      tournamentData,
      shouldShowDiscardChanges,
      open,
      isFilterOpen
    } = this.state;
    const { t, classes, shouldDisableEditing, viewportWidth } = this.props;

    const INTERACTIVE_HEADER = [
      {
        label: (
          <>
            <span style={{ flexGrow: 1 }}>{t('filter')}</span>
            {isFilterOpen ? (
              <ArrowDropUpIcon style={{ color: '#fff' }} />
            ) : (
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            )}
          </>
        ),
        onClick: () => this.onToggleFilter(),
        className: isFilterOpen && classes.activeState,
        gridArea: 'filter'
      },
      {
        label: t('transfer'),
        id: 'TEST_ID_TRANSFER',
        onClick: this.onActionBtnClick,
        gridArea: 'transfer',
        disabled: shouldDisableEditing
      },
      {
        label: t('copy'),
        id: TEST_ID_COPY,
        onClick: this.onActionBtnClick,
        gridArea: 'copy',
        disabled: shouldDisableEditing
      },
      {
        search: true,
        isInput: true,
        gridArea: 'search'
      }
    ];

    return (
      <>
        <SideModal
          closeModal={this.hideSnackBar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <Modal
              {...{ open }}
              close={this.hideModal}
              onClick={this.onSaveDistribution}
              dialogTitle={[t('select'), t('teams')].join(' ')}
              buttonPurpose={t('save')}
              subHeader={
                <span className={clsx(classes.flex, classes.column)}>
                  {modalData && ['->', modalData?.name].join(' ')}
                </span>
              }
              discardOrKeepEditing={this.discardOrKeepEditing}
              {...{ shouldShowDiscardChanges }}>
              {selectedParticiantsCategory.oldData?.map((item, itemIdx) => (
                <Fragment key={item.category_id}>
                  {item.participants?.map((subitem, subitemIdx) => (
                    <span
                      className={clsx(
                        classes.flex,
                        classes.centerVertically,
                        classes.wrapperSelectTeamNum
                      )}
                      key={subitem.participant_id}>
                      <Filter
                        className={classes.label}
                        classes={{
                          inputRoot: classes.outlined
                        }}
                        value={
                          selectedValue(
                            ALL_TEAMS,
                            'team_id',
                            subitem.team_id,
                            true
                          ) || ''
                        }
                        options={ALL_TEAMS}
                        label={t('team')}
                        onChange={(evt, val) => {
                          this.onSelectTeamNum(evt, val, itemIdx, subitemIdx);
                        }}
                        item={'team_id'}
                        variant="outlined"
                      />
                      {subitem.participant_name}
                    </span>
                  ))}
                </Fragment>
              ))}
            </Modal>
            <div
              className={clsx(
                !isFilterOpen && classes.marginBottom1,
                classes.interactiveHeaderWrapper
              )}>
              {INTERACTIVE_HEADER.map((el, index) => (
                <Fragment key={index}>
                  {!el.isInput ? (
                    <Button
                      onClick={el.onClick}
                      id={el.id}
                      label={el.label}
                      isSaveBtn
                      className={el.className}
                      disabled={el.disabled}
                      style={{ gridArea: el.gridArea }}
                    />
                  ) : (
                    <SearchInput
                      className={clsx(classes.margin0, classes.search)}
                      onChange={this.onSearch}
                      clearSearch={this.onClearSearch}
                      value={searchBar}
                      isSearchInactive={!searchBar}
                      style={{ gridArea: el.gridArea }}
                    />
                  )}
                </Fragment>
              ))}
            </div>
            <span
              className={clsx(
                classes.flex,
                classes.wrap,
                !isSmallScreen(viewportWidth) && classes.centerHorizontally
              )}>
              <FilterCheckboxes
                {...{
                  categoryTypes,
                  selectedCheckboxes,
                  isFilterOpen
                }}
                filterDetails={{
                  ownerCategories: true,
                  tounamentPresence: false,
                  noUnisex: false
                }}
                onSelectCheckbox={this.onSelectCheckbox}
                withoutFilterList
              />
            </span>
            <DistributeParticipantsByCategoriesComponent
              filteredData={filteredRecordsByPanel}
              {...{ tournamentData, collapsed }}
              onExpandRow={this.onExpandRow}
              {...{ shouldDisableEditing }}
              onRadioBtnSelection={this.onRadioBtnSelection}
              onSelectPartCheckbox={this.onSelectPartCheckbox}
              onSelectAllPartCheckboxes={this.onSelectAllPartCheckboxes}
              onPanelSelection={this.onPanelSelection}
              onSortCollapsibleTable={this.onSortCollapsibleTable}
              {...{ selectedParticiantsCategory }}
              {...{ activeFiltersPerPanel }}
              {...{ viewportWidth }}
            />
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(
  withStyles(styles)(DistributeParticipantsByCategories)
);
