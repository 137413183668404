import clsx from 'clsx';

import { Collapse } from '@material-ui/core';

import { ReactComponent as Dropdown } from 'assets/img/dropdown_grey&greybg.svg';

import { serverDefaultPath, NEW_DEFAULT_AVATAR } from 'helpers/constants';

import {
  useStyles,
  useStylesRecords,
  useStylesRecordsCollapsed
} from './StylesCardsList';

const List = (props) => {
  const {
    data,
    id,
    subTableDataKey,
    recordDataParams,
    collapsedRecordDataParams,
    viewportWidth,
    collapsed,
    onColumnClick,
    actionIcons,
    subActionsIcons,
    sortDirection,
    onSort,
    onSortCollapsible,
    specificDetails
  } = props;

  const classes = useStyles();

  const CardMainPart = ({ currentRecord, item, idx, icons, parent }) => {
    return (
      <span
        className={clsx(
          classes.flex,
          classes.cardMainPartWrapper,
          currentRecord?.main?.style?.cardMainPart
        )}>
        <span
          className={clsx(
            classes.flex,
            classes.centerVertically,
            currentRecord?.main?.hasPhoto && classes.heightFitContent
          )}>
          {currentRecord?.main?.rowOrder?.value && (
            <span
              className={clsx(
                classes.flex,
                classes.centerVertically,
                classes.fullHeight,
                classes.rowOrder,
                currentRecord?.main?.rowOrder?.class
              )}>
              {currentRecord?.main?.rowOrder?.value}
            </span>
          )}
          {currentRecord?.main?.hasPhoto &&
            (!currentRecord?.main?.defaultImg ? (
              <img
                className={clsx(
                  classes.thumbnail,
                  currentRecord?.class?.thumbnail
                )}
                alt="list_avatar"
                src={
                  currentRecord?.main?.photo
                    ? serverDefaultPath + currentRecord?.main?.photo
                    : NEW_DEFAULT_AVATAR
                }
              />
            ) : (
              currentRecord?.main?.defaultImg?.ic
            ))}
        </span>
        <span
          className={clsx(
            classes.flex,
            classes.column,
            !isMobile && classes.marginLeft0dot75,
            !currentRecord?.main?.showLabelName && classes.nameWithoutAvatar,
            currentRecord?.class?.nameWithoutAvatar
          )}>
          {currentRecord?.main?.showLabelName ? (
            <span
              className={clsx(
                classes.flex,
                classes.column,
                classes.recordValuesInnerWrapper
              )}>
              {currentRecord?.main?.label && (
                <span
                  className={clsx(
                    classes.placeholderRecord,
                    classes.flex,
                    classes.centerVertically
                  )}>
                  {currentRecord?.main?.label}
                </span>
              )}
              <span
                className={clsx(
                  classes.valueRecord,
                  classes.gridGap10,
                  classes.wordBreak
                )}>
                {currentRecord?.main?.name}
              </span>
            </span>
          ) : (
            <h5
              className={clsx(
                classes.recordName,
                classes.ellipsis,
                classes.marginTop05,
                currentRecord?.main?.style?.recordName
              )}>
              {currentRecord?.main?.name}
            </h5>
          )}
          {icons && (
            <span className={clsx(classes.flex)}>
              {icons(
                item,
                idx,
                [
                  classes.flex,
                  classes.onHover,
                  classes.buttonsSize,
                  classes.borderBtn
                ],
                parent
              )}
            </span>
          )}
        </span>
      </span>
    );
  };

  const GeneralCard = ({
    icons,
    onCardClick,
    currentRecord,
    item,
    idx,
    parent,
    currentClasses
  }) => {
    return (
      <div
        key={idx}
        onClick={
          onCardClick ? (evt) => onCardClick(evt, item, idx, parent) : undefined
        }
        className={clsx(
          classes.gridRecords,
          classes.border,
          currentRecord?.class?.gridRecords
        )}>
        <CardMainPart {...{ icons, currentRecord, item, idx, parent }} />
        {currentRecord?.info && (
          <span
            className={clsx(
              classes.gridRecordsValue,
              classes.paddingLeft01,
              currentClasses,
              currentRecord?.main?.style?.cardValuePart,
              currentRecord?.selected && classes.selected
            )}>
            {currentRecord?.info?.map((el, elIdx) => {
              let recordValue = el.value ?? '-';

              return (
                <span
                  key={elIdx}
                  className={clsx(
                    classes.grid,
                    classes.recordValuesInnerWrapper,
                    el.list && classes.isCategoriesColumn
                  )}>
                  <span
                    className={clsx(
                      classes.flex,
                      classes.column,
                      el.className
                    )}>
                    {!currentRecord?.main.hideValueName && (
                      <span
                        onClick={() => el.sort && onSort(el.sort, el.sort)}
                        className={clsx(
                          classes.placeholderRecord,
                          classes.flex,
                          classes.centerVertically,
                          currentRecord?.main?.style?.cardValuePart
                        )}>
                        {el?.name}
                      </span>
                    )}
                    <span
                      onClick={(e) => {
                        if (onColumnClick) {
                          e.stopPropagation();

                          onColumnClick(e, item, el.key);
                        }
                      }}
                      className={clsx(
                        classes.valueRecord,
                        classes.gridGap10,
                        classes.wordBreak // MAYBE IT"S BETTER TO BREAK THE WORD THEN TO ADD ELLIPSIS
                      )}>
                      {recordValue}
                    </span>
                  </span>
                </span>
              );
            })}
          </span>
        )}
      </div>
    );
  };

  const Card = ({ item, idx }) => {
    const currentRecord =
      recordDataParams && recordDataParams(item, idx, specificDetails?.parent);
    const findRowInArray = collapsed?.find((it) => +it?.id === +item?.[id]);
    const inCollapsed = findRowInArray?.id;
    const subTableData = findRowInArray?.[subTableDataKey];

    return (
      <>
        <GeneralCard
          currentClasses={clsx(
            specificDetails?.className?.gridRecordsValue
              ? specificDetails?.className?.gridRecordsValue
              : classes2.gridRecordsValue,
            currentRecord?.class?.gridRecordsValue
          )}
          icons={actionIcons}
          {...{ currentRecord, item, idx }}
        />
        <Collapse
          className={clsx(
            classes.collpasewrapperInner,
            currentRecord?.class?.collapse
          )}
          in={Boolean(inCollapsed)}
          timeout="auto"
          unmountOnExit>
          {subTableData && subTableData.length > 0 && (
            <>
              <SortComponent
                currentData={collapsedRecordDataParams({}, null, item)}
                currentClasses={clsx(
                  classes3.gridRecordsValue,
                  currentRecord?.class?.sortCollapseLabels
                )}
                onClick={onSortCollapsible}
                parent={item}
                sortValues={findRowInArray?.sortDirection}
              />
              {subTableData.map((subItem, subIdx) => {
                const subRecordParams =
                  collapsedRecordDataParams &&
                  collapsedRecordDataParams(subItem, subIdx, item, [
                    classes.flex,
                    classes.onHover,
                    classes.buttonsSize
                  ]);

                return (
                  <GeneralCard
                    currentClasses={clsx(
                      classes3.gridRecordsValue,
                      subRecordParams?.class?.gridRecordsValueCollapse
                    )}
                    key={`${idx}_${subIdx}`}
                    icons={subActionsIcons}
                    currentRecord={subRecordParams}
                    parent={item}
                    item={subItem}
                    idx={subIdx}
                  />
                );
              })}
            </>
          )}
        </Collapse>
      </>
    );
  };

  const SortComponent = ({
    onClick,
    currentData,
    currentClasses,
    parent,
    sortValues
  }) => {
    return (
      <>
        {currentData?.main?.hasSort && (
          <div
            className={clsx(
              classes.gridRecords,
              classes.hideElem,
              currentData?.class?.gridRecords
            )}>
            {currentData?.main?.label && (
              <span className={clsx(classes.flex, classes.cardMainPartWrapper)}>
                <span
                  className={clsx(
                    classes.flex,
                    classes.column,
                    classes.nameWithoutAvatar
                  )}>
                  <span
                    onClick={() =>
                      currentData?.main?.sort &&
                      onClick(
                        currentData?.main?.sort,
                        currentData?.main?.sort,
                        parent
                      )
                    }
                    className={clsx(
                      classes.flex,
                      classes.placeholderRecord,
                      classes.centerVertically,
                      classes.onHover,
                      sortValues[1] === currentData?.main?.sort &&
                        classes.activeSort
                    )}>
                    {currentData?.main?.label}
                    <Dropdown
                      className={clsx(
                        sortValues[1] === currentData?.main?.sort &&
                          sortValues[0] === 1 &&
                          classes.rotate180
                      )}
                    />
                  </span>
                </span>
              </span>
            )}
            {currentData?.info && (
              <span
                className={clsx(
                  classes.grid,
                  classes.padding0dot05,
                  classes.paddingLeft01,
                  currentClasses
                )}>
                {currentData?.info?.map((el, elIdx) => {
                  return (
                    el?.name && (
                      <span
                        key={elIdx}
                        className={clsx(
                          classes.grid,
                          classes.recordValuesInnerWrapper
                        )}>
                        <span className={clsx(classes.flex, classes.column)}>
                          <span
                            onClick={() =>
                              el.sort && onClick(el.sort, el.sort, parent)
                            }
                            className={clsx(
                              classes.flex,
                              classes.placeholderRecord,
                              classes.centerVertically,
                              classes.onHover,
                              el.sort &&
                                sortValues[1] === el.sort &&
                                classes.activeSort
                            )}>
                            {el?.name}
                            {el.sort && (
                              <Dropdown
                                className={clsx(
                                  sortValues[1] === el.sort &&
                                    sortValues[0] === 1 &&
                                    classes.rotate180
                                )}
                              />
                            )}
                          </span>
                        </span>
                      </span>
                    )
                  );
                })}
              </span>
            )}
          </div>
        )}
      </>
    );
  };

  const paramsDetails = recordDataParams({});
  const classes2 = useStylesRecords(paramsDetails?.info ?? []);
  const classes3 = useStylesRecordsCollapsed(
    (collapsedRecordDataParams && collapsedRecordDataParams({})?.info) ?? []
  );

  const isMobile = viewportWidth < 768;

  return (
    <div className={paramsDetails?.main?.style?.wrapper}>
      {data?.length > 0 && (
        <SortComponent
          currentData={paramsDetails}
          currentClasses={clsx(
            specificDetails?.className?.gridRecordsValue
              ? specificDetails?.className?.gridRecordsValue
              : classes2.gridRecordsValue
          )}
          onClick={onSort}
          sortValues={sortDirection}
        />
      )}
      <div className={clsx(classes.gridGap10)}>
        {data?.map((item, idx) => {
          return <Card key={idx} {...{ item, idx }} />;
        })}
      </div>
    </div>
  );
};

export default List;
