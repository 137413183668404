const styles = () => ({
  flex: {
    display: 'flex'
  },

  centerVertically: {
    alignItems: 'center',
    padding: '0 12px'
  },

  centerVertical: {
    alignItems: 'center'
  },

  time: {
    border: '1px solid #001D3D',
    borderRadius: '0.2rem',
    padding: '0 0.2rem'
  },

  timeWrapper: {
    position: 'relative',
    width: '7em',
    display: 'flex',
    height: '2em',
    margin: '0.5rem',
    alignItems: 'center'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  marginBottom05: {
    marginBottom: '0.5rem'
  },

  modalWidth: {
    '@media (min-width:1024px)': {
      minWidth: '40em'
    }
  },

  marginRight0dot5: {
    marginRight: '0.5rem'
  },

  noEvent: {
    pointerEvents: 'none'
  },

  disabled: {
    opacity: '0.6'
  },

  kataFinalRoundInput: {
    width: '7em'
  },

  marginRight0: {
    marginRight: 0
  },

  timeInputWrapper: {
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-15px'
    }
  },

  centerVertically: {
    alignItems: 'center'
  },

  popperContentWrapper: {
    background: '#fff',
    display: 'grid',
    margin: '0.5rem 1rem',
    border: '1px solid #A2ABBA',
    borderRadius: '0.3rem',
    paddingBottom: '1rem'
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  addnewRremoveBtn: {
    width: '16em !important',
    display: 'flex',
    textTransform: 'none !important'
  },

  marginLeft08: {
    marginLeft: '0.8rem'
  }
});

export default styles;
