import { apiUrl, LOCALE } from './constants';
import { parse } from './content-disp';

export const apiPostRequest = (endPoint, reqBody, cb, token, uniqueApi) => {
  const api = uniqueApi ? uniqueApi : apiUrl;
  let contentType;

  if (reqBody instanceof FormData) {
    contentType;
  } else {
    contentType = 'application/json';
  }

  fetch(api + endPoint, {
    method: 'POST',
    headers: {
      'API-TOKEN': token ? token : localStorage.getItem('token'),
      'Accept-Language': LOCALE(localStorage.getItem('i18nextLng')),
      ...(contentType ? { 'Content-Type': contentType } : {})
    },
    body: reqBody
  })
    .then((res) => {
      handleUnauthorized(res);

      if (!res.headers.get('content-type').match('application/json')) {
        res.blob().then((msg) => {
          const contentDisposition = res.headers.get('content-disposition');
          const fileDetails = contentDisposition && parse(contentDisposition);

          cb(msg, res.status, fileDetails?.filename ?? '');
        });
      } else res.json().then((msg) => cb(msg, res.status));
    })
    .catch((err) => cb('error'));
};

export const noTokenPostRequest = (endPoint, reqBody, cb) => {
  fetch(apiUrl + endPoint, {
    method: 'POST',
    body: reqBody,
    headers: {
      'Accept-Language': LOCALE(localStorage.getItem('i18nextLng'))
    }
  })
    .then((res) => res.json().then((body) => cb(body, res.status)))
    .catch((err) => cb('error'));
};

export const apiGetRequest = (
  endPoint,
  params,
  cb,
  token,
  uniqueApi,
  shouldSetContentDispositionFileName
) => {
  const searchParams = new URLSearchParams();

  for (let key in params) searchParams.append(key, params[key]);
  const api = uniqueApi ? uniqueApi : apiUrl;

  fetch(api + endPoint + '?' + searchParams.toString(), {
    headers: {
      'API-TOKEN': token ? token : localStorage.getItem('token'),
      'Accept-Language': LOCALE(localStorage.getItem('i18nextLng'))
    }
  })
    .then((res) => {
      handleUnauthorized(res);

      if (!res.headers.get('content-type').match('application/json')) {
        res.blob().then((msg) => {
          const contentDisposition = res.headers.get('content-disposition');
          const fileDetails = contentDisposition && parse(contentDisposition);

          cb(msg, res.status, fileDetails?.filename ?? '');
        });
      } else if (shouldSetContentDispositionFileName) {
        res.json().then((msg) => {
          const contentDisposition = res.headers.get('content-disposition');
          const fileDetails = contentDisposition && parse(contentDisposition);

          cb(msg, res.status, fileDetails?.filename ?? '');
        });
      } else {
        res.json().then((msg) => cb(msg, res.status));
      }
    })
    .catch(() => cb('error'));
};

export const noTokenApiGetRequest = (endPoint, params, cb) => {
  const searchParams = new URLSearchParams();

  for (let key in params) searchParams.append(key, params[key]);

  fetch(apiUrl + endPoint + '?' + searchParams.toString(), {
    headers: {
      'Accept-Language': LOCALE(localStorage.getItem('i18nextLng'))
    }
  })
    .then((res) => res.json().then((msg) => cb(msg, res.status)))
    .catch((err) => cb('error'));
};

export const imageExistance = (imgPath) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imgPath;
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error('Image not found'));
  });
};

export const changeTitle = (titleName) => {
  document.title = titleName;
};

const handleUnauthorized = (res) => {
  const lang = localStorage.getItem('i18nextLng');
  const token = localStorage.getItem('token');

  if (res.status === 401 && token) {
    localStorage.clear();
    localStorage.setItem('i18nextLng', lang);
    localStorage.setItem('logout-event', `logout_${Math.random()}`);
    window.location = '/sign-in';

    return;
  }
};
