import { Component, Fragment } from 'react';
import clsx from 'clsx';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import { Tooltip, Switch, FormControlLabel } from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';

import { ReactComponent as FormatListNumberedIcon } from 'assets/img/list-white.svg';
import { ReactComponent as ScheduleIcon } from 'assets/img/schedule-white.svg';
import { ReactComponent as SportsKabaddiIcon } from 'assets/img/skirmishes-white.svg';
import { ReactComponent as PersonIcon } from 'assets/img/members_num_white.svg';
import { ReactComponent as ArrowIcon } from 'assets/img/back_triangle_white_bg&grey_border.svg';
import { ReactComponent as GridView } from 'assets/img/scheme_view_referees.svg';

import {
  ADD_ICON,
  CLEAR_ICON,
  ALL_TEAMS,
  TEST_ID_DOWNLOAD_DOC,
  KEEP_EDITING,
  TEST_ID_FORM,
  CLOSE_DISCARD
} from '../../helpers/constants';
import {
  changeModal,
  fetchTournaments,
  fetchTournRegistrar,
  changeHead,
  fetchStaff,
  addNewStaff,
  updateStaff,
  textChangeHandler,
  fetchReference,
  exportReportGeneral,
  fetchQdan,
  changeImage,
  downloadUplaodedFile,
  downloadFile,
  selectOption,
  fetchCountries,
  validateForm,
  fetchReferenceRegion,
  fetchTatmisCategories,
  delStaffTeam,
  addStaffTeam,
  addStaffTatami,
  delStaffTatami,
  pageChangeHandler,
  rowsPerPageHandler,
  changeDate,
  getTatamisTheme
} from '../../helpers/util';
import {
  finishedTournament,
  selectedValue,
  convertMs,
  findSortDirection,
  compareValueForSorting
} from '../../helpers/selectors';

import { AuthContext } from 'AuthContext';
import { SearchInput } from 'components';

import SideModal from '../../components/Snackbar/SideModal';
import Modal from 'components/Modal/ModalNewDesign';
import TableList from './TableList';
import LoadingState from '../../components/LoadingState/LoadingState';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import EmptyState from '../../components/EmptyState/EmptyState';
import StaffForm from '../../components/StaffForm/StaffForm';
import Filter from '../../components/Filter/Filter';
import Button from '../../components/Buttons/ActionButtons';
import Pagination from '../../components/TablePagination/TablePagination';

import { styles, getTatamiTheme } from './Styles';

const defaultForm = {
  first_name: '',
  last_name: '',
  gender: '',
  registrar_id: '',
  tournament_id: '',
  birthday: ''
};

const TOP_JUDGES = [25, 26, 27, 28];
const TATAMI_JUDGES = [33, 31, 4251, 32];
const TEAM_JUDGES = [30, 29, 34];

const MIDDLE_JUDGES_COLOR = '#F58A46';
const TOP_JUDGES_COLOR = '#FF483D';
const DEFAULT_JUDGES_COLOR = '#D0D0D0';
const SIDE_JUDGES_COLOR = '#1C48B9';
const REFEREE_JUDGES_COLOR = '#F5BC4A';
const PARTISIPANTS_JUDGES_COLOR = '#00BB92';

class Staff extends Component {
  state = {
    tournamentId: this.props.match.params.id,
    showModal: false,
    isSchemeView: false,
    isSwitchSet: false,
    schemeData: {},
    gridViewSelectedRecord: {},
    filteredRecordsByScheme: {},
    modalInfo: '',
    success: false,
    isLoading: true,
    open: false,
    tournamentData: {},
    allRecords: [],
    modalData: {},
    errors: {},
    allPositions: [],
    countries: [],
    qdanList: [],
    all_qual_iko: [],
    all_qual_rf: [],
    tourTatamis: [],
    tatamisTheme: [],
    allRegistrar: [],
    sortDirection: [],
    modalSelectedVal: {},
    rowDataTeamsTatamis: {},
    rowDataTeamsTatamisErrors: {},
    filters: {},
    recordsCount: 0,
    langOnLoad: localStorage.getItem('i18nextLng'),
    rowsPerPage:
      (localStorage.getItem('rowsPerPage') &&
        +localStorage.getItem('rowsPerPage')) ??
      25,
    page: 1
  };

  changeModal = changeModal.bind(this);
  fetchTournaments = fetchTournaments.bind(this);
  fetchTournRegistrar = fetchTournRegistrar.bind(this);
  changeHead = changeHead.bind(this);
  fetchStaff = fetchStaff.bind(this);
  addNewStaff = addNewStaff.bind(this);
  updateStaff = updateStaff.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  fetchReference = fetchReference.bind(this);
  exportReportGeneral = exportReportGeneral.bind(this);
  selectOption = selectOption.bind(this);
  fetchCountries = fetchCountries.bind(this);
  fetchQdan = fetchQdan.bind(this);
  changeImage = changeImage.bind(this);
  downloadUplaodedFile = downloadUplaodedFile.bind(this);
  downloadFile = downloadFile.bind(this);
  validateForm = validateForm.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);
  fetchTatmisCategories = fetchTatmisCategories.bind(this);
  delStaffTeam = delStaffTeam.bind(this);
  addStaffTeam = addStaffTeam.bind(this);
  addStaffTatami = addStaffTatami.bind(this);
  delStaffTatami = delStaffTatami.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  changeDate = changeDate.bind(this);
  getTatamisTheme = getTatamisTheme.bind(this);

  static contextType = AuthContext;

  componentDidMount() {
    const { tournamentId, langOnLoad } = this.state;
    const { t } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;
      const shouldDisableEditing =
        finishedTournament(tournamentData) || !!+tournamentData?.finished;

      this.fetchReference('judge_pos', (data) =>
        this.setState({ allPositions: data })
      );

      this.fetchReference('qual_iko', (data) =>
        this.setState({ all_qual_iko: data })
      );

      this.fetchReference('qual_rf', (data) =>
        this.setState({ all_qual_rf: data })
      );

      this.fetchCountries();
      this.fetchTatmisCategories(
        tournamentId,
        (data) => {
          const tourTatamis = [...data];

          this.setState(
            (prevState) => ({
              tourTatamis,
              schemeData: {
                ...prevState.schemeData,
                tatami_id: tourTatamis[0]?.tatami_id,
                tatami_name: tourTatamis[0]?.tatami_name,
                category_count_use: tourTatamis[0]?.category_count_use,
                skirmish_count: tourTatamis[0]?.skirmish_count,
                participant_count: tourTatamis[0]?.participant_count,
                total_time: tourTatamis[0]?.total_time,
                theme_id: tourTatamis[0]?.theme_id,
                ...ALL_TEAMS[0]
              }
            }),
            () => this.fetchData(() => this.onGroupRecordsByPositions(true))
          );
        },
        true,
        true
      );
      this.getTatamisTheme();
      this.fetchQdan();

      this.setState({ shouldDisableEditing });

      changeHead(tournamentData, t('judges'));
    });

    // this.fetchReference(
    //   4113,
    //   (data) => {
    //     const filteredData = data.filter((item) => item.name === 'staff');
    //     this.setState({ iframeURL: filteredData[0] });
    //   },
    //   null,
    //   langOnLoad
    // );
  }

  componentDidUpdate(prevProps, prevState) {
    const { rowDataTeamsTatamis, tournamentData, isEditing, langOnLoad } =
      this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      isEditing &&
      rowDataTeamsTatamis &&
      rowDataTeamsTatamis !== prevState.rowDataTeamsTatamis
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }

    if (langOnLoad !== currentLang) {
      this.fetchReference('judge_pos', (data) =>
        this.setState({ allPositions: data })
      );

      this.fetchReference('qual_iko', (data) =>
        this.setState({ all_qual_iko: data })
      );

      this.fetchReference('qual_rf', (data) =>
        this.setState({ all_qual_rf: data })
      );

      this.fetchCountries();
      this.fetchData();

      changeHead(tournamentData, t('judges'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  fetchData = (cb, shouldShowSnackbar) => {
    const { tournamentId, all_qual_rf } = this.state;

    this.fetchStaff(
      tournamentId,
      null,
      (data) => {
        const len = data?.length;
        let filterDataByActiveStatus = [];

        for (let i = 0; i < len; i++) {
          const item = data[i];

          if (!!+item?.status) {
            const findQualRf = all_qual_rf.find(
              (it) => +it?.id === +item?.qualificationRfRid
            );

            filterDataByActiveStatus = [
              ...filterDataByActiveStatus,
              { ...item, qualificationRfOrder: findQualRf?.order }
            ];
          }
        }

        const filterDataByActiveStatusLen = filterDataByActiveStatus?.length;

        this.setState(
          {
            allRecords: filterDataByActiveStatus,
            filteredRecords: filterDataByActiveStatus,
            isLoading: false,
            recordsCount: filterDataByActiveStatusLen
          },
          () => {
            cb && cb();
          }
        );
      },
      shouldShowSnackbar
    );
  };

  closeModalHandler = () => this.setState({ showModal: false });

  onFilterRecords = () => {
    const { allRecords, filters, isSchemeView, isSwitchSet, schemeData } =
      this.state;
    const len = allRecords?.length;
    let newFilteredRecords = [];

    for (let i = 0; i < len; i++) {
      const el = allRecords[i];
      const bySearch = filters?.searchBar
        ? [el.firstName, el.lastName].some((val) =>
            val.toLowerCase().includes(filters?.searchBar.toLowerCase().trim())
          )
        : true;

      const byPosition = filters?.positionId
        ? +el?.positionId === +filters?.positionId
        : true;

      const bySchemeAssigned =
        isSchemeView && isSwitchSet
          ? el?.tatamis?.some(
              (it) => +it?.tatami_id === +schemeData?.tatami_id
            ) &&
            (el?.teams?.some((it) => +it?.team_n === +schemeData?.team_id) ||
              el?.teams.length === 0)
          : true;

      if (bySearch && byPosition && bySchemeAssigned) {
        newFilteredRecords = [...newFilteredRecords, el];
      }
    }

    this.setState({ filteredRecords: newFilteredRecords }, () => {
      const { filteredRecords } = this.state;
      const len = filteredRecords?.length;

      this.setState({ recordsCount: len });
    });
  };

  searchHandler = (event) => {
    const { value } = event.target;

    this.setState(
      (prevState) => ({
        page: 1,
        filters: { ...prevState.filters, searchBar: value }
      }),
      () => {
        this.onFilterRecords();
      }
    );
  };

  clearSearch = () => {
    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBar: '' } }),
      () => this.onFilterRecords()
    );
  };

  showModalForm = (evt, item, idx) => {
    evt.preventDefault();

    const { tournamentData } = this.state;
    const id = item?.tjId;
    let state = {
      selectedRow: id,
      open: true,
      pressedIcon: TEST_ID_FORM,
      errors: {}
    };

    if (id) {
      if (item?.countryId) {
        this.fetchReferenceRegion(item?.countryId);
      }

      state = {
        ...state,
        modalData: {
          ...item,
          first_name: item.firstName,
          last_name: item.lastName,
          country_id: item.countryId,
          last_name_national: item.lastNameInt,
          first_name_national: item.firstNameInt,
          qdan: item.qdanId,
          qual_iko: item.qualificationIkoRid,
          qual_rf: item.qualificationRfRid,
          tournament_id: item.tournamentId ?? tournamentData?.id,
          registrar_id: item.userId,
          days: item.days?.map((it) => ({
            id: `${it}`
          })),
          currentRecordIdx: idx
        }
      };
    } else {
      state = { ...state, modalData: { tournament_id: tournamentData?.id } };
    }

    this.fetchTournRegistrar(tournamentData?.id, 2, (data) => {
      this.setState({ allRegistrar: data, ...state }, () => {
        if (!id) {
          this.onAssignYourself();
        }
      });
    });
  };

  saveForm = (evt) => {
    const { tournamentData, sortDirection, errors } = this.state;
    const { t } = this.props;
    const regionVal = document.getElementById('region')?.value;
    const copyErrors = { ...errors };

    this.setState(
      (prevState) => ({
        modalData: {
          ...prevState.modalData,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.modalData;

        if (copyErrors?.age?.length > 0) {
          copyErrors.birthday = t('newerDate');

          this.setState({ errors: copyErrors });
        }

        this.validateForm(
          evt,
          {
            ...defaultForm,
            ...(!!+tournamentData?.show_reg_region ? { region: '' } : {}),
            ...(!!+tournamentData?.show_reg_city ? { city: '' } : {}),
            ...(!!+tournamentData?.show_reg_club ? { club: '' } : {}),
            ...(!+tournamentData?.no_country ? { country_id: '' } : {}),
            ...(+tournamentData?.type === 2
              ? { first_name_national: '', last_name_national: '' }
              : {})
          },
          form,
          'errors',
          () => {
            if (form?.id) {
              this.updateStaff(evt, form, () => {
                this.fetchData(() => {
                  this.onGroupRecordsByPositions(true);
                  this.hideModal();
                  if (sortDirection?.length > 0) {
                    this.onSort(sortDirection[2], null, true);
                  }
                });
              });
            } else {
              this.addNewStaff(evt, form, () => {
                this.fetchData(() => {
                  this.onGroupRecordsByPositions(true);
                  this.hideModal();

                  if (sortDirection?.length > 0) {
                    this.onSort(sortDirection[2], null, true);
                  }
                });
                this.setState({
                  modalData: {
                    tournament_id: form.tournament_id,
                    registrar_id: form.registrar_id,
                    ...(form?.club ? { club: form.club } : {}),
                    ...(form?.region ? { region: form.region } : {}),
                    country_id: form.country_id,
                    ...(form?.city ? { city: form.city } : {})
                  }
                });
              });
            }
          }
        );
      }
    );
  };

  showModal = (event, rowData, currentVal, currentTargetID) => {
    const iconID = currentTargetID ?? event.target.id;
    let cpyForm = {
      ...rowData,
      ...(rowData.days
        ? {
            days: rowData.days?.map((it) => ({
              id: `${it}`
            }))
          }
        : {})
    };

    this.setState(
      {
        modalData: { ...rowData }
      },
      () => {
        let rowDataTeamsTatamis = {};

        if (iconID === ADD_ICON) {
          rowDataTeamsTatamis.teams = rowData?.teams?.map((it) => ({
            team_id: `${it.team_n}`
          }));

          rowDataTeamsTatamis.tatamis = rowData?.tatamis?.map((it) => {
            return {
              tatami_id: `${it.tatami_id}`,
              tatami_name: it.tatami_name
            };
          });
          rowDataTeamsTatamis.positionId = rowData?.positionId;

          cpyForm = {
            ...cpyForm,
            first_name: rowData.firstName,
            last_name: rowData.lastName,
            country_id: rowData.countryId,
            last_name_national: rowData.lastNameInt,
            first_name_national: rowData.firstNameInt,
            qdan: rowData.qdanId,
            qual_iko: rowData.qualificationIkoRid,
            qual_rf: rowData.qualificationRfRid,
            tournament_id: rowData.tournamentId,
            registrar_id: rowData.userId
          };
        } else if (iconID === TEST_ID_DOWNLOAD_DOC) {
          this.downloadUplaodedFile(rowData?.documentId, null, (file) => {
            cpyForm = {
              ...cpyForm,
              preview: URL.createObjectURL(file)
            };
          });
        }

        this.setState({
          modalData: cpyForm,
          pressedIcon: iconID,
          rowDataTeamsTatamis,
          open: true,
          modalSelectedVal: currentVal
        });
      }
    );
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        pressedIcon: null,
        shouldShowDiscardChanges: false,
        rowDataTeamsTatamis: {},
        rowDataTeamsTatamisErrors: {},
        isEditing: false,
        selectedRow: null,
        allRegions: []
      });
    }
  };

  discardOrKeepEditing = (evt, targetID) => {
    if (targetID === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (targetID === CLOSE_DISCARD) {
      // clear the fields to be able to close the modal window
      this.setState({ isAttemptingToEditModalFields: false }, () => {
        this.hideModal();
      });
    }
  };

  fetchRegionBySelectedCountry = (key) => {
    const { modalData } = this.state;
    let cpy = { ...modalData };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ modalData: cpy });
  };

  onAssignYourself = () => {
    const currentUserID = localStorage.getItem('user_id');

    this.autoFillRegistrarData(currentUserID, (data) => {
      if (data) {
        this.fetchReferenceRegion(data.country_id);
      }
    });
  };

  autoFillRegistrarData = (key, cb) => {
    const { allRegistrar, errors } = this.state;
    const findRegistrar = allRegistrar?.find((it) => +it?.id === +key);
    const copyErrors = { ...errors };

    delete copyErrors?.registrar_id;
    findRegistrar?.club && delete copyErrors?.club;
    findRegistrar?.region && delete copyErrors?.region;
    delete copyErrors?.country_id;
    findRegistrar?.city && delete copyErrors?.city;

    cb && cb(findRegistrar);

    this.setState((prevState) => ({
      modalData: {
        ...prevState.modalData,
        registrar_id: key,
        ...(findRegistrar?.club ? { club: findRegistrar.club } : {}),
        ...(findRegistrar?.region ? { region: findRegistrar.region } : {}),
        country_id: findRegistrar?.country_id,
        ...(findRegistrar?.city ? { city: findRegistrar.city } : {})
      },
      errors: copyErrors
    }));
  };

  saveModalChanges = (e) => {
    const {
      modalSelectedVal,
      pressedIcon,
      rowDataTeamsTatamis,
      modalData,
      groupedRecordsByPosition,
      sortDirection
    } = this.state;

    if (pressedIcon === CLEAR_ICON) {
      if (modalSelectedVal?.tatami_id) {
        this.delStaffTatami(modalSelectedVal?.tt_id, () => {
          this.fetchData(() => {
            this.onGroupRecordsByPositions(true);

            if (sortDirection?.length > 0) {
              this.onSort(sortDirection[2], null, true);
            }

            this.setState({ isAttemptingToEditModalFields: false }, () =>
              this.hideModal()
            );
          });
        });
      } else {
        this.delStaffTeam(modalSelectedVal?.tt_id, () => {
          this.fetchData(() => {
            this.onGroupRecordsByPositions(true);

            if (sortDirection?.length > 0) {
              this.onSort(sortDirection[2], null, true);
            }

            this.setState({ isAttemptingToEditModalFields: false }, () =>
              this.hideModal()
            );
          });
        });
      }
    } else if (pressedIcon === ADD_ICON) {
      this.validateForm(
        e,
        {
          ...{ positionId: '' },
          ...(!TOP_JUDGES.includes(+rowDataTeamsTatamis?.positionId)
            ? { tatamis: '' }
            : {}),
          ...(TEAM_JUDGES.includes(+rowDataTeamsTatamis?.positionId)
            ? { teams: '' }
            : {})
        },
        rowDataTeamsTatamis,
        'rowDataTeamsTatamisErrors',
        () => {
          const d1 = rowDataTeamsTatamis?.teams?.map((it) => {
            new Promise((resolve) => {
              if (
                TEAM_JUDGES.includes(+rowDataTeamsTatamis?.positionId) &&
                !modalData?.teams?.some((val) => +val?.team_n === +it?.team_id)
              ) {
                this.addStaffTeam(modalData?.tjId, it.team_id, (val) =>
                  resolve(val)
                );
              }
            });
          });
          const d2 = modalData?.teams?.map((it) => {
            if (
              !rowDataTeamsTatamis?.teams?.some(
                (val) => +val?.team_id === +it?.team_n
              ) ||
              !TEAM_JUDGES.includes(+rowDataTeamsTatamis?.positionId)
            ) {
              new Promise((resolve) =>
                this.delStaffTeam(it?.tt_id, (val) => resolve(val))
              );
            }
          });
          const d3 = rowDataTeamsTatamis?.tatamis?.map((it) => {
            if (
              !TOP_JUDGES.includes(+rowDataTeamsTatamis?.positionId) &&
              !modalData?.tatamis?.some(
                (val) => +val?.tatami_id === +it?.tatami_id
              )
            ) {
              new Promise((resolve) =>
                this.addStaffTatami(modalData?.tjId, it.tatami_id, (val) =>
                  resolve(val)
                )
              );
            }
          });
          const d4 = modalData?.tatamis?.map((it) => {
            if (
              TOP_JUDGES.includes(+rowDataTeamsTatamis?.positionId) ||
              !rowDataTeamsTatamis?.tatamis?.some(
                (val) => +val?.tatami_id === +it?.tatami_id
              )
            ) {
              new Promise((resolve) =>
                this.delStaffTatami(it?.tt_id, (val) => resolve(val))
              );
            }
          });
          const d5 = new Promise((resolve) => {
            if (rowDataTeamsTatamis?.positionId) {
              if (!modalData?.showConfirmationModalTxt) {
                this.updateStaff(
                  e,
                  { ...modalData, positionId: rowDataTeamsTatamis?.positionId },
                  (val) => resolve(val)
                );
              } else {
                const allRecordsWithCurrentPosition =
                  groupedRecordsByPosition?.[rowDataTeamsTatamis?.positionId];
                allRecordsWithCurrentPosition.map((rowData, rowDataIdx) => {
                  const form = {
                    ...rowData,
                    first_name: rowData.firstName,
                    last_name: rowData.lastName,
                    country_id: rowData.countryId,
                    last_name_national: rowData.lastNameInt,
                    first_name_national: rowData.firstNameInt,
                    qdan: rowData.qdanId,
                    qual_iko: rowData.qualificationIkoRid,
                    qual_rf: rowData.qualificationRfRid,
                    tournament_id: rowData.tournamentId,
                    registrar_id: rowData.userId,
                    days: rowData.days?.map((it) => ({
                      id: `${it}`
                    })),
                    positionId: null
                  };

                  this.updateStaff(e, form, () => {
                    if (
                      rowDataIdx ===
                      allRecordsWithCurrentPosition.length - 1
                    ) {
                      this.updateStaff(
                        e,
                        {
                          ...modalData,
                          positionId: rowDataTeamsTatamis?.positionId
                        },
                        (val) => resolve(val)
                      );
                    }
                  });
                });
              }
            }
          });

          Promise.all([d1, d2, d3, d4, d5]).then(() =>
            this.fetchData(() => {
              this.onGroupRecordsByPositions(true);

              if (sortDirection?.length > 0) {
                this.onSort(sortDirection[2], null, true);
              }

              this.setState({ isAttemptingToEditModalFields: false }, () =>
                this.hideModal()
              );
            })
          );
        }
      );
    }
  };

  checkingIfConfirmationModalIsNeeded = (e) => {
    const { groupedRecordsByPosition, rowDataTeamsTatamis, modalData } =
      this.state;
    let onDeleteThanUpdate;
    const findjudgesWithCurrentPosition =
      groupedRecordsByPosition?.[+rowDataTeamsTatamis?.positionId];
    const len = findjudgesWithCurrentPosition?.length;

    for (let i = 0; i < len; i++) {
      const currentItem = findjudgesWithCurrentPosition[i];

      if (
        TOP_JUDGES.includes(+rowDataTeamsTatamis?.positionId) &&
        +currentItem.id !== +modalData.id
      ) {
        onDeleteThanUpdate = true;
        break;
      }

      if (
        TATAMI_JUDGES.includes(+rowDataTeamsTatamis?.positionId) &&
        +currentItem.id !== +modalData.id &&
        !TATAMI_JUDGES.includes(32) // Exclude 32 - Senior Judge
      ) {
        const selectedTatamis = rowDataTeamsTatamis.tatamis;
        const tatamiLen = selectedTatamis?.length;

        for (let j = 0; j < tatamiLen; j++) {
          const currentTatami = selectedTatamis[j];

          if (
            currentItem?.tatamis?.some(
              (val) => +val.tatami_id === +currentTatami.tatami_id
            )
          ) {
            onDeleteThanUpdate = true;
            break;
          }
        }
      }
    }

    if (onDeleteThanUpdate) {
      this.setState((prevState) => ({
        modalData: {
          ...prevState.modalData,
          showConfirmationModalTxt: true
        }
      }));
    } else {
      this.saveModalChanges(e);
    }
  };

  selectTeamsTatamis = (_, value, param) => {
    const { rowDataTeamsTatamis, rowDataTeamsTatamisErrors } = this.state;
    const copyObj = { ...rowDataTeamsTatamis };
    const copyErrors = { ...rowDataTeamsTatamisErrors };

    if (value !== null) {
      delete copyErrors[param];

      copyObj[param] = value;
      this.setState({
        rowDataTeamsTatamis: copyObj,
        isEditing: true,
        rowDataTeamsTatamisErrors: copyErrors
      });
    }
  };

  onToggleView = () => {
    this.setState(
      (prevState) => ({ isSchemeView: !prevState.isSchemeView }),
      () => {
        const { isSchemeView } = this.state;
        this.onFilterRecords();

        if (isSchemeView) {
          this.onGroupRecordsByPositions(null, true);
        }
      }
    );
  };

  onGroupRecordsByPositions = (
    shouldGroupAllRecords,
    shouldGroupWidgetRecords
  ) => {
    const { allRecords, schemeData } = this.state;
    const len = allRecords?.length;
    let filteredRecordsByScheme = {};
    let groupedRecordsByPosition = {};

    for (let i = 0; i < len; i++) {
      const item = allRecords[i];

      if (item.positionId) {
        if (
          (shouldGroupWidgetRecords &&
            item?.tatamis?.some(
              (it) => +it?.tatami_id === +schemeData?.tatami_id
            ) &&
            (item?.teams?.some((it) => +it?.team_n === +schemeData?.team_id) ||
              [31, 32, 33, 4251].includes(+item?.positionId))) ||
          TOP_JUDGES.includes(+item?.positionId)
        ) {
          if (filteredRecordsByScheme[item.positionId]) {
            filteredRecordsByScheme[item.positionId] = [
              ...filteredRecordsByScheme[item.positionId],
              item
            ];
          } else {
            filteredRecordsByScheme = {
              ...filteredRecordsByScheme,
              [item.positionId]: [item]
            };
          }
        }

        if (shouldGroupAllRecords) {
          if (groupedRecordsByPosition[item.positionId]) {
            groupedRecordsByPosition[item.positionId] = [
              ...groupedRecordsByPosition[item.positionId],
              item
            ];
          } else {
            groupedRecordsByPosition = {
              ...groupedRecordsByPosition,
              [item.positionId]: [item]
            };
          }
        }
      }
    }

    this.setState({
      ...(shouldGroupWidgetRecords ? { filteredRecordsByScheme } : {}),
      ...(shouldGroupAllRecords ? { groupedRecordsByPosition } : {})
    });
  };

  onSwitchTatami = (arrow) => {
    const { schemeData, tourTatamis } = this.state;
    const findIdx = tourTatamis.findIndex(
      (it) => +it?.tatami_id === +schemeData?.tatami_id
    );
    let currentVal;

    if (arrow === 0) {
      currentVal = tourTatamis[findIdx - 1] ?? tourTatamis[findIdx];
    } else {
      currentVal = tourTatamis[findIdx + 1] ?? tourTatamis[findIdx];
    }

    this.setState(
      (prevState) => ({
        schemeData: {
          ...prevState.schemeData,
          tatami_id: currentVal?.tatami_id,
          tatami_name: currentVal?.tatami_name,
          category_count_use: currentVal?.category_count_use,
          skirmish_count: currentVal?.skirmish_count,
          participant_count: currentVal?.participant_count,
          theme_id: currentVal?.theme_id,
          total_time: currentVal?.total_time
        }
      }),
      () => this.onGroupRecordsByPositions(null, true)
    );
  };

  onSwitchTeam = (arrow) => {
    const { schemeData } = this.state;
    const findIdx = ALL_TEAMS.findIndex(
      (it) => +it?.team_id === +schemeData?.team_id
    );
    let currentVal;

    if (arrow === 0) {
      currentVal = ALL_TEAMS[findIdx - 1] ?? ALL_TEAMS[findIdx];
    } else {
      currentVal = ALL_TEAMS[findIdx + 1] ?? ALL_TEAMS[findIdx];
    }

    this.setState(
      (prevState) => ({
        schemeData: {
          ...prevState.schemeData,
          team_id: currentVal?.team_id
        }
      }),
      () => this.onGroupRecordsByPositions(null, true)
    );
  };

  onSelectRecord = (e, item) => {
    const { selectedRow, gridViewSelectedRecord } = this.state;
    const isSelected =
      +gridViewSelectedRecord?.tjId === +item?.tjId &&
      +selectedRow === +item?.tjId;

    this.setState({
      gridViewSelectedRecord: !isSelected
        ? {
            ...item,
            first_name: item.firstName,
            last_name: item.lastName,
            country_id: item.countryId,
            last_name_national: item.lastNameInt,
            first_name_national: item.firstNameInt,
            qdan: item.qdanId,
            qual_iko: item.qualificationIkoRid,
            qual_rf: item.qualificationRfRid,
            tournament_id: item.tournamentId,
            registrar_id: item.userId
          }
        : {},
      selectedRow: !isSelected ? item?.tjId : null
    });
  };

  onSelectingJudgePosition = (e, item) => {
    const { gridViewSelectedRecord, schemeData, sortDirection, isSchemeView } =
      this.state;

    if (gridViewSelectedRecord?.tjId) {
      const form = {
        ...gridViewSelectedRecord,
        days: gridViewSelectedRecord?.days?.map((it) => ({
          id: `${it}`
        })),
        positionId: item.t_id
      };

      this.updateStaff(
        e,
        form,
        () => {
          const data1 = gridViewSelectedRecord?.teams?.map(
            (it) =>
              new Promise((resolve) => {
                if (
                  TOP_JUDGES.includes(+item?.t_id) ||
                  TATAMI_JUDGES.includes(+item?.t_id)
                ) {
                  this.delStaffTeam(it?.tt_id, (val) => resolve(val));
                } else {
                  resolve();
                }
              })
          );
          const data2 = gridViewSelectedRecord?.tatamis?.map(
            (it) =>
              new Promise((resolve) => {
                if (TOP_JUDGES.includes(+item?.t_id)) {
                  this.delStaffTatami(it?.tt_id, (val) => resolve(val));
                } else {
                  resolve();
                }
              })
          );
          const data3 = new Promise((resolve) => {
            if (TEAM_JUDGES.includes(+item?.t_id)) {
              this.addStaffTeam(form?.tjId, schemeData.team_id, (val) =>
                resolve(val)
              );
            } else {
              resolve();
            }
          });
          const data4 = new Promise((resolve) => {
            if (!TOP_JUDGES.includes(+item?.t_id)) {
              this.addStaffTatami(form?.tjId, schemeData.tatami_id, (val) =>
                resolve(val)
              );
            } else {
              resolve();
            }
          });

          Promise.all([data1, data2, data3, data4]).then(() => {
            this.fetchData(() => {
              const { allRecords } = this.state;
              const findJudge = allRecords.find(
                (it) => +it?.id === +gridViewSelectedRecord?.id
              );

              findJudge && this.onSelectRecord(e, findJudge);

              if (sortDirection?.length > 0) {
                this.onSort(sortDirection[2], null, true);
              }

              this.onGroupRecordsByPositions(true, true);
            });
          });
        },
        true
      );
    }
  };

  onSwitchToggle = () => {
    this.setState(
      (prevState) => ({ page: 1, isSwitchSet: !prevState.isSwitchSet }),
      () => this.onFilterRecords()
    );
  };

  onFilterByPotsiton = (evt, val) => {
    if (val === null) {
      this.setState(
        (prevState) => ({
          page: 1,
          filters: { ...prevState.filters, positionId: null }
        }),
        () => this.onFilterRecords()
      );
    } else {
      this.selectOption(evt, val, 'filters', 'positionId', 'id', null, () => {
        this.setState({ page: 1 });
        this.onFilterRecords();
      });
    }
  };

  onRemovePosition = (e, item) => {
    const { sortDirection, open } = this.state;
    const form = {
      ...item,
      first_name: item.firstName,
      last_name: item.lastName,
      country_id: item.countryId,
      last_name_national: item.lastNameInt,
      first_name_national: item.firstNameInt,
      qdan: item.qdanId,
      qual_iko: item.qualificationIkoRid,
      qual_rf: item.qualificationRfRid,
      tournament_id: item.tournamentId,
      registrar_id: item.userId,
      positionId: null
    };

    this.updateStaff(
      e,
      form,
      () => {
        const data1 = item?.tatamis.map(
          (it) =>
            new Promise((resolve) =>
              this.delStaffTatami(it?.tt_id, (val) => resolve(val))
            )
        );

        const data2 = item?.teams.map(
          (it) =>
            new Promise((resolve) =>
              this.delStaffTeam(it?.tt_id, (val) => resolve(val))
            )
        );

        Promise.all([...data1, ...data2]).then(() => {
          this.fetchData(() => {
            if (sortDirection?.length > 0) {
              this.onSort(sortDirection[2], null, true);
            }

            this.onGroupRecordsByPositions(true, true);

            if (open) {
              this.hideModal();
            }
          }, true);
        });
      },
      true
    );
  };

  modalOnSelectPosition = (e, value) => {
    const { rowDataTeamsTatamisErrors } = this.state;

    this.selectOption(
      e,
      value,
      'rowDataTeamsTatamis',
      'positionId',
      'id',
      rowDataTeamsTatamisErrors,
      (val) => {
        const copyErrors = rowDataTeamsTatamisErrors;

        if (TOP_JUDGES.includes(+val)) {
          delete copyErrors?.tatamis;
          delete copyErrors?.teams;

          this.setState({ rowDataTeamsTatamisErrors: copyErrors });
        }
      },
      true
    );
  };

  onSort = (sortField, idxCell, noFirstTimeSort) => {
    const { allRecords, sortDirection, tournamentData } = this.state;
    const clone = [...allRecords];

    const direction = findSortDirection(
      sortDirection,
      sortField,
      noFirstTimeSort
    );
    const sortedData = compareValueForSorting(clone, sortField, direction);

    this.setState(
      {
        ...(!noFirstTimeSort
          ? { sortDirection: [direction, idxCell, sortField] }
          : {}),
        page: 1,
        allRecords: sortedData
      },
      () => this.onFilterRecords()
    );
  };

  goBack2FirstView = () => {
    const { modalData } = this.state;
    const copyModalData = { ...modalData };
    delete copyModalData.showConfirmationModalTxt;

    this.setState({ modalData: copyModalData });
  };

  selectDays = (_, value) => {
    const { modalData } = this.state;
    const copyForm = { ...modalData };

    if (value !== null) {
      copyForm.days = value;
    }

    this.setState({ modalData: copyForm });
  };

  render() {
    const {
      success,
      modalInfo,
      showModal,
      shouldDisableEditing,
      isLoading,
      tournamentData,
      allRegistrar,
      allRecords,
      modalData,
      errors,
      allPositions,
      countries,
      qdanList,
      filters,
      sortDirection,
      all_qual_iko,
      all_qual_rf,
      allRegions,
      selectedRow,
      open,
      tourTatamis,
      tatamisTheme,
      pressedIcon,
      shouldShowDiscardChanges,
      modalSelectedVal,
      rowDataTeamsTatamis,
      rowDataTeamsTatamisErrors,
      filteredRecords,
      isSchemeView,
      schemeData,
      filteredRecordsByScheme,
      groupedRecordsByPosition,
      page,
      rowsPerPage,
      recordsCount,
      isSwitchSet
    } = this.state;
    const { t, classes } = this.props;
    const { viewportWidth } = this.context;
    const MODAL_PARATRONYMIC = (item) =>
      !!+tournamentData?.show_reg_patronymic ? item.patronymic : '';
    const openRegistration = !!+tournamentData?.registration_active;

    const ADD_TEAM_TATAMI = [
      {
        options: tourTatamis,
        label: t('tatamis'),
        item: 'tatami_name',
        param: 'tatamis'
      },
      {
        options: ALL_TEAMS,
        label: t('judgeTeams'),
        item: 'team_id',
        param: 'teams'
      }
    ];

    const MODAL_FIRST_NAME = (item) =>
      !+tournamentData?.last_name_first ? item.firstName : item.lastName;

    const MODAL_LAST_NAME = (item) =>
      !+tournamentData?.last_name_first ? item.lastName : item.firstName;

    let dialogContent,
      dialogTitle,
      modalInnerContent,
      onClick,
      onSwitchBetween,
      close,
      closeButtonlabel;
    let buttonPurpose = t('save');

    if (pressedIcon === CLEAR_ICON) {
      if (modalSelectedVal?.tatami_id) {
        dialogTitle = [t('remove'), t('tatami')].join(' ');
        onClick = (e) => this.saveModalChanges(e);
      } else if (modalSelectedVal?.team_n) {
        dialogTitle = t('deleteJudgeTeam');
        onClick = (e) => this.saveModalChanges(e);
      } else {
        dialogTitle = t('removePosition');
        onClick = (e) => this.onRemovePosition(e, modalData);
      }

      dialogContent = t('provideThisAction');
      buttonPurpose = t('remove');
    } else if (pressedIcon === TEST_ID_FORM) {
      const rowOrder =
        modalData.currentRecordIdx !== undefined
          ? modalData.currentRecordIdx + 1
          : 0;
      const numOrder = (page - 1) * rowsPerPage + rowOrder;

      dialogTitle = modalData?.tjId
        ? `${numOrder} ${t('updateJudge')}`
        : t('addJudge');
      modalInnerContent = (
        <StaffForm
          selectDays={this.selectDays}
          {...{ tournamentData }}
          changeTxt={this.textChangeHandler}
          param={'modalData'}
          {...{ countries }}
          {...{ qdanList }}
          {...{ all_qual_iko }}
          {...{ all_qual_rf }}
          values={modalData}
          selectOption={this.selectOption}
          errors={errors}
          saveForm={this.saveForm}
          changeImage={this.changeImage}
          {...{ openRegistration }}
          {...{ allPositions }}
          fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
          {...{ allRegions }}
          {...{ allRegistrar }}
          autoFillRegistrarData={this.autoFillRegistrarData}
          changeDate={(date) =>
            this.changeDate(
              date,
              'modalData',
              errors,
              true,
              tournamentData.start_date
            )
          }
          shouldRenderTorunRegistrarList
        />
      );

      onSwitchBetween = modalData?.tjId && {
        prev: (evt) => {
          const nextItemIdx = modalData.currentRecordIdx - 1;

          if (nextItemIdx >= 0) {
            const item = filteredRecords[nextItemIdx];

            this.showModalForm(evt, item, nextItemIdx);
          }
        },
        next: (evt) => {
          const nextItemIdx = modalData.currentRecordIdx + 1;
          const len = filteredRecords?.length - 1;

          if (len >= nextItemIdx) {
            const item = filteredRecords[nextItemIdx];

            this.showModalForm(evt, item, nextItemIdx);
          }
        }
      };
    } else if (pressedIcon === ADD_ICON) {
      dialogTitle = t('assignToPosition');

      if (!modalData?.showConfirmationModalTxt) {
        onClick = (e) => this.checkingIfConfirmationModalIsNeeded(e);
        modalInnerContent = (
          <>
            <Filter
              classes={{
                root: classes.filter
              }}
              value={
                selectedValue(
                  allPositions,
                  'id',
                  rowDataTeamsTatamis?.positionId,
                  true
                ) || ''
              }
              options={allPositions}
              onChange={(e, value) => this.modalOnSelectPosition(e, value)}
              label={t('positions')}
              item={{
                firstName: 'shortName',
                lastName: 'name',
                parathesis: true
              }}
              error={!!rowDataTeamsTatamisErrors?.positionId}
              helperText={rowDataTeamsTatamisErrors?.positionId}
            />
            {ADD_TEAM_TATAMI.map((itVal, itIdx) => {
              const disabled =
                !+rowDataTeamsTatamis?.positionId ||
                TOP_JUDGES.includes(+rowDataTeamsTatamis?.positionId) ||
                (itVal.param === 'teams' &&
                  !TEAM_JUDGES.includes(+rowDataTeamsTatamis?.positionId) &&
                  !TOP_JUDGES.includes(+rowDataTeamsTatamis?.positionId));

              return (
                <Fragment key={itIdx}>
                  <Filter
                    classes={{
                      root: classes.filter
                    }}
                    render
                    multiple
                    value={rowDataTeamsTatamis?.[itVal.param] || []}
                    options={itVal.options}
                    onChange={(e, value) =>
                      this.selectTeamsTatamis(e, value, itVal.param)
                    }
                    disableCloseOnSelect
                    {...{ disabled }}
                    label={itVal.label}
                    item={itVal.item}
                    error={!!rowDataTeamsTatamisErrors?.[itVal.param]}
                    helperText={rowDataTeamsTatamisErrors?.[itVal.param]}
                  />
                </Fragment>
              );
            })}
          </>
        );
      }

      if (modalData.showConfirmationModalTxt) {
        const existingUserWithCurrentPosition = groupedRecordsByPosition?.[
          rowDataTeamsTatamis?.positionId
        ]?.find((it) => +it.id !== +modalData?.id);

        onClick = (e) => this.saveModalChanges(e);
        modalInnerContent = t('judgeWithSuchPositionExists', {
          position: existingUserWithCurrentPosition?.positionName,
          name1:
            existingUserWithCurrentPosition &&
            [
              MODAL_FIRST_NAME(existingUserWithCurrentPosition),
              MODAL_LAST_NAME(existingUserWithCurrentPosition)
            ].join(' '),
          name2: [MODAL_FIRST_NAME(modalData), MODAL_LAST_NAME(modalData)].join(
            ' '
          )
        });
        buttonPurpose = t('yes');
        closeButtonlabel = t('no');
        close = this.goBack2FirstView;
      }
    } else if (pressedIcon === TEST_ID_DOWNLOAD_DOC) {
      dialogTitle = t('judicialQualifications');
      modalInnerContent = modalData?.preview ? (
        <span className={classes.flex}>
          <img
            src={modalData?.preview}
            alt={t('photo')}
            className={classes.docImg}
          />
          <span
            className={clsx(
              classes.flex,
              classes.centerVertically,
              classes.left
            )}>
            <GetAppIcon
              onClick={() => this.downloadUplaodedFile(modalData?.documentId)}
              className={clsx(classes.onHoverIc, classes.downloadIc)}
            />
          </span>
        </span>
      ) : (
        t('noAttachmentsMsg')
      );
    }

    const JUDGES_TYPES = [
      [
        {
          t_id: 25,
          id: 0,
          currentJ: filteredRecordsByScheme?.[25]?.[0],
          color: TOP_JUDGES_COLOR
        },
        {
          t_id: 26,
          id: 1,
          currentJ: filteredRecordsByScheme?.[26]?.[0],
          color: TOP_JUDGES_COLOR
        },
        {
          t_id: 27,
          id: 2,
          currentJ: filteredRecordsByScheme?.[27]?.[0],
          color: TOP_JUDGES_COLOR
        },
        {
          t_id: 28,
          id: 3,
          currentJ: filteredRecordsByScheme?.[28]?.[0],
          color: TOP_JUDGES_COLOR
        }
      ],
      [
        {
          t_id: 33,
          id: 0,
          currentJ: filteredRecordsByScheme?.[33]?.[0],
          color: MIDDLE_JUDGES_COLOR
        },
        {
          t_id: 31,
          id: 1,
          currentJ: filteredRecordsByScheme?.[31]?.[0],
          color: MIDDLE_JUDGES_COLOR
        },
        {
          t_id: 4251,
          id: 2,
          currentJ: filteredRecordsByScheme?.[4251]?.[0],
          color: MIDDLE_JUDGES_COLOR
        },
        {
          t_id: 32,
          id: 3,
          currentJ: filteredRecordsByScheme?.[32]?.[0],
          color: MIDDLE_JUDGES_COLOR
        }
      ],
      [
        {
          t_id: 34,
          id: 4,
          className: [classes.squareJudges, classes.squareJudgeR],
          currentJ: filteredRecordsByScheme?.[34]?.[0],
          color: REFEREE_JUDGES_COLOR
        }
      ],
      [
        {
          t_id: 29,
          id: 5,
          className: classes.squareJudgeBTopLeft,
          currentJ: filteredRecordsByScheme?.[29]?.[0],
          color: SIDE_JUDGES_COLOR
        },
        {
          t_id: 29,
          id: 6,
          className: classes.squareJudgeBTopRight,
          currentJ: filteredRecordsByScheme?.[29]?.[1],
          color: SIDE_JUDGES_COLOR
        },
        {
          t_id: 29,
          id: 7,
          className: classes.squareJudgeBBottomRight,
          currentJ: filteredRecordsByScheme?.[29]?.[2],
          color: SIDE_JUDGES_COLOR
        },
        {
          t_id: 29,
          id: 8,
          className: classes.squareJudgeBBottomLeft,
          currentJ: filteredRecordsByScheme?.[29]?.[3],
          color: SIDE_JUDGES_COLOR
        }
      ],
      [
        {
          t_id: 30,
          id: 9,
          className: classes.squareJudgeTopLeftU,
          currentJ: filteredRecordsByScheme?.[30]?.[0],
          color: PARTISIPANTS_JUDGES_COLOR
        },
        {
          t_id: 30,
          id: 10,
          className: classes.squareJudgeTopRighttU,
          currentJ: filteredRecordsByScheme?.[30]?.[1],
          color: PARTISIPANTS_JUDGES_COLOR
        },
        {
          t_id: 30,
          id: 11,
          className: classes.squareJudgeBottomRightU,
          currentJ: filteredRecordsByScheme?.[30]?.[2],
          color: PARTISIPANTS_JUDGES_COLOR
        },
        {
          t_id: 30,
          id: 12,
          className: classes.squareJudgeBottomLeftU,
          currentJ: filteredRecordsByScheme?.[30]?.[3],
          color: PARTISIPANTS_JUDGES_COLOR
        }
      ]
    ];

    const convertedTime = convertMs(schemeData?.total_time);
    const resultTime = [convertedTime.hours, convertedTime.minutes].join(':');
    const iconsTatami = [
      {
        icon: <FormatListNumberedIcon className={classes.tatamiIcons} />,
        count: schemeData?.category_count_use
      },
      {
        icon: <PersonIcon className={classes.tatamiIcons} />,
        count: schemeData?.participant_count
      },
      {
        icon: <SportsKabaddiIcon className={classes.tatamiIcons} />,
        count: schemeData?.skirmish_count
      },
      {
        icon: <ScheduleIcon className={classes.tatamiIcons} />,
        count: resultTime
      }
    ];

    const slidderComponent = (label, onClick, name, isTatami) => {
      const findTheme = tatamisTheme?.find(
        (it) => +it?.id === +schemeData?.theme_id
      )?.color1;

      return (
        <span
          className={clsx(
            classes.flex,
            classes.column,
            !isTatami && classes.marginLeft2,

            classes.centerHorizontally
          )}>
          <span className={clsx(classes.slidderLabel)}>{label}</span>
          <span className={clsx(classes.flex, classes.centerVertically)}>
            <ArrowIcon
              onClick={() => onClick(0)}
              className={clsx(
                !isTatami && classes.triangleTeamL,
                classes.onHoverIc
              )}
            />
            <span
              className={clsx(
                classes.flex,
                classes.column,
                classes.tatamiTeamWrapper,
                isTatami ? classes.tatamiWrapper : classes.teamWrapper,
                classes.centerVertically,
                classes.centerHorizontally
              )}
              style={isTatami ? getTatamiTheme(findTheme) : undefined}>
              <span
                className={clsx(
                  classes.flex,
                  classes.centerHorizontally,
                  classes.slidderName
                )}>
                {name}
              </span>
              {isTatami && (
                <span className={clsx(classes.flex)}>
                  {iconsTatami.map(({ icon, count }, idx) => (
                    <span
                      key={idx}
                      className={clsx(
                        classes.flex,
                        classes.tatamiDetails,
                        classes.centerVertically
                      )}>
                      {icon}
                      {count}
                    </span>
                  ))}
                </span>
              )}
            </span>
            <ArrowIcon
              onClick={() => onClick(1)}
              className={clsx(
                classes.onHoverIc,
                classes.rotate180,
                !isTatami && classes.triangleTeamR
              )}
            />
          </span>
        </span>
      );
    };

    const componentJ = (idx, className, subIdx) =>
      [
        ...(!isNaN(subIdx) ? [JUDGES_TYPES[idx][subIdx]] : JUDGES_TYPES[idx])
      ].map((jType) => {
        const titleName = allPositions.find((it) => +it?.id === +jType.t_id);

        return (
          <Tooltip
            key={jType.id}
            arrow
            title={
              <span>
                {[
                  titleName?.name,
                  jType?.currentJ?.firstName
                    ? `: ${jType?.currentJ?.firstName}
                ${jType?.currentJ?.lastName}`
                    : ''
                ].join(' ')}
              </span>
            }>
            <span
              onClick={(e) =>
                !jType.currentJ
                  ? this.onSelectingJudgePosition(e, jType)
                  : undefined
              }
              className={clsx(
                classes.flex,
                classes.centerVertically,
                !shouldDisableEditing && classes.pointer,
                className,
                jType.className,
                classes.centerHorizontally
              )}
              style={
                jType?.currentJ
                  ? getTatamiTheme(jType?.color)
                  : { background: DEFAULT_JUDGES_COLOR, color: '#000' }
              }>
              <span className={classes.fontSize1And2}>
                {titleName?.shortName}
              </span>
            </span>
          </Tooltip>
        );
      });

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {isLoading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            <HeaderTournInfo
              {...{ tournamentData, viewportWidth }}
              shouldShowBtn
            />
            <Modal
              {...{ open }}
              close={close ?? this.hideModal}
              closeButtonlabel={closeButtonlabel}
              onClick={onClick ? (e) => onClick(e) : undefined}
              {...{ dialogTitle }}
              {...{ dialogContent }}
              {...{ buttonPurpose, onSwitchBetween }}
              subHeader={
                pressedIcon !== TEST_ID_FORM &&
                modalData &&
                [
                  MODAL_FIRST_NAME(modalData),
                  MODAL_LAST_NAME(modalData),
                  modalData.patronymic && MODAL_PARATRONYMIC(modalData)
                ]
                  .filter(Boolean)
                  .join(' ')
              }
              discardOrKeepEditing={this.discardOrKeepEditing}
              {...{ shouldShowDiscardChanges }}>
              {modalInnerContent && modalInnerContent}
            </Modal>

            <span
              className={clsx(
                classes.flex,
                classes.centerVertically,
                (tourTatamis?.length === 0 || allRecords?.length === 0) &&
                  classes.warningTxt
              )}>
              <GridView
                onClick={
                  tourTatamis?.length > 0 && allRecords?.length > 0
                    ? this.onToggleView
                    : undefined
                }
                className={clsx(classes.ic, classes.onHoverIc)}
              />

              <span>
                {t(
                  tourTatamis?.length === 0 || allRecords?.length === 0
                    ? 'judgeNoTatami'
                    : isSchemeView
                    ? 'hideRefereePlacementScheme'
                    : 'schemeJudges'
                )}
              </span>
            </span>

            {/* SCHEME VIEW  - START */}
            {isSchemeView && (
              <div className={clsx(classes.flex, classes.schemeWrapperOuter)}>
                <span
                  className={clsx(classes.flex, classes.column, classes.auto)}>
                  <span
                    className={clsx(
                      classes.flex,
                      classes.fullWidth,
                      classes.centerHorizontally,
                      classes.wrapperInlineMainJudges
                    )}>
                    {
                      componentJ(0, [classes.flex, classes.topMainJudges]) //Top Judges
                    }
                  </span>
                  <span className={clsx(classes.flex)}>
                    {slidderComponent(
                      t('tatami'),
                      this.onSwitchTatami,
                      schemeData.tatami_name,
                      true //isTatami
                    )}
                    {slidderComponent(
                      t('judgeTeam'),
                      this.onSwitchTeam,
                      schemeData.team_id
                    )}
                  </span>
                  <span
                    className={clsx(
                      classes.flex,
                      classes.fullWidth,
                      classes.centerHorizontally,
                      classes.wrapperInlineMainJudges
                    )}>
                    {
                      componentJ(1, [classes.flex, classes.topMainJudges]) //Main Judges
                    }
                  </span>
                  <span
                    className={clsx(
                      classes.fullWidth,
                      classes.relative,
                      classes.centerHorizontally,
                      classes.squareWrapper
                    )}>
                    <span
                      className={clsx(
                        classes.flex,
                        classes.square,
                        classes.relative,
                        classes.wrapperSquare
                      )}>
                      <span className={clsx(classes.box, classes.box1)}>
                        {
                          componentJ(
                            3,
                            [classes.squareJudges, classes.absolute],
                            0
                          ) //Side Judge
                        }
                        {
                          componentJ(
                            4,
                            [classes.squareJudges, classes.absolute],
                            0
                          ) //Judge to Participants
                        }
                        {
                          componentJ(
                            4,
                            [classes.squareJudges, classes.absolute],
                            1
                          ) //Judge to Participants
                        }
                      </span>
                      <span className={clsx(classes.box, classes.box2)}>
                        {
                          componentJ(
                            3,
                            [classes.squareJudges, classes.absolute],
                            1
                          ) //Side Judge
                        }

                        {componentJ(2, classes.absolute)}
                      </span>
                      <span className={clsx(classes.box, classes.box3)}>
                        {
                          componentJ(
                            3,
                            [classes.squareJudges, classes.absolute],
                            2
                          ) //Side Judge
                        }
                      </span>
                      <span className={clsx(classes.box, classes.box4)}></span>
                      <span className={clsx(classes.box, classes.box5)}>
                        {
                          componentJ(
                            3,
                            [classes.squareJudges, classes.absolute],
                            3
                          ) //Side Judge
                        }
                        {
                          componentJ(
                            4,
                            [classes.squareJudges, classes.absolute],
                            2
                          ) //Judge to Participants
                        }
                        {
                          componentJ(
                            4,
                            [classes.squareJudges, classes.absolute],
                            3
                          ) //Judge to Participants
                        }
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            )}
            {isSchemeView && (
              <FormControlLabel
                className={clsx(classes.switchWrapper, classes.switch)}
                control={
                  <Switch
                    checked={isSwitchSet}
                    onChange={(evt, checked) => {
                      this.onSwitchToggle(evt, checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={t('assigned')}
                style={{ gridArea: 'switchWrapper' }}
              />
            )}
            <span
              className={clsx(
                classes.gridButtons,
                classes.centerVertically,
                classes.marginBottom1
              )}>
              <Button
                onClick={() =>
                  this.exportReportGeneral({
                    api: `/get_judges_report/${tournamentData.id}`,
                    isApiGet: true
                  })
                }
                label={t('saveList')}
                style={{ gridArea: 'saveList' }}
                isSaveBtn
              />
              <SearchInput
                onChange={this.searchHandler}
                clearSearch={this.clearSearch}
                value={filters?.searchBar}
                style={{ gridArea: 'searchInput' }}
              />
              <Filter
                value={selectedValue(
                  allPositions,
                  'id',
                  filters?.positionId,
                  true
                )}
                disableClearable
                options={allPositions}
                classes={{
                  root: classes.outlined
                }}
                onChange={(evt, val) => this.onFilterByPotsiton(evt, val)}
                label={t('positions')}
                item={{
                  firstName: 'shortName',
                  lastName: 'name',
                  parathesis: true
                }}
                variant="outlined"
                style={{ gridArea: 'filter' }}
              />
              {!shouldDisableEditing && (
                <Button
                  id="add_new_button"
                  onClick={(evt, item, idx) =>
                    this.showModalForm(evt, item, idx)
                  }
                  isSaveBtn={true}
                  label={
                    <>
                      <span className={classes.plus}>&#x002B;</span>
                      {t('addNew')}
                    </>
                  }
                  style={{ gridArea: 'add' }}
                />
              )}
            </span>
            {/* the button is missing from the design */}
            {filteredRecords.length != 0 ? (
              <>
                <TableList
                  {...{
                    sortDirection,
                    filteredRecords,
                    shouldDisableEditing,
                    isSchemeView,
                    page,
                    rowsPerPage,
                    selectedRow,
                    tournamentData,
                    getTatamiTheme
                  }}
                  tournamentIdParams={this.tournamentIdParams}
                  judgesTypes={JUDGES_TYPES}
                  onSort={this.onSort}
                  showModalForm={this.showModalForm}
                  onSelectRecord={this.onSelectRecord}
                  showModal={this.showModal}
                />

                <Pagination
                  count={recordsCount}
                  onPageChange={this.pageChangeHandler}
                  onRowsPerPageChange={this.rowsPerPageHandler}
                  {...{ page }}
                  {...{ rowsPerPage }}
                />
              </>
            ) : (
              <EmptyState
                className={classes.emptyState}
                emptyStateText={t('noResults')}
              />
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Staff)));
