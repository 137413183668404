import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Clear } from 'assets/img/cancel_btn_grey_bg&border.svg';

import {
  serverDefaultPath,
  DEFAULT_AVATAR,
  CHOOSE_FROM_LIST
} from '../../helpers/constants';

import SearchInput from '../../components/SearchInput/SearchInput';
import Button from '../Buttons/ActionButtons';

import { useStyles } from './StylesInvitation';

const ADD_NEW = 'ADD_NEW';

const Invitation = (props) => {
  const {
    showModal,
    tournInvitations,
    textChange,
    pressEnter,
    clearSearch,
    filters,
    specificDetails,
    onDelete
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const { FIRST_NAME, LAST_NAME } = specificDetails;

  const buttons = [
    {
      purpose: t('chooseFromList'),
      onClick: (e) => {
        showModal({ targetID: CHOOSE_FROM_LIST });
      },
      id: CHOOSE_FROM_LIST
    },
    {
      purpose: t('addRecord', { name: '' }),
      onClick: () => showModal({ targetID: ADD_NEW }),
      id: ADD_NEW
    }
  ];

  return (
    <>
      <h3 className={classes.headerMain}>{t('invitationListTitle')}</h3>

      <div
        style={{
          background: '#fff',
          border: '1px solid #A2ABBA',
          borderRadius: '0.3rem',
          padding: '1.5rem'
        }}>
        <div className={classes.toolbar}>
          <SearchInput
            onChange={(e) => textChange(e, 'filters')}
            id="search-input_registration"
            onKeyPress={(e) => pressEnter(e, 'filters')}
            value={filters?.searchBar || ''}
            isSearchInactive={!filters?.searchBar}
            clearSearch={() => clearSearch('filters')}
            className={classes.search}
          />
          {buttons.map(({ purpose, onClick, id }) => (
            <Fragment key={id}>
              <Button label={purpose} {...{ onClick, id }} isSaveBtn={true} />
            </Fragment>
          ))}
        </div>

        <div className={classes.wrapperInvitations}>
          {tournInvitations?.map((el, elIdx) => {
            return (
              <span
                key={elIdx}
                style={{
                  border: '1px solid #8996AC',
                  height: '10.5em',
                  minWidth: '22.75em',
                  display: 'grid',
                  borderRadius: '0.3rem',
                  padding: '0.63rem'
                }}>
                <span
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '3.375em 1fr',
                    gap: '0.5rem',
                    height: 'fit-content'
                  }}>
                  <img
                    style={{
                      width: '3.375em',
                      height: '4.5em',
                      objectFit: 'cover'
                    }}
                    src={
                      el?.photo ? serverDefaultPath + el?.photo : DEFAULT_AVATAR
                    }
                    alt={t('avatar')}
                  />
                  <span style={{ display: 'grid' }}>
                    <span
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1.5em',
                        gap: '0.5rem'
                      }}>
                      <span style={{ fontSize: '1rem', fontWeight: 500 }}>
                        {[el[FIRST_NAME], el[LAST_NAME]].join(' ')}
                      </span>

                      <Clear
                        onClick={() => onDelete(el)}
                        style={{
                          cursor: 'pointer',
                          width: '1.5em',
                          height: 'auto'
                        }}
                      />
                    </span>
                    <span
                      className={classes.ellipsis}
                      style={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        alignContent: 'end'
                      }}>
                      {el.email}
                    </span>
                  </span>
                </span>
                <span style={{ color: '#8996AC', fontSize: '0.7rem' }}>
                  {t('cluborFederationName')}
                </span>
                <span style={{ fontSize: '1rem', fontWeight: 500 }}>
                  {el.club}
                </span>
              </span>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Invitation;
