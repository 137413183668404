import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { isMobileView } from 'helpers/selectors';

import { Clear } from '@material-ui/icons';

import TableList from './TableList';
import Button from '../Buttons/ActionButtons';

import { useStyles } from './Styles';

const DistributeParticipantsByCategoriesComponentComponent = (props) => {
  const {
    filteredData,
    onExpandRow,
    shouldDisableEditing,
    onRadioBtnSelection,
    onSelectPartCheckbox,
    onSelectAllPartCheckboxes,
    selectedParticiantsCategory,
    onSortCollapsibleTable,
    collapsed,
    onPanelSelection,
    tournamentData,
    activeFiltersPerPanel,
    viewportWidth
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const mobileScreen = isMobileView(viewportWidth);

  return (
    <div className={clsx(classes.flex, classes.centerHorizontally)}>
      <div className={clsx(classes.flex, classes.column, classes.fullWidth)}>
        <div className={clsx(classes.flex, mobileScreen && classes.column)}>
          {[1, 2].map((panel, panelIdx) => {
            const param_agr = `panel_${panelIdx}`;
            const filtersByPanel = activeFiltersPerPanel[param_agr];

            return (
              <span
                className={clsx(
                  classes.flex,
                  classes.column,
                  classes.fullWidth,
                  panelIdx === 1 && !mobileScreen && classes.marginLeft1,
                  mobileScreen && classes.marginBottom10
                )}
                key={panel}>
                <span
                  className={clsx(
                    classes.flex,
                    classes.fontSize08,
                    classes.fullWidth,
                    classes.centerVertically,
                    classes.pointer
                  )}>
                  <Button
                    specificallyStyledBtn={clsx(
                      classes.filterBtn,
                      classes.fullWidth
                    )}
                    onClick={() => onPanelSelection(panelIdx)}
                    label={
                      <label
                        className={clsx(
                          classes.flex,
                          classes.fullWidth,
                          classes.centerVertically
                        )}>
                        <span
                          className={clsx(
                            classes.flex,
                            classes.filterSummary,
                            classes.pointer,
                            classes.flexGrow1
                          )}>
                          <span className={classes.marginRight05}>
                            {t(filtersByPanel ? 'filter' : 'applyFilter')}
                            {filtersByPanel && ':'}
                          </span>
                          {Array.isArray(filtersByPanel) &&
                            filtersByPanel.map((filter, idx) => (
                              <span className={classes.marginRight05} key={idx}>
                                {filter.name}
                                {idx !== filtersByPanel.length - 1 ? ',' : ''}
                              </span>
                            ))}
                        </span>
                        {filtersByPanel && (
                          <Clear
                            className={clsx(
                              classes.clearIcon,
                              classes.btnHover
                            )}
                          />
                        )}
                      </label>
                    }
                  />
                </span>

                <span
                  className={clsx(
                    classes.flex,
                    classes.fullHeight,
                    classes.minHeight
                  )}>
                  <span
                    className={clsx(
                      classes.flex,
                      classes.column,
                      classes.categoriesPanel,
                      classes.fullWidth
                    )}>
                    <TableList
                      filteredData={filteredData[param_agr]}
                      {...{
                        collapsed,
                        panelIdx,
                        param_agr,
                        viewportWidth,
                        selectedParticiantsCategory,
                        tournamentData,
                        shouldDisableEditing,
                        onRadioBtnSelection,
                        onSelectPartCheckbox,
                        onSelectAllPartCheckboxes
                      }}
                      expandTableRow={onExpandRow}
                      onSortCollapsible={onSortCollapsibleTable}
                    />
                  </span>
                </span>
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DistributeParticipantsByCategoriesComponentComponent;
