import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Filter from '../Filter/Filter';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { ALL_TEAMS, CATEGORY_ID, ID } from '../../helpers/constants';
import { selectedValue } from '../../helpers/selectors';
import { useStyles } from './Styles';
import Button from 'components/Buttons/ActionButtons';

const AssignCategories = (props) => {
  const {
    categoryTypes,
    selectedCategories,
    addNewCategory,
    removeCategory,
    selectCategories,
    selectCategoryType,
    selectTeam,
    selectedCategoriesErrors,
    shouldDisableInput,
    switchTournament,
    tournamentsList,
    tournamentIdParams,
    selectedTournament
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {selectedCategories?.map((val, idx) => {
        const selectors = [
          ...(!tournamentIdParams && idx === 0 && selectedTournament //show tournament selector on 'My Team' page
            ? [
                {
                  value: selectedTournament?.id,
                  key: ID,
                  options: tournamentsList,
                  selectOption: switchTournament,
                  label: t('tournament'),
                  item: 'tournament_name',
                  className: classes.marginBottom,
                  int: true
                }
              ]
            : []),
          {
            value: val.type,
            key: ID,
            options: categoryTypes,
            selectOption: selectCategoryType,
            label: t('discipline'),
            item: 'name',
            className: classes.marginBottom,
            error: Boolean(selectedCategoriesErrors[idx]?.type),
            helperText: selectedCategoriesErrors[idx]?.type,
            int: true,
            disabled: shouldDisableInput
          },
          {
            current_category_name: val.name,
            value: val.id,
            key: CATEGORY_ID,
            options: val.filteredCategories,
            selectOption: selectCategories,
            label: t('category'),
            item: 'category_name',
            className: `${val.type}` === '3' && classes.marginBottom,
            error: Boolean(selectedCategoriesErrors[idx]?.id),
            helperText: selectedCategoriesErrors[idx]?.id,
            int: true,
            disabled: shouldDisableInput
          },
          `${val.type}` === '3' && {
            value: val.team_id,
            key: 'team_id',
            options: ALL_TEAMS,
            selectOption: selectTeam,
            label: t('team'),
            item: 'team_id',
            error: Boolean(selectedCategoriesErrors[idx]?.team_id),
            helperText: selectedCategoriesErrors[idx]?.team_id,
            int: true
          }
        ];
        return (
          <span
            key={idx}
            className={clsx(classes.flex, classes.marginTop, classes.grid)}>
            <span
              className={clsx(
                classes.assignCategoryFilters,
                classes.inputRightMargin
              )}>
              {selectors
                .filter((it) => it)
                .map(
                  (
                    {
                      value,
                      options,
                      selectOption,
                      label,
                      item,
                      key,
                      className,
                      error,
                      helperText,
                      current_category_name,
                      int,
                      disabled
                    },
                    index
                  ) => (
                    <Fragment key={index}>
                      <Filter
                        className={clsx(
                          classes.label,
                          classes.gridFilterArea,
                          classes.placeholder
                        )}
                        classes={{
                          inputRoot: classes.outlined
                        }}
                        {...{ options }}
                        {...{ label }}
                        value={selectedValue(options, key, value, int) || ''}
                        inputLabelProps={
                          value && !selectedValue(options, key, value)
                            ? {
                                shrink: true
                              }
                            : {}
                        }
                        placeholder={
                          value && !selectedValue(options, key, value)
                            ? current_category_name
                            : ''
                        }
                        onChange={(evt, val) => {
                          selectOption(
                            evt,
                            val,
                            idx,
                            selectedCategories,
                            selectedCategoriesErrors?.[idx]
                          );
                        }}
                        {...{ item }}
                        variant="outlined"
                        {...{ error }}
                        {...{ helperText }}
                        {...{ disabled }}
                      />
                    </Fragment>
                  )
                )}
            </span>
            <span className={clsx(classes.gridIconsArea)}>
              {idx === 0 && (
                <AddIcon
                  className={clsx(classes.icon, classes.marginRight05)}
                  onClick={addNewCategory}
                />
              )}
              <RemoveIcon
                className={classes.icon}
                onClick={() => removeCategory(val)}
              />
            </span>
          </span>
        );
      })}
    </>
  );
};
export default AssignCategories;
