import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },

  categoriesPanel: {
    padding: '1rem',
    border: '2px solid rgb(209, 214, 221)',
    minWidth: '7.5em'
  },

  btnHover: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  centerHorizontally: {
    justifyContent: 'center'
  },

  fullWidth: {
    width: '100%'
  },

  fullHeight: {
    height: '100%'
  },

  minHeight: {
    minHeight: '15em'
  },

  centerVertically: {
    alignItems: 'center'
  },

  filterBtn: {
    border: '1px solid #B5BAC2',
    width: '100%',
    height: '100%',
    minHeight: '48px',
    color: '#FFFFFF',
    backgroundColor: '#8996AC',
    fontWeight: 700,
    borderRadius: '0.3rem 0.3rem 0 0',
    '&:hover': {
      color: '#262441'
    }
  },

  fontSize08: {
    fontSize: '0.8rem'
  },

  filterSummary: {
    flexWrap: 'wrap',
    justifyContent: 'center'
  },

  marginRight05: {
    marginRight: '0.5rem'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  column: {
    flexDirection: 'column'
  },

  pointer: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  marginBottom10: {
    marginBottom: '10px'
  },

  clearIcon: {
    backgroundColor: '#FFFFFF',
    color: '#8996AC',
    borderRadius: '2px'
  },

  flexGrow1: {
    flexGrow: 1
  }
});
